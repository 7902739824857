import React from "react";
import Routes from "../../../routes/partners/Profile";

class Profile extends React.Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.setNav();
  }
  componentDidUpdate(oldProps) {
    if (
      this.props.match.params.id !==
      oldProps.match.params.id
    ) {
      this.setNav();
    }
  }
  async setNav() {
    const navOpts = [
      {
        icon: "cog",
        text: "Configuration",
        link: `/portal/partners/partner-management/${this.props.match.params.id}/configuration`
      },
      {
        icon: "chart-pie",
        text: "Sales Overview",
        link: `/portal/partners/partner-management/${this.props.match.params.id}/sales-overview`
      },
      {
        icon: "file-spreadsheet",
        text: "Reconciliation Reports",
        link: `/portal/partners/partner-management/${this.props.match.params.id}/reconciliation-reports`
      }
    ];
    this.props.setNavigationOptions(navOpts);
  }
  render() {
    return <Routes
    api={this.props.api}
    user={this.props.user}
    id={this.props.match.params.id}
    setTitle={this.props.setTitle}
  />;
  }
}

export default Profile;
