import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import CardButton from "../../../components/interactive/CardButton";
import Map from "../../../components/interactive/Map";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";

class PostcodeGeofencing extends React.Component {
  constructor() {
    super();
    this.state = {
      plotData: [],
      sentData: null,
      loading: true,
      error: false,
      syncModal: false,
      postcode: "NE4 5TF",
      range: 15,
      staticRange: 15
    };
  }
  componentDidMount() {
    this.getData();
  }
  validPostcode() {
    return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
      this.state.postcode
    );
  }
  async getData(disableCache) {
    if (this.validPostcode()) {
      this.setState(
        {
          loading: true
        },
        async () => {
          try {
            const plotData = await this.props.api.get(
              `/customers/regions/${this.state.postcode}?radius=${this.state.range}${disableCache ? "&resetCache=true" : ""}`
            );
            this.setState({
              plotData: plotData.data.data.plots,
              sentData: plotData.data.data.sent,
              staticRange: this.state.range,
              loading: false
            });
          } catch (e) {
            this.setState({ error: true, loading: false });
          }
        }
      );
    }
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
        <div className="col-1 gap-40 margin-bottom-40">
          <button
            className="ui-btn sync-button"
            onClick={() => this.setState({ syncModal: true })}
          >
            <i className="fad fa-sync" />
          </button>
          {this.state.syncModal ? (
            <GenericConfirm
              title="Sync live customers?"
              subTitle="This will reload the cache."
              body={
                <>
                  Once confirmed this will fetch all live stats from the database.
                  <br />
                  <br />
                The initial load may take considerably longer.
                </>
              }
              onExit={confirm => {
                if (confirm) {
                  this.setState(
                    {
                      loading: true,
                      syncModal: false
                    },
                    async () => {
                      this.getData(true);
                    }
                  );
                } else {
                  this.setState({
                    syncModal: false
                  });
                }
              }}
            />
          ) : null}
          <div className="col-1 gap-10">
            <p className="ui-label">Report filters</p>
            <Card>

              <div
                className="padding-20 col-4 gap-20"
                style={{
                  alignItems: "end",
                  gridTemplateColumns: "auto auto max-content"
                }}
              >
                <div>
                  <p className="ui-label margin-bottom-10">Postcode</p>
                  <input
                    className="ui-input"
                    defaultValue={this.state.postcode}
                    onChange={e =>
                      this.setState({ postcode: e.currentTarget.value })
                    }
                    type="text"
                    placeholder="Postcode"
                  />
                </div>
                <div>
                  <p className="ui-label margin-bottom-10">Range</p>
                  <select
                    className="ui-select"
                    value={this.state.range}
                    onChange={e =>
                      this.setState({ range: e.currentTarget.value })
                    }
                  >
                    <option value="5">5 miles</option>
                    <option value="15">15 miles</option>
                    <option value="30">30 miles</option>
                    <option value="50">50 miles</option>
                    <option value="75">75 miles</option>
                    <option value="100">100 miles</option>
                  </select>
                </div>
                <div>
                  <button
                    className="ui-btn-compact"
                    onClick={() => this.getData()}
                  >
                    <i className="far fa-search margin-right-10" />
                  Search
                </button>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Overall statistics</p>
            <Card style={{ minHeight: 130 }}>
              {!this.state.loading ? (
                <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                  <Stat
                    label="Total Plots"
                    figure={this.state.plotData
                      .reduce((a, x) => (a += x.total), 0)
                      .toLocaleString()}
                  />
                  <Stat
                    label="Total Occupied"
                    figure={this.state.plotData
                      .reduce((a, x) => (a += x.occupied), 0)
                      .toLocaleString()}
                  />
                  <Stat
                    label="Total Unoccupied"
                    figure={this.state.plotData
                      .reduce((a, x) => (a += x.notOccupied), 0)
                      .toLocaleString()}
                  />
                </div>
              ) : (
                  <div className="grid-center-center">
                    <Loader />
                  </div>
                )}
            </Card>
          </div>
          <Card style={{ height: 600, overflow: "hidden" }}>
            {!this.state.loading ? (
              <div>
                <Map
                  user={this.props.user}
                  markers={this.state.plotData.map(x => ({
                    lat: x.latitude,
                    lng: x.longitude,
                    infoHeading: x.outcode,
                    infoBody: `${x.total} ${x.total > 1 ? "Properties" : "Property"}`
                  }))}
                  showMarkers={true}
                  geofence={true}
                  geofenceDetails={{
                    lat: this.state.sentData.latitude,
                    lng: this.state.sentData.longitude,
                    range: this.state.staticRange
                  }}
                  onMarkerClick={() => { }}
                />
              </div>
            ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
      );
  }
}

export default PostcodeGeofencing;
