import React from "react";
import Card from "../../../components/generics/Card";
import Loader from "../../../components/generics/Loader";
import Stat from "../../../components/analytical/Stat";
import GenericConfirm from "../../../modals/GenericConfirm";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class MeterTypes extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      syncModal: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(clearCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const res = await this.props.api.get(`/customers/meters`);
          res.data.data.gas = Object.keys(res.data.data.gas).filter(x => x).map(x => ({
            name: x,
            value: res.data.data.gas[x]
          }));
          res.data.data.electric = Object.keys(res.data.data.electric).filter(x => x).map(x => ({
            name: x,
            value: res.data.data.electric[x]
          }));

          this.setState({
            data: res.data.data,
            loading: false
          });
        } catch (e) {
          console.log(e);
          this.setState({error:true,loading:false});
        }
      }
    );
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live customers?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    data: null,
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        <button
          className="ui-btn sync-button"
          onClick={() => this.setState({ syncModal: true })}
        >
          <i className="fad fa-sync" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-2 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Gas Meters"
                  figure={this.state.data.gas.reduce((s,x) => s+=x.value,0).toLocaleString()}
                />
                <Stat
                  label="Electricity Meters"
                  figure={this.state.data.electric.reduce((s,x) => s+=x.value,0).toLocaleString()}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Electricity breakdown</p>
            <Card style={{ height: 400 }}>
              {!this.state.loading ? (
                <div className="padding-15">
                  <ResponsiveContainer>
                  <BarChart
                    data={this.state.data.electric.sort((a,b) => b.value - a.value)}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={20}
                      type="number"
                      domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="value"
                      fill={colors[1]}
                    />
                  </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
          <div className="col-1 gap-40">
          <div className="col-1 gap-10">
            <p className="ui-label">Gas breakdown</p>
            <Card style={{ height: 400 }}>
              {!this.state.loading ? (
                <div className="padding-15">
                  <ResponsiveContainer>
                  <BarChart
                    data={this.state.data.gas.sort((a,b) => b.value - a.value)}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={20}
                      type="number"
                      domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="value"
                      fill={colors[0]}
                    />
                  </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default MeterTypes;
