import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import UserManagement from "../views/admin/UserManagement";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/admin/user-management"
      render={internalProps => (
        <UserManagement {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/admin/user-management" />} />
  </Switch>
);

export default Routes;
