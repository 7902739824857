import React from 'react';

const CardButton = props => <div className={`ui-card ui-card-button ${props.disabled ? "ui-card-button-disabled" : ""} ${props.compact ? "ui-card-button-compact" : ""}`} style={props.style} onClick={props.disabled ? ()=>{} : props.onClick}>
  <i className={`fad fa-${props.icon} colour-${props.colour}`}/>
  <div>
    <p>{props.title}</p>
    <p style={{marginTop: props.subTitle ? 5 : 0}}>{props.subTitle}</p>
  </div>
</div>;

export default CardButton;