import React from "react";
import moment from "moment";
import Modal from "../components/generics/Modal";
import Loader from "../components/generics/Loader";
import Card from "../components/generics/Card";
import GenericMessage from "./GenericMessage";

class SmartInstall extends React.Component {
  constructor() {
    super();
    this.state = {
      jobType: "DUAL_FUEL_SMETS_2",
      date: null,
      availableSlots: null,
      selectedSlot: null,
      dateVariance: 0,
      notes: null,
      noAvailabilityModal: false,
      loading: false,
      errorModal: false,
      successModal: false,
      errorModalMessage: null,
      bookingReference: null,
      wanLoading: true,
      wan: null
    }
  }
  async componentDidMount(){
    const wan = await this.props.api.get(`/customers/${this.props.accountNumber}/wan`);
    this.setState({
      wan: wan.data.data,
      wanLoading: false
    });
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  }
  getAvailableSlots = async () => {
    this.setState({
      loading: true
    }, async () => {
      const res = await this.props.api.get(`/customers/${this.props.accountNumber}/beboc/availability?date=${this.state.date}&endDate=${moment(this.state.date).add(this.state.dateVariance, "days").format("YYYY-MM-DD")}`);
      this.setState({
        availableSlots: res.data.data.filter(x => x.isAvailable),
        loading: false
      });
    });
  }
  getNextAvailableDate = async () => {
    this.setState({
      loading: true
    }, async () => {
      const res = await this.props.api.get(`/customers/${this.props.accountNumber}/beboc/next-availability`);
      if (res.data.data === null) {
        this.setState({
          noAvailabilityModal: true,
          loading: false
        });
      } else {
        this.setState({
          date: res.data.data,
          loading: false
        });
      }
    });
  }
  bookInstall = async e => {
    e.preventDefault();
    this.setState({
      loading: true
    }, async () => {
      const res = await this.props.api.post(`/customers/${this.props.accountNumber}/beboc/book`, {
        ...this.state.selectedSlot,
        jobType: this.state.jobType,
        notes: this.state.notes
      });
      if(res.data.success){
        this.setState({
          loading: false,
          successModal: true,
          bookingReference: res.data.data.bookingReference
        });
      } else {
        this.setState({
          loading: false,
          errorModal: true,
          errorModalMessage: res.data.errors
        });
      }
    });
  }
  render() {
    return (<>
      {this.state.noAvailabilityModal ? (
        <GenericMessage
          title={`No availability found.`}
          subTitle={`Please raise with our smart team.`}
          body={
            <>
              We can't find any availability for this area. Please raise with our smart team.
            </>
          }
          cancelText="Close"
          onExit={() => {
            this.setState({ noAvailabilityModal: false });
          }}
        />
      ) : null}
      {this.state.wan ? (this.state.wan.tier === "LOW" || !this.state.wan.availability) ? (
        <GenericMessage
          title={`No availability found.`}
          subTitle={`Please raise with our smart team.`}
          body={
            <>
              Based on the WAN information, we cannot proceed with a booking. Please raise with our smart team.
            </>
          }
          cancelText="Close"
          onExit={() => {
            this.setState({ noAvailabilityModal: false });
          }}
        />
      ) : null : null}
      {this.state.errorModal ? (
        <GenericMessage
          title={`An error occurred.`}
          subTitle={`Please see below or raise with our smart team.`}
          body={
            <>
              Error code: {this.state.errorModalMessage}
            </>
          }
          cancelText="Close"
          onExit={() => {
            this.setState({ errorModal: false });
          }}
        />
      ) : null}
      {this.state.successModal ? (
        <GenericMessage
          title={`Booking confirmed.`}
          subTitle={`Booking ref. ${this.state.bookingReference}`}
          body={
            <>
              The customer will recieve an email shortly confirming this booking.
            </>
          }
          cancelText="Close"
          onExit={() => this.props.onExit(false)}
        />
      ) : null}
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={() => this.handleExit}
        >
          <Card style={{ minWidth: 550, minHeight: 300 }}>
            {this.state.loading ? (
              <div className="grid-center-center padding-30">
                <Loader />
              </div>
            ) : (
                <form
                  className="padding-30 gap-20 col-1"
                  onSubmit={this.bookInstall}
                >
                  <div className="col-1 gap-5 margin-bottom-20">
                    <h1 className="ui-title-three">Book Smart Install</h1>
                    <h2 className="ui-title-five ui-title-sub">
                      Arrange an appointment with BES
                </h2>
                  </div>
                  <div className="col-1 gap-30">
                  <div className="col-1 gap-10">
                      <p className="ui-label">WAN Information</p>
                      {
                        this.state.wanLoading ? <p className="ui-body">Loading...</p> : !this.state.wan ? <p className="ui-body">No information found</p> : <p className="ui-body">Tier: {this.state.wan.tier}, Availability: {this.state.wan.availability ? "Yes" : "No"}, Notes: {this.state.wan.description ? this.state.wan.description : "N/A"}</p>
                      }
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Install Type</p>
                      <select required className="ui-select" value={this.state.jobType} onChange={e => this.setState({ jobType: e.currentTarget.value })}>
                        <option value="DUAL_FUEL_SMETS_2">Dual Fuel - SMETS 2</option>
                        <option value="ELECTRIC_SMETS_2">Electric - SMETS 2</option>
                        <option value="GAS_SMETS_2">Gas - SMETS 2</option>
                      </select>
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Install Availability</p>
                      <div className="col-2 gap-10">
                        <input
                          required
                          type="date"
                          className="ui-input"
                          value={this.state.date}
                          onChange={e => {
                            this.setState({
                              date: e.currentTarget.value
                            })
                          }}
                        />
                        <select required className="ui-select" value={this.state.dateVariance} onChange={e => this.setState({ dateVariance: Number(e.currentTarget.value) })}>
                          <option value="0">Exact Day</option>
                          <option value="3">+3 Days</option>
                          <option value="7">+7 Days</option>
                          <option value="14">+14 Days</option>
                          <option value="28">+28 Days</option>
                        </select>
                        <button className="ui-btn ui-btn-compact" onClick={this.getNextAvailableDate}>Find next available date</button>
                        <button className="ui-btn ui-btn-compact ui-btn-secondary" disabled={this.state.date === null ? true : false} onClick={this.getAvailableSlots}>Get slots</button>
                      </div>
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Install Slot</p>
                      <select className="ui-select" required onChange={e => this.setState({ selectedSlot: this.state.availableSlots[Number(e.currentTarget.value)] })}>
                        {
                          this.state.availableSlots === null ? <option value="">Select an install date</option> : this.state.availableSlots.length === 0 ? <option value="">No availability found</option> : <><option value="">Select a slot</option>{this.state.availableSlots.map((x, i) => <option value={i}>{moment(x.date).format("ddd Do MMM YYYY")} ({x.startTime} - {x.endTime})</option>)}</>
                        }
                      </select>
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Notes</p>
                      <textarea
                        className="ui-input"
                        style={{ height: 100 }}
                        value={this.state.notes}
                        onChange={e => {
                          this.setState({
                            notes: e.currentTarget.value
                          })
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="ui-btn margin-top-20 margin-right-20"
                      onClick={() => this.props.onExit(false)}
                    >
                      {this.props.cancelText ? this.props.cancelText : "Cancel"}
                    </button>
                    <button type="submit" className="ui-btn-primary margin-top-20">
                      Book
                </button>
                  </div>
                </form>)}
          </Card>
        </div>
      </Modal>
    </>
    );
  }
}

export default SmartInstall;
