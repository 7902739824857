import React from "react";
import moment from "moment";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";
import GenericMessage from "./GenericMessage";

const types = [{ code: "GC_DIRECT_DEBIT", friendly: "Direct Debit" },
{ code: "STRIPE_TOP_UP", friendly: "Account Topup" },
{ code: "TERMINAL_TOP_UP", friendly: "Terminal Topup" },
{ code: "METERING_CHARGE", friendly: "Metering Charge" },
{ code: "GC_REFUND", friendly: "Direct Debit Refund" },
{ code: "STRIPE_REFUND", friendly: "Account Topup Refund" },
{ code: "GENERIC_REFUND", friendly: "Refund" },
{ code: "BACS", friendly: "BACS Payment" },
{ code: "CHEQUE", friendly: "Cheque Payment" },
{ code: "BALANCE_AMENDMENT", friendly: "Balance Amendment" },
{ code: "PAPER_BILL", friendly: "Paper Bill" },
{ code: "EXIT_FEES", friendly: "Exit Fees" },
{ code: "LATE_PAYMENT", friendly: "Late Payment Fee" },
{ code: "GOODWILL", friendly: "Goodwill Credit" },
{ code: "GOODWILL_OS", friendly: "Goodwill Credit" },
{ code: "GSOP_ET", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_FINALBILL", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_SWITCHING", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_METERING", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_RECONNECTION", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_RECONNECTION_PREPAY", friendly: "Guaranteed Standards Payment" },
{ code: "GSOP_ADDITIONAL", friendly: "Guaranteed Standards Payment" }];

class AddTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: "",
      amount: 0,
      amountIsCredit: true,
      date: moment(),
      id: "",
      type: "",
      loading: false,
      success: null
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  addTransaction = async e => {
    e.preventDefault();
    this.setState({
      loading: true
    }, async () => {
      try {
        await this.props.api.post(`/transactions`, {
          amount: this.state.amountIsCredit ? this.state.amount : -Math.abs(this.state.amount),
          id: this.state.id,
          date: this.state.date,
          description: types.find(x => x.code === this.state.type).friendly,
          account: this.state.account
        });
        this.setState({
          loading: false,
          success: true
        });
      } catch (e) {
        this.setState({
          loading: false,
          success: false
        });
      }
    });
  };
  render() {
    return (
      this.state.success === true ? <GenericMessage title="Transaction added" subTitle={`On account number ${this.state.account}.`} body={<>This will reflect on the balance in real time.</>} cancelText="Close" onExit={() => this.props.onExit()} /> : this.state.success === false ? <GenericMessage title="Transaction failed" subTitle={`We hit an error when adding this to the sales ledger`} body={<>Please ensure this isn't already in the sales ledger. If the error persists please contact support.</>} cancelText="Close" onExit={() => this.props.onExit()} /> : <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }} loading={this.state.loading}>
            <form className="padding-30 gap-20 col-1" onSubmit={this.addTransaction}>
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">
                  Add a transaction
                </h1>
                <h2 className="ui-title-five ui-title-sub">
                  Add a new transaction to sales ledger
                </h2>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Account Number</p>
                <input
                  type="text"
                  placeholder="e.g 200008"
                  minLength={6}
                  maxLength={6}
                  required
                  name="account"
                  className="ui-input"
                  defaultValue={this.state.account}
                  onChange={e => this.setState({ account: e.currentTarget.value })}
                />
              </div>
              <div className="col-2 gap-20">
              <div className="col-1 gap-10">
                <p className="ui-label">Amount (£)</p>
                <input
                  type="number" 
                  min="0.01" 
                  step="0.01" 
                  max="5000"
                  placeholder="e.g £50.00"
                  required
                  className="ui-input"
                  onChange={e => this.setState({ amount: Number(e.currentTarget.value) * 100 < 0 ? 0 : Number(e.currentTarget.value) * 100 > 500000 ? 500000 : Number(e.currentTarget.value) * 100 })}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Amount Type</p>
                <div className="col-2 gap-5">
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.amountIsCredit ? "-secondary" : ""
                    } ui-btn-compact margin-right-5`}
                    onClick={() =>
                      this.setState({ amountIsCredit: true })
                    }
                  >
                    Credit
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      !this.state.amountIsCredit ? "-secondary" : ""
                    } ui-btn-compact`}
                    onClick={() =>
                      this.setState({ amountIsCredit: false })
                    }
                  >
                    Debit
                  </button>
                  </div>
              </div>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Transaction Date</p>
                <input
                  type="date"
                  required
                  className="ui-input"
                  defaultValue={this.state.date.format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={e => {
                    this.setState({ date: moment(e.currentTarget.value, "YYYY-MM-DD") });
                  }}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Transaction Type</p>
                <select className="ui-select" required onChange={e => {
                  this.setState({ type: e.currentTarget.value }, () => {
                    if (this.state.type !== "GC_DIRECT_DEBIT" && this.state.type !== "STRIPE_REFUND" && this.state.type !== "STRIPE_TOP_UP" && this.state.type !== "GC_REFUND") {
                      this.setState({
                        id: `${this.state.type}_${Math.random().toString(36).substr(2, 34).toUpperCase()}`
                      });
                    } else {
                      this.setState({
                        id: ""
                      });
                    }
                  });
                }}>
                  <option value="">Select an option</option>
                  {
                    types.map(x => <option value={x.code}>{x.code}</option>)
                  }
                </select>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">{this.state.type === "GC_DIRECT_DEBIT" ? "GoCardless Payment ID" : this.state.type === "GC_REFUND" ? "GoCardless Refund ID" : this.state.type === "STRIPE_TOP_UP" ? "Stripe Charge ID" : this.state.type === "STRIPE_REFUND" ? "Stripe Refund ID" : "External Sales ID"}</p>
                <input
                  type="text"
                  required
                  disabled={(this.state.type === "GC_DIRECT_DEBIT" || this.state.type === "STRIPE_REFUND" || this.state.type === "STRIPE_TOP_UP" || this.state.type === "GC_REFUND") ? false : true}
                  className="ui-input"
                  value={this.state.id}
                  onChange={e => this.setState({ id: e.currentTarget.value })}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary margin-top-20">
                  Add £{(this.state.amountIsCredit ? (this.state.amount / 100) : -Math.abs(this.state.amount / 100)).toFixed(2)} transaction
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default AddTransaction;
