import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";
import CardButton from "../components/interactive/CardButton";
import axios from "axios";
import GenericMessage from "./GenericMessage";

class CreditControlComms extends React.Component {
  constructor() {
    super();
    this.state = {
      commsSentModal: false
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  };
  render() {
    return <>
      {this.state.commsSentModal ? <GenericMessage title="Communication sent" subTitle={`It's on the way.`} body={<>The customer will recieve the SMS within the next few minutes.</>} cancelText="Close" onExit={() => this.setState({ commsSentModal: false })} /> : null}
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={() => this.handleExit}
        >
          <Card style={{ minWidth: 550, backgroundColor: "var(--ui-bg)" }}>
            <div className="padding-30 gap-20 col-1">
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">Credit Control Communications</h1>
                <h2 className="ui-title-five ui-title-sub">Send SMS Notifications</h2>
              </div>
              <div className="col-1 gap-20">
              <CardButton
                  compact
                  title="Debt (Not Contactable - Friendly)"
                  colour="green"
                  icon="comment"
                  onClick={async () => {
                    await this.props.api.post(`/customers/${this.props.accountNumber}/sms`, { messageId: "DEBT_NOT_CONTACTABLE_FRIENDLY" });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  title="Debt (Not Contactable - Multiple Times)"
                  colour="purple"
                  icon="comment"
                  onClick={async () => {
                    await this.props.api.post(`/customers/${this.props.accountNumber}/sms`, { messageId: "DEBT_NOT_CONTACTABLE_MULTI" });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  title="Debt (Urgent Request)"
                  colour="orange"
                  icon="comment"
                  onClick={async () => {
                    await this.props.api.post(`/customers/${this.props.accountNumber}/sms`, { messageId: "DEBT_NOT_CONTACTABLE_AGGRESSIVE" });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  title="Debt (Field - No Answer)"
                  colour="green"
                  icon="home"
                  onClick={async () => {
                    await this.props.api.post(`/customers/${this.props.accountNumber}/sms`, { messageId: "FIELD_NO_ANSWER_DOOR" });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
              </div>
              <div className="margin-top-25">
                <button
                  type="button"
                  className="ui-btn margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                  </button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    </>;
  }
}

export default CreditControlComms;
