import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import moment from "moment";

class FunnelAnalysis extends React.Component {
  constructor() {
    super();
    this.state = {
      funnel: null,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  getDropOff(q, s) {
    if(s > q) return "100.00%";
    return `${Math.abs((((q - (q - s)) / q) * 100) - 100).toFixed(2)}% (${q - s < 0 ? 0 : (q - s).toLocaleString()})`;
  }
  getConversionRate(q, s) {
    if(s > q) return "100.00%";
    return `${(((q - (q - s)) / q) * 100).toFixed(2)}%`;
  }
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const funnel = await this.props.api.get(
            `/analytics/sales/funnel?start=${this.state.startDate.format(
              "YYYY-MM-DD"
            )}&end=${this.state.endDate.format("YYYY-MM-DD")}`
          );
          this.setState({
            funnel: funnel.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({error:true,loading:false});
        }
      }
    );
  }
  get funnelOverview(){
    const data = {...this.state.funnel};
    data.quote = data.comparison.quote + data.normal.quote;
    data.detailForm = data.comparison.detailForm + data.normal.detailForm;
    data.success = data.comparison.success + data.normal.success;
    delete data.comparison;
    delete data.normal;
    return Object.keys(data).map((x,i) => ({
      "Stage": x === "visits" ? "Visits" : x === "quoteForm" ? "Quote Form" : x === "quote" ? "Quote Landing" : x === "detailForm" ? "Details Form" : "Converison",
      "Users": data[x],
      "Dropoff": x === "success" ? "N/A" : this.getDropOff(data[x], data[Object.keys(data)[i+1]])
    }));
  }
  dropOffTable(key){
    return Object.keys(this.state.funnel[key]).map((x,i) => ({
      "Stage": x === "visits" ? "Visits" : x === "quoteForm" ? "Quote Form" : x === "quote" ? "Quote Landing" : x === "detailForm" ? "Details Form" : "Converison",
      "Users": this.state.funnel[key][x],
      "Dropoff": x === "success" ? "N/A" : this.getDropOff(this.state.funnel[key][x], this.state.funnel[key][Object.keys(this.state.funnel[key])[i+1]])
    }))
  }
  render() {
    return (
      this.state.error ?  <Card message="An error occurred" style={{height:"100%",borderRadius: "12px 12px 0 0"}} messageIcon="bug"/> : <div className="col-1 gap-40 margin-bottom-40">
      <div className="col-1 gap-10">
        <p className="ui-label">Report filters</p>
        <Card>
          <div
            className="padding-20 col-4 gap-20"
            style={{
              alignItems: "end",
              gridTemplateColumns: "auto auto max-content"
            }}
          >
            <div>
              <p className="ui-label margin-bottom-10">Start date</p>
              <input
                className="ui-input"
                defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                onChange={e =>
                  this.setState({ startDate: moment(e.currentTarget.value) })
                }
                type="datetime-local"
                placeholder="Start date"
              />
            </div>
            <div>
              <p className="ui-label margin-bottom-10">End date</p>
              <input
                className="ui-input"
                defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                onChange={e =>
                  this.setState({ endDate: moment(e.currentTarget.value) })
                }
                type="datetime-local"
                placeholder="End date"
              />
            </div>
            <div>
              <button
                className="ui-btn-compact"
                onClick={() => this.getData()}
              >
                <i className="far fa-search margin-right-10" />
                Search
              </button>
            </div>
          </div>
        </Card>
        </div>
        <div className="col-1 gap-10">
          <Card style={{ minHeight: 120 }}>
            {!this.state.loading ? (
              <div className="col-4 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                figureColour="blue"
                compact
                  label="Total Conversions"
                  figure={(this.state.funnel.comparison.success + this.state.funnel.normal.success).toLocaleString()}
                />
                <Stat
                figureColour="blue"
                compact
                  label="Overall Conversion"
                  figure={this.getConversionRate(this.state.funnel.visits, (this.state.funnel.comparison.success + this.state.funnel.normal.success))}
                />
                <Stat
                figureColour="blue"
                compact
                  label="Comparison Conversion"
                  figure={this.getConversionRate(this.state.funnel.comparison.quote, this.state.funnel.comparison.success)}
                />
                <Stat
                figureColour="blue"
                compact
                  label="Quote Conversion"
                  figure={this.getConversionRate(this.state.funnel.normal.quote, this.state.funnel.normal.success)}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Funnel overview</p>
        <Card style={{ height: 400 }} className="padding-20">
            {!this.state.loading ? <Table data={this.funnelOverview}/> : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        <div className="col-2 gap-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Dropoff overview (Comparison)</p>
        <Card style={{ height: 400 }} className="padding-20">
            {!this.state.loading ? <Table data={this.dropOffTable("comparison")}/>: (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
          <div className="col-1 gap-10">
          <p className="ui-label">Dropoff overview (Quote only)</p>
        <Card style={{ height: 400 }} className="padding-20">
            {!this.state.loading ? <Table data={this.dropOffTable("normal")}/>: (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

export default FunnelAnalysis;
