import React from "react";
import Particles from "react-particles-js";
import Card from "../components/generics/Card";
import Loader from "../components/generics/Loader";
import Axios from "../../Axios";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      email: "",
      password: "",
      sectionFadeIn: false,
      invalid: false
    };
  }
  componentDidMount() {
    if(localStorage.apollo_key) this.props.history.push("/portal");
    setTimeout(() => {
      this.setState({
        sectionFadeIn: true
      });
    }, 500);
  }
  signInAttempt = e => {
    e.preventDefault();
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const res = await Axios().post("auth/signin", {
            email: this.state.email,
            password: this.state.password
          });
          localStorage.setItem("apollo_key", res.data.data.token);
          this.setState(
            {
              sectionFadeIn: false
            },
            () => {
              setTimeout(() => {
                this.props.history.push("/portal");
              }, 600);
            }
          );
        } catch (e) {
          this.setState(
            {
              loading: false,
              invalid: true
            }
          );
        }
      }
    );
  };
  render() {
    return (
      <div
        className={`container-small sign-in grid-center-center transition-3 ${
          this.state.sectionFadeIn ? "display" : "hidden"
        }`}
      >
        <Particles params={{
                    particles: {
                        number: {
                            value: 600
                        },
                        color: {
                            value: ["#30AF5F"]
                        },
                        shape: {
                            type: 'circle',
                            stroke: {
                                width: 0,
                                color: '#000000'
                            },
                            polygon: {
                                nb_sides: 5
                            }
                        },
                        opacity: {
                            value: 1
                        },
                        size: {
                            value: 2,
                            random: true,
                            anim: {
                                enable: false,
                                speed: 0.025,
                                size_min: 0.1,
                                sync: false
                            }
                        },
                        line_linked: {
                            enable: false
                        },
                        move: {
                            enable: true,
                            speed: 5,
                            direction: 'none',
                            random: false,
                            straight: false,
                            out_mode: 'out',
                            bounce: true,
                            attract: {
                                enable: false,
                                rotateX: 600,
                                rotateY: 1200
                            }
                        }
                    },
                    interactivity: {
                        detect_on: 'canvas',
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'repulse'
                            },
                            onclick: {
                                enable: true,
                                mode: 'push'
                            },
                            resize: true
                        },
                        modes: {
                            repulse: {
                                distance: 150,
                                duration: 0.4
                            },
                            push: {
                                particles_nb: 50
                            }
                        }
                    },
                    retina_detect: true
                }} />
        <Card className="col-start-2 transition-2">
          {this.state.loading ? (
            <div className="grid-center-center" style={{ height: 538 }}>
              {this.state.successTransition ? null : <Loader />}
            </div>
          ) : (
            <div className="col-1 gap-20 padding-right-40 padding-left-40 padding-top-50 padding-bottom-50 ">
              <img
                alt="logo"
                src="https://green-cdn.fra1.digitaloceanspaces.com/branding/logo-r-white.svg"
                height={32}
              />
              <div className="col-1 gap-5 margin-top-20 margin-bottom-30">
                <h1 className="ui-title-one">Green Studio</h1>
                <h2 className="ui-title-three ui-title-sub">Sign in to your account.</h2>
              </div>
              <form onSubmit={this.signInAttempt} className="col-1 gap-20">
                <div className="col-1 gap-10">
                  <label className="ui-label">Email Address</label>
                  <input
                    required
                    className={`ui-input ${this.state.invalid ? "warning" : ""}`}
                    type="email"
                    onChange={e =>
                      this.setState({ email: e.currentTarget.value })
                    }
                  />
                </div>
                <div className="col-1 gap-10">
                  <label className="ui-label">Password</label>
                  <input
                    required
                    className={`ui-input ${this.state.invalid ? "warning" : ""}`}
                    type="password"
                    onChange={e =>
                      this.setState({ password: e.currentTarget.value })
                    }
                  />
                </div>
                <div className="margin-top-20">
                  <button className="ui-btn-primary">Sign in</button>
                </div>
              </form>
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default SignIn;
