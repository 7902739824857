export default (name, type, byteChars) => {
  const byteNumbers = new Array(byteChars.length);
  for (let i = 0; i < byteChars.length; i++) {
    byteNumbers[i] = byteChars.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type });
  const el = document.createElement("a");
  el.download = name;
  el.href = window.URL.createObjectURL(blob);
  el.addEventListener("onclick", () => {
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, name);
      return false;
    }
  });
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};
