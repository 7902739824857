import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";

class CreatePartnerTariff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dyballName: "",
      heading: "",
      subHeading: "",
      dual: false,
      elecOnly: false,
      prepay: false,
      partnerId: props.partnerId
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  saveTariff = async e => {
    e.preventDefault();
    await this.props.api.post("partners/tariffs", this.state);
    this.props.onExit(true);
  };
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }}>
            <form className="padding-30 gap-20 col-1" onSubmit={this.saveTariff}>
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">
                  Allocate a new tariff
                </h1>
                <h2 className="ui-title-five ui-title-sub">
                  This will be available to the partner through all channels.
                </h2>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Tariff</p>
                <select className="ui-select" required onChange={e => {
                  if (e.currentTarget.value) {
                    const tariff = this.props.tariffs[Number(e.currentTarget.value)];
                    this.setState({
                      dyballName: tariff.name,
                      heading: tariff.name,
                      subHeading: tariff.fixed ? `${tariff.length} Month Fixed` : `Variable Rate`
                    });
                  } else {
                    this.setState({
                      dyballName: e.currentTarget.value
                    });
                  }
                }}>
                  <option value="">Select a tariff</option>
                  {
                    this.props.tariffs.sort((a, b) => a.name.localeCompare(b.name)).map(x => `${x.name} (${x.fixed ? `${x.length} Month Fixed` : `Variable`})`).map((x, i) => <option value={i}>{x}</option>)
                  }
                </select>
                <p className="ui-label margin-top-10">Heading Text</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  value={this.state.heading}
                  onChange={e => this.setState({ heading: e.currentTarget.value })}
                />
                <p className="ui-label margin-top-10">Sub Heading Text</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  value={this.state.subHeading}
                  onChange={e => this.setState({ subHeading: e.currentTarget.value })}
                />
                <p className="ui-label margin-top-10">Availability</p>
                <div className="col-3 gap-10">
                  <button
                    type="button"
                    className={`ui-btn${this.state.dual ? "-secondary" : ""
                      } ui-btn-compact`}
                    onClick={() =>
                      this.setState({ dual: !this.state.dual })
                    }
                  >
                    Dual Fuel
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${this.state.elecOnly ? "-secondary" : ""
                      } ui-btn-compact`}
                    onClick={() =>
                      this.setState({ elecOnly: !this.state.elecOnly })
                    }
                  >
                    Electric Only
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${this.state.prepay ? "-secondary" : ""
                      } ui-btn-compact`}
                    onClick={() =>
                      this.setState({ prepay: !this.state.prepay })
                    }
                  >
                    Prepay
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary margin-top-20">
                  Allocate
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default CreatePartnerTariff;
