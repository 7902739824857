import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Table from "../../../components/analytical/Table";
import Loader from "../../../components/generics/Loader";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class SalesTrends extends React.Component {
  constructor() {
    super();
    this.state = {
      sales: null,
      quotes: null,
      syncModal: false,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      interval: "hour",
      intervalStatic: "hour",
      loading: true,
      typeSplit: null
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        const sales = await this.props.api.get(
          `/sales?start=${this.state.startDate.format(
            "YYYY-MM-DDTHH:mm"
          )}&end=${this.state.endDate.format("YYYY-MM-DDTHH:mm")}&interval=${this.state.interval
          }${disableCache ? "&resetCache=true" : ""}`
        );
        const quotes = await this.props.api.get(
          `/quotes?start=${this.state.startDate.format(
            "YYYY-MM-DDTHH:mm"
          )}&end=${this.state.endDate.format("YYYY-MM-DDTHH:mm")}&interval=${this.state.interval
          }${disableCache ? "&resetCache=true" : ""}`
        );
        this.setState({
          sales: sales.data.data,
          quotes: quotes.data.data,
          intervalStatic: this.state.interval,
          typeSplit: Object.keys(sales.data.data.type).reduce((a,x) => {
            a.push({
              name: x,
              value: sales.data.data.type[x]
            });
            return a;
          }, []),
          loading: false
        });
      }
    );
  }
  getConversionRate(q, s) {
    return (((q - (q - s)) / q) * 100).toFixed(2) + "%";
  }
  formatInterval(x) {
    switch (this.state.intervalStatic) {
      case "hour":
        return moment()
          .hour(x)
          .format("ha");
      case "date":
        return moment(x).format("DD/MM/YYYY");
      case "month":
        return moment()
          .month(x - 1)
          .format("MMM YYYY");
      case "day":
        return moment()
          .date(x)
          .format("Do");
      case "dayofweek":
        return x;
      case "year":
        return x;
    }
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live sales?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    sales: null,
                    quotes: null,
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        <button
          className="ui-btn sync-button"
          onClick={() => this.setState({ syncModal: true })}
        >
          <i className="fad fa-sync" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto auto auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Start date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ startDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="Start date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">End date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ endDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="End date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">Interval</p>
                <select
                  className="ui-select"
                  value={this.state.interval}
                  onChange={e =>
                    this.setState({ interval: e.currentTarget.value })
                  }
                >
                  <option value="date">Date</option>
                  <option value="hour">Hourly</option>
                  <option value="day">Daily</option>
                  <option value="dayofweek">Day of Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Quotes"
                  figure={this.state.quotes.total.toLocaleString()}
                />
                <Stat
                  label="Sales"
                  figure={this.state.sales.total.toLocaleString()}
                />
                {console.log(this.state.quotes)}
                <Stat
                  label="Website Conversion Rate"
                  figure={this.getConversionRate(
                    this.state.quotes.total,
                    this.state.sales.channels.Website
                  )}
                />
              </div>
            ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Interval breakdown</p>
          <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart
                    data={Object.keys(this.state.quotes.interval)
                      .sort((a, b) => a - b)
                      .map(x => {
                        console.log(this.state.quotes.interval)
                        return {
                          name: this.formatInterval(x),
                          Sales: this.state.sales.interval ? this.state.sales.interval[x] ? this.state.sales.interval[x] : 0 : 0,
                          Quotes: this.state.quotes.interval[x]
                        }
                      })}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={30}
                      type="number"
                    // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Quotes"
                      fill={colors[1]}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Sales"
                      fill={colors[0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
        <div className="col-1 gap-40">
          <div className="col-2 gap-30">
            <div className="col-1 gap-10">
              <p className="ui-label">Channel split</p>
              <Card style={{ height: 400 }}>
                {!this.state.loading ? (
                  <div className="padding-15">
                    <ResponsiveContainer>
                      <BarChart
                        data={Object.keys(this.state.sales.channels).map(x => ({
                          name: x,
                          Sales: this.state.sales.channels[x]
                        }))}
                      >
                        <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                        <XAxis height={20} dataKey="name" />
                        <YAxis
                          width={30}
                          type="number"
                        // domain={[0, dataMax => dataMax * 1.25]}
                        />
                        <Tooltip
                          cursor={{
                            fill: getComputedStyle(
                              document.documentElement
                            ).getPropertyValue("--ui-border")
                          }}
                          formatter={value =>
                            new Intl.NumberFormat("en").format(value)
                          }
                        />
                        <Bar
                          isAnimationActive={false}
                          dataKey="Sales"
                          fill={colors[2]}
                        />
                      </BarChart>
                    </ResponsiveContainer>

                  </div>
                ) : (
                    <div className="grid-center-center">
                      <Loader />
                    </div>
                  )}
              </Card>
            </div>
            <div className="col-1 gap-10">
              <p className="ui-label">Type Split</p>
              <Card style={{ height: 400 }}>
                {
                  this.state.loading ? (
                    <div className="grid-center-center">
                      <Loader />
                    </div>
                  ) :
                <div className="padding-top-15 padding-bottom-10">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={this.state.typeSplit}
                        strokeWidth={0}
                        // paddingAngle={5}
                        innerRadius={70}
                      >
                        {this.state.typeSplit.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                      </Pie>

                      <Tooltip />
                      <Legend
                        payload={this.state.typeSplit.map((item, index) => ({
                          id: item.name,
                          type: "circle",
                          color: colors[index],
                          value: `${item.name} (${item.value})`
                        }))}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              }
              </Card>
            </div>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Tariff split</p>
            <Card style={{ height: 400 }}>
              {!this.state.loading ? (
                <div className="padding-15">
                  <ResponsiveContainer>
                    <BarChart
                      data={Object.keys(this.state.sales.tariffs).map(x => ({
                        name: x,
                        Sales: this.state.sales.tariffs[x]
                      })).sort((a, b) => b.Sales - a.Sales)}
                    >
                      <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                      <XAxis height={20} dataKey="name" />
                      <YAxis
                        width={30}
                        type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                      />
                      <Tooltip
                        cursor={{
                          fill: getComputedStyle(
                            document.documentElement
                          ).getPropertyValue("--ui-border")
                        }}
                        formatter={value =>
                          new Intl.NumberFormat("en").format(value)
                        }
                      />
                      <Bar
                        isAnimationActive={false}
                        dataKey="Sales"
                        fill={colors[2]}
                      />
                    </BarChart>
                  </ResponsiveContainer>

                </div>
              ) : (
                  <div className="grid-center-center">
                    <Loader />
                  </div>
                )}
            </Card>
          </div>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Interval conversions</p>
          <Card style={{ height: 400 }} className="padding-20">
            {!this.state.loading ? <Table data={Object.keys(this.state.quotes.interval).sort((a, b) => a - b).map(x => ({
              "Interval": this.formatInterval(x),
              "Quotes": this.state.quotes.interval[x],
              "Sales": this.state.sales.interval ? this.state.sales.interval[x] ? this.state.sales.interval[x] : 0 : 0,
              "Conversion Rate": this.getConversionRate(this.state.quotes.interval[x], this.state.sales.interval ? this.state.sales.interval[x] ? this.state.sales.interval[x] : 0 : 0)
            }))} /> : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
      </div>
    );
  }
}

export default SalesTrends;
