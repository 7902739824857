import React from "react";
import CardButton from "../../../components/interactive/CardButton";
import AddTransaction from "../../../modals/AddTransaction";
import BalanceTransfer from "../../../modals/BalanceTransfer";

class ImportTransactions extends React.Component {
  constructor() {
    super();
    this.state = {
      transactionModal: false,
      transferModal: false
    };
  }
  render() {
    return <div className="col-1 gap-50">
      {this.state.transactionModal ? <AddTransaction api={this.props.api} onExit={reload => {
        this.setState({ transactionModal: false });
      }} /> : null}
       {this.state.transferModal ? <BalanceTransfer api={this.props.api} onExit={reload => {
        this.setState({ transferModal: false });
      }} /> : null}
      <div className="col-1 gap-10">
        <p className="ui-label">Quick actions</p>
        <div className="col-2 gap-50">
        <CardButton
            title="Add a transaction"
            colour="green"
            subTitle="Add a new transaction to sales ledger"
            icon="arrow-right"
            onClick={() => this.setState({ transactionModal: true })}
          />
          <CardButton
            title="Balance transfer"
            colour="purple"
            subTitle="Transfer from an existing account"
            icon="exchange"
            onClick={() => this.setState({ transferModal: true })}
          />
        </div>
      </div>
    </div>
  }
}

export default ImportTransactions;
