import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import LocationOverview from "../views/field/LocationOverview";
import TerminalPayments from "../views/field/TerminalPayments";
import FormData from "../views/field/FormData";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/field/location-overview"
      render={internalProps => (
        <LocationOverview {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/field/terminal-payments"
      render={internalProps => (
        <TerminalPayments {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/field/form-data"
      render={internalProps => (
        <FormData {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/field/location-overview" />} />
  </Switch>
);

export default Routes;
