import React from "react";

import Routes from "../../routes/Admin";
import SidebarLayout from "../../layouts/Sidebar";

class Data extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="admin"
        navigationOptions={[
          {
            icon: "users-cog",
            text: "User Management",
            link: "/portal/admin/user-management"
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Data;
