import React from "react";
import Card from "../../../components/generics/Card";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import moment from 'moment';

class UrnLookup extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      data: null,
      urn: ""
    };
  }
  async getData() {
    this.setState({
      loading: true
    }, async () => {
      try {
        const customer = await this.props.api.get("/customers/sftp/" + this.state.urn);
        console.log(customer.data);
        this.setState({
          data: {
            "Account Number": customer.data.data.accountNumber,
            "URN": `${customer.data.data.urn} (${customer.data.data.channel})`,
            "Customer Name": customer.data.data.customerName,
            "MPAN": customer.data.data.mpan,
            "MPRN": customer.data.data.mprn,
            "Sale Date": customer.data.data.saleDate ? moment(customer.data.data.saleDate).format("DD/MM/YYYY") : null,
            "Start Date": customer.data.data.startDate ? moment(customer.data.data.startDate).format("DD/MM/YYYY") : null,
            "End Date": customer.data.data.endDate ? moment(customer.data.data.endDate).format("DD/MM/YYYY") : null,
            "Gas Status": customer.data.data.gasRegiStatus,
            "Electric Status": customer.data.data.elecRegiStatus
          },
          loading: false
        });
      } catch (e) {
        this.setState({
          loading: false,
          data: null
        })
      }
    });
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">URN</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.urn}
                  onChange={e =>
                    this.setState({ urn: e.currentTarget.value })
                  }
                  type="text"
                  placeholder="URN"
                />
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Data Response</p>
          {
            !this.state.loading && !this.state.data ? <Card message="Nothing found, search another URN" style={{ padding: 50 }} messageIcon="empty-set" /> : <Card style={{ minHeight: 120, padding: 16 }}>{this.state.loading ?
              <div className="grid-center-center">
                <Loader />
              </div> : <Table
                data={Object.keys(this.state.data).map(x => ({
                  Key: x,
                  Value: this.state.data[x]
                }))}
              />}</Card>
          }
        </div>
      </div>
    );
  }
}

export default UrnLookup;
