import axios from "axios";

export default token => {
  return axios.create({
    baseURL: "https://api.studio.green.energy/",
    // baseURL: "http://localhost:3030/",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  });
};
