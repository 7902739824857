import React from "react";
import Routes from "../../../routes/support/Profile";

class Profile extends React.Component {
  constructor() {
    super();
  }
  componentDidMount() {
    this.setNav();
  }
  componentDidUpdate(oldProps) {
    if (
      this.props.match.params.accountNumber !==
      oldProps.match.params.accountNumber
    ) {
      this.setNav();
    }
  }
  async setNav() {
    const navOpts = [
      {
        icon: "user",
        text: "Account Overview",
        link: `/portal/support/customers/${this.props.match.params.accountNumber}/overview`
      },
      {
        icon: "envelope",
        text: "Communication",
        link: `/portal/support/customers/${this.props.match.params.accountNumber}/communication`
      },
      {
        icon: "credit-card-blank",
        text: "Billing & Payments",
        link: `/portal/support/customers/${this.props.match.params.accountNumber}/billing-and-payments`
      },
      {
        icon: "tachometer",
        text: "Meter Readings",
        link: `/portal/support/customers/${this.props.match.params.accountNumber}/meter-readings`
      }
    ];
    if(this.props.user.smartAccess){
      navOpts.push({
        icon: "calendar",
        text: "Smart Booking",
        link: `/portal/support/customers/${this.props.match.params.accountNumber}/smart-booking`
      });
    }
    this.props.setNavigationOptions(navOpts);
  }
  render() {
    return <Routes
    api={this.props.api}
    user={this.props.user}
    accountNumber={this.props.match.params.accountNumber}
  />;
  }
}

export default Profile;
