import React from "react";
import Routes from "../../../routes/data/SiteAnalytics";

class SiteAnalytics extends React.Component {
  render() {
    return (
      <Routes api={this.props.api} user={this.props.user}/>
    );
  }
}

export default SiteAnalytics;
