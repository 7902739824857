import React from "react";

const Stat = props => (
  <div className={`ui-stat ${props.compact ? "ui-stat-compact" : ""}`} style={props.style}>
    <label className="ui-label">{props.label}</label>
    <div className={`ui-title-one ${props.figureColour ? `colour-${props.figureColour}` : ""}`}>
      {props.figure}
      <span>
        <i
          className={`fad fa-${props.icon} ${
            props.negative
              ? "colour-orange"
              : props.positive
              ? "colour-green"
              : "colour-orange"
          }`}
        ></i>
        <p>
          {props.iconFigure ? props.iconFigure : ""}
        </p>
      </span>
    </div>
  </div>
);

export default Stat;
