import React from "react";

import Routes from "../../routes/Payments";
import SidebarLayout from "../../layouts/Sidebar";

class Payments extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="payments"
        navigationOptions={[
          {
            icon: "database",
            text: "Sales Ledger",
            link: "/portal/payments/sales-ledger",
            subNavigation: [
              {
                icon: "search-dollar",
                text: "Transaction Explorer",
                link: "/portal/payments/sales-ledger/transaction-explorer"
              },
              {
                icon: "upload",
                text: "Import Transactions",
                link: "/portal/payments/sales-ledger/import-transactions"
              }
            ]
          },
          {
            icon: "credit-card",
            text: "Top-up Overview",
            link: "/portal/payments/topup-overview"
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Payments;
