import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import BalanceBands from "../views/billing/BalanceBands";
import BillingDashboard from "../views/billing/BillingDashboard";
import DisputeAgeing from "../views/billing/DisputeAgeing";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/billing/balance-bands"
      render={internalProps => (
        <BalanceBands {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/billing/billing-dashboard"
      render={internalProps => (
        <BillingDashboard {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/billing/dispute-ageing"
      render={internalProps => (
        <DisputeAgeing {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/billing/billing-dashboard" />} />
  </Switch>
);

export default Routes;
