import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import moment from "moment";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import downloadFile from "../../../components/helpers/downloadFile";
import { json2csv } from 'json-2-csv';

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class SupplierIntelligence extends React.Component {
  constructor() {
    super();
    this.state = {
      startDate: moment()
        .startOf("day")
        .subtract(1, "week"),
      endDate: moment().endOf("day"),
      data: null,
      loading: false,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  downloadScope = async () => {
    json2csv(this.state.data.map(x => {
      const d = {
        Type: x.type,
        "Previous Supplier": x.prevSupplier,
        "New Supplier": x.newSupplier,
        ...x
      };
      delete d.type;
      delete d.prevSupplier;
      delete d.newSupplier;
      return d;
    }), (err,csv) => {
      downloadFile(
        `supplier_insights_${this.state.startDate.format(
          "YYYYMMDD"
        )}_${this.state.endDate.format("YYYYMMDD")}.csv`,
        "text/csv",
        csv.replace(/undefined/g, "0")
      );
    });
  };
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const suppliers = await this.props.api.get(
            `/customers/suppliers?start=${this.state.startDate.format(
              "YYYY-MM-DDTHH:mm"
            )}&end=${this.state.endDate.format("YYYY-MM-DDTHH:mm")}`
          );

          this.setState({
            data: suppliers.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  bestStatus(keys, supplierKey = "prevSupplier") {
    return this.state.data
      .reduce((a, b) => {
        if (b[supplierKey]) {
          let curIndx = a.findIndex(x => x.supplier === b[supplierKey]);
          if (curIndx === -1) {
            a.push({
              supplier: b[supplierKey],
              count: 0
            });
            curIndx = a.length - 1;
          }
          keys.forEach(key => {
            a[curIndx].count += b[key] ? b[key] : 0;
          });
        }
        return a;
      }, [])
      .sort((a, b) => b.count - a.count)[0].supplier;
  }
  get fuelChartData() {
    return this.state.data
      ? this.state.data.reduce((a, x) => {
          const d = { ...x };
          const type = d.type;
          delete d.type;
          delete d.prevSupplier;
          delete d.newSupplier;
          Object.keys(d).forEach(key => {
            if (a.findIndex(x => x.name === key) === -1)
              a.push({
                Gas: 0,
                Electric: 0,
                name: key
              });
            a[a.findIndex(x => x.name === key)][
              type === "gas" ? "Gas" : "Electric"
            ] += d[key];
          });
          return a;
        }, [])
      : [];
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={this.downloadScope}>
          <i className="fad fa-download" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Start date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ startDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="Start date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">End date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ endDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="End date"
                />
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 260 }}>
            {!this.state.loading ? (
              <div className="col-1">
                <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                  <Stat
                    label="Best Gain"
                    figure={
                      this.state.data ? this.bestStatus(["Registered"]) : ""
                    }
                  />
                  <Stat
                    label="Highest Loss"
                    figure={
                      this.state.data
                        ? this.bestStatus(["Lost"], "newSupplier")
                        : ""
                    }
                  />
                  <Stat
                    label="Most Objections"
                    figure={
                      this.state.data
                        ? this.bestStatus([
                            "Objection Received",
                            "Objection Upheld"
                          ])
                        : ""
                    }
                  />
                </div>
                <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                  <Stat
                    label="Total Registered"
                    figure={
                      this.state.data
                        ? this.state.data
                            .reduce(
                              (a, b) => (a += b.Registered ? b.Registered : 0),
                              0
                            )
                            .toLocaleString()
                        : ""
                    }
                  />
                  <Stat
                    label="Total Lost"
                    figure={
                      this.state.data
                        ? this.state.data.reduce(
                            (a, b) => (a += b.Lost ? b.Lost : 0),
                            0
                          )
                        : ""
                    }
                  />
                  <Stat
                    label="Total Objections"
                    figure={
                      this.state.data
                        ? this.state.data.reduce(
                            (a, b) =>
                              (a += b["Objection Received"]
                                ? b["Objection Received"]
                                : 0 + b["Objection Upheld"]
                                ? b["Objection Upheld"]
                                : 0),
                            0
                          )
                        : ""
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Status overview by fuel</p>
          <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart data={this.fuelChartData}>
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={20}
                      type="number"
                      domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Gas"
                      fill={colors[1]}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Electric"
                      fill={colors[0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default SupplierIntelligence;
