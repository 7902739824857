import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Overview from "../../views/support/profile/Overview";
import Billing from "../../views/support/profile/Billing";
import Communication from "../../views/support/profile/Communication";
import Readings from "../../views/support/profile/Readings";
import Smart from "../../views/support/profile/Smart";

const Routes = props => (
    <Switch>
        <Route
            path="/portal/support/customers/:accountNumber/overview"
            render={internalProps => (
                <Overview {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/support/customers/:accountNumber/billing-and-payments"
            render={internalProps => (
                <Billing {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/support/customers/:accountNumber/communication"
            render={internalProps => (
                <Communication {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/support/customers/:accountNumber/meter-readings"
            render={internalProps => (
                <Readings {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/support/customers/:accountNumber/smart-booking"
            render={internalProps => (
                <Smart {...internalProps} {...props} />
            )}
        />
        <Route render={props => {
            return <Redirect to={`/portal/support/customers/${props.match.params.accountNumber}/overview`} />
        }} />
    </Switch>
);

export default Routes;
