import React from "react";

import Routes from "../../routes/Support";
import SidebarLayout from "../../layouts/Sidebar";

class Support extends React.Component {
  constructor(){
    super();
    this.state = {
      navigationOptions: []
    }
  }
  setNavigationOptions = navigationOptions => {
    this.setState({navigationOptions});
  }
  render() {
    return (
      <SidebarLayout
        onSearch={async query => {
          try {
            const accounts = await this.props.api.get(
              `/customers?query=${query}&take=10`
            );
            console.log(accounts.data);
            return accounts.data.data.filter(x => x.ContactEmail !== "dead@green.energy").map(x => ({
                title: x.CustomerName,
                content: x.ContactAddress.replace(/, , /g,"").replace(/,, /g,""),
                accountNumber: x.AccountNumber,
                path: `/portal/support/customers/${x.AccountNumber}/overview`
              }));
          } catch (e) {
            console.log(e);
            return [];
          }
        }}
        searchPlaceholder="Search by account number or name..."
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="support"
        navigationOptions={this.state.navigationOptions}
      >
        <Routes api={this.props.api} user={this.props.user} setNavigationOptions={this.setNavigationOptions}/>
      </SidebarLayout>
    );
  }
}

export default Support;
