import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import GasForecast from "../../views/data/data-forecasting/GasForecast";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/data/data-forecasting/gas-forecast"
      render={internalProps => (
        <GasForecast {...internalProps} {...props} />
      )}
    />
    
    <Route render={props => <Redirect to={`/portal/data/data-forecasting/gas-forecast`} />} />
  </Switch>
);

export default Routes;
