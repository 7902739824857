import React from "react";
import Card from "../../components/generics/Card";

class Base extends React.Component {
  render(){
    return null;
  }
}

export default Base;
