import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import CardButton from "../../../components/interactive/CardButton";
import Map from "../../../components/interactive/Map";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";

class RegionalInsights extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      syncModal: false,
      filter: "all"
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const plotData = await this.props.api.get(
            `/customers/regions${disableCache ? "?resetCache=true" : ""}`
          );
          this.setState({
            plotData: plotData.data.data,
            staticPlotData: plotData.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button
          className="ui-btn sync-button"
          onClick={() => this.setState({ syncModal: true })}
        >
          <i className="fad fa-sync" />
        </button>
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live customers?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Total Plots"
                  figure={this.state.plotData
                    .reduce((a, x) => (a += x.total), 0)
                    .toLocaleString()}
                />
                <Stat
                  label="Total Occupied"
                  figure={this.state.plotData
                    .reduce((a, x) => (a += x.occupied), 0)
                    .toLocaleString()}
                />
                <Stat
                  label="Total Unoccupied"
                  figure={this.state.plotData
                    .reduce((a, x) => (a += x.notOccupied), 0)
                    .toLocaleString()}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="margin-bottom-0">
          <select
            style={{ maxWidth: "300px" }}
            className="ui-select"
            value={this.state.filter}
            onChange={e => this.setState({ filter: e.currentTarget.value })}
          >
            <option value="all">All</option>
            <option value="not-occupied">Not Occupied</option>
          </select>
        </div>
        <Card style={{ height: 600, overflow: "hidden" }}>
          {!this.state.loading ? (
            <div>
              <Map
                user={this.props.user}
                markers={this.state.filter === "all" ? this.state.plotData.map(x => ({
                  lat: x.latitude,
                  lng: x.longitude,
                  weight: x.total
                })) : this.state.plotData.filter(x => x.notOccupied).map(x => ({
                  lat: x.latitude,
                  lng: x.longitude,
                  infoHeading: x.outcode,
                  infoBody: `${x.notOccupied} ${x.notOccupied > 1 ? "Properties" : "Property"}`
                }))}
                showMarkers={this.state.filter !== "all"}
                onMarkerClick={() => {}}
              />
            </div>
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default RegionalInsights;
