import React from "react";
import CardButton from "../../../components/interactive/CardButton";
import Loader from "../../../components/generics/Loader";
import Card from "../../../components/generics/Card";
import moment from "moment";
import CustomerCommsActionsModal from "../../../modals/CustomerCommsActions";
import CreditControlCommsModal from "../../../modals/CreditControlComms";
import Table from "../../../components/analytical/Table";
import { Helmet } from "react-helmet";

class CustomerCommunication extends React.Component {
  constructor() {
    super();
    this.state = {
      account: null,
      mailHistory: null,
      loading: true,
      commsModal: false,
      fundsModal: false,
      inActiveModal: false,
      ccaModal: false
    };
  }
  componentDidMount() {
    this.getAccount();
  }
  componentDidUpdate(oldProps) {
    if (
      this.props.accountNumber !==
      oldProps.accountNumber
    ) {
      this.getAccount();
    }
  }
  async getAccount() {
    this.setState(
      {
        loading: true
      },
      async () => {
        const account = await this.props.api.get(
          `customers/${this.props.accountNumber}`
        );
        this.setState(
          {
            account: account.data.data,
            isActive: account.data.data.EndDate ? moment(account.data.data.EndDate).isAfter(moment()) ? true : false : true,
            inActiveModal: account.data.data.EndDate ? moment(account.data.data.EndDate).isAfter(moment()) ? false : true : false,
            loading: false
          },
          () => {
            this.getMailHistory();
          }
        );
      }
    );
  }
  async getMailHistory() {
    this.setState(
      {
        mailHistory: null
      },
      async () => {
        const mailHistory = await this.props.api.get(
          `customers/${this.props.accountNumber}/${this.state.account.ContactEmail}/history`
        );
        this.setState({
          mailHistory: mailHistory.data.data
        });
      }
    );
  }
  render() {
    return !this.state.loading ? (
      <div className="col-1 gap-40 margin-bottom-40">
        <Helmet>
          <title>({this.props.accountNumber}) Communication - Green Studio</title>
        </Helmet>
        {this.state.commsModal ? (
          <CustomerCommsActionsModal
            email={this.state.account.ContactEmail}
            phone={this.state.account.ContactPhone}
            accountNumber={this.props.accountNumber}
            api={this.props.api}
            onExit={() => {
              this.setState({ commsModal: false });
            }}
          />
        ) : null}
        {this.state.ccaModal ? (
          <CreditControlCommsModal
            api={this.props.api}
            accountNumber={this.props.accountNumber}
            onExit={() => {
              this.setState({ ccaModal: false });
            }}
          />
        ) : null}
        <div className="col-1 gap-10">
          <p className="ui-label">Quick actions</p>
          <div className="col-2 gap-30">

            <CardButton
              title="Communication Actions"
              colour="green"
              subTitle="Send login links or Trustpilot"
              icon="envelope-open-text"
              onClick={() => this.setState({ commsModal: true })}
            />
            {
              this.props.user.paymentsAccess ?
                <CardButton
                  title="Credit Control Actions"
                  colour="olive"
                  subTitle="Send SMS texts"
                  icon="coins"
                  onClick={() => this.setState({ ccaModal: true })}
                /> : null}

          </div></div>

        <div className="col-1 gap-40">
          <div className="col-1 gap-10">
            <p className="ui-label">Email communication log</p>
            <Card style={{ height: 500 }} className="padding-20">
              {this.state.mailHistory ? (
                <Table
                  data={this.state.mailHistory.map(x => ({
                    Status:
                      x.clicks > 0
                        ? "Clicked"
                        : x.opens > 0
                          ? "Opened"
                          : `No Action, current status: ${x.status}`,
                    "Last Action": `${moment(
                      x.lastUpdated
                    ).fromNow()} (${moment(x.lastUpdated).format(
                      "DD/MM/YYYY HH:MM"
                    )})`
                  }))}
                />
              ) : (
                  <div className="grid-center-center">
                    <Loader />
                  </div>
                )}
            </Card>
          </div>
        </div>
      </div>
    ) : (
        <div className="grid-center-center">
          <Loader />
        </div>
      );
  }
}

export default CustomerCommunication;
