import React from "react";

import Routes from "../../routes/Field";
import SidebarLayout from "../../layouts/Sidebar";

class Data extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="field"
        navigationOptions={[
          {
            icon: "location",
            text: "Location Overview",
            link: "/portal/field/location-overview"
          },
          {
            icon: "credit-card",
            text: "Terminal Payments",
            link: "/portal/field/terminal-payments"
          },
          {
            icon: "file-signature",
            text: "Form Data",
            link: "/portal/field/form-data"
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Data;
