import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Table from "../../../components/analytical/Table";
import Loader from "../../../components/generics/Loader";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";

const payableStatuses = ["Registered", "Lost", "Loss Objection Confirmed", "Loss Received", "Loss Objection Sent", "Loss Objection Upheld"];

class ReconReports extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      startDate: moment().subtract(1, "month"),
      endDate: moment().subtract(1, "day"),
      confirmModal: false,
      downloadModal: false,
      loading: false
    };
  }
  componentDidMount() {
    this.props.setTitle("Reconciliation Reports");
  }
  async getData() {
    this.setState({
      loading: true
    }, async () => {
      const res = await this.props.api.get(`/partners/${this.props.match.params.id}/recon?start=${this.state.startDate.format("YYYY-MM-DD")}&end=${this.state.endDate.format("YYYY-MM-DD")}`);
      this.setState({
        data: res.data.data,
        totals: {
          gasSales: res.data.data.gas.reduce((a, x) => a += Number(x.count_gas_regi_status), 0),
          elecSales: res.data.data.elec.reduce((a, x) => a += Number(x.count_elec_regi_status), 0),
          payable: res.data.data.gas.filter(x => payableStatuses.includes(x.gas_regi_status)).reduce((a, x) => a += Number(x.count_gas_regi_status), 0) + res.data.data.elec.filter(x => payableStatuses.includes(x.elec_regi_status)).reduce((a, x) => a += Number(x.count_elec_regi_status), 0)
        },
        loading: false
      });
    });
  }
  render() {
    return !this.state.loading ? <div className="col-1 gap-40 margin-bottom-40">
      <div className="col-1 gap-10">
        <p className="ui-label">Report filters</p>
        <Card>
          <div
            className="padding-20 col-3 gap-20"
            style={{
              alignItems: "end",
              gridTemplateColumns: "auto auto max-content"
            }}
          >
            <div>
              <p className="ui-label margin-bottom-10">Start date</p>
              <input
                className="ui-input"
                defaultValue={this.state.startDate.format("YYYY-MM-DD")}
                onChange={e =>
                  this.setState({ startDate: moment(e.currentTarget.value) })
                }
                type="date"
                placeholder="Start date"
              />
            </div>
            <div>
              <p className="ui-label margin-bottom-10">End date</p>
              <input
                className="ui-input"
                defaultValue={this.state.endDate.format("YYYY-MM-DD")}
                onChange={e =>
                  this.setState({ endDate: moment(e.currentTarget.value) })
                }
                type="date"
                placeholder="End date"
              />
            </div>
            <div>
              <button
                className="ui-btn-compact"
                onClick={() => this.getData()}
              >
                <i className="far fa-search margin-right-10" />
                  Preview
                </button>
            </div>
          </div>
        </Card>
      </div>
      {
        this.state.data ? <>
          <div className="col-1 gap-10">
            <p className="ui-label">Overall statistics</p>
            <Card style={{ minHeight: 130 }}>
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Total Electric Updates"
                  figureColour="blue"
                  figure={this.state.totals.elecSales.toLocaleString()}
                />
                <Stat
                  label="Total Gas Updates"
                  figureColour="blue"
                  figure={this.state.totals.gasSales.toLocaleString()}
                />
                <Stat
                  label="Total Payable"
                  figureColour="blue"
                  figure={this.state.totals.payable.toLocaleString()}
                />
              </div>
            </Card>
          </div>
          <div className="col-3 gap-10">
            <div className="col-1 gap-10">
              <p className="ui-label">Electric Breakdown</p>
              <Card style={{ height: 375 }} className="padding-10">
                <Table data={this.state.data.elec.sort((a, b) => b.count_elec_regi_status - a.count_elec_regi_status).map(x => ({
                  "Regi Status": x.elec_regi_status,
                  "Count": x.count_elec_regi_status
                }))} />
              </Card>
            </div>
            <div className="col-1 gap-10">
              <p className="ui-label">Gas Breakdown</p>
              <Card style={{ height: 375 }} className="padding-10">
                <Table data={this.state.data.gas.sort((a, b) => b.count_gas_regi_status - a.count_gas_regi_status).map(x => ({
                  "Regi Status": x.gas_regi_status,
                  "Count": x.count_gas_regi_status
                }))} />
              </Card>
            </div>
            <div className="col-1 gap-10">
              <p className="ui-label">GCLID Breakdown</p>
              <Card style={{ height: 375 }} className="padding-10">
                <Table data={this.state.data.gclid.sort((a, b) => b.count_gas_regi_status - a.count_gas_regi_status).map(x => ({
                  "ID": x.gclid,
                  "Count": x.count_gclid
                }))} />
              </Card>
            </div>
          </div>
          <div className="col-3">
            <div>
              <button
                className="ui-btn ui-btn-primary"
                onClick={() => this.getData()}
              >
                  Generate Report
                  <i className="far fa-arrow-right margin-left-10" />
                </button>
            </div>
          </div>
        </> : null
      }
    </div> : <div className="grid-center-center">
        <Loader />
      </div>;
  }
}

export default ReconReports;
