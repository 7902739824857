import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Base from "../views/knowledge/Base";
import ArticleManagement from "../views/knowledge/ArticleManagement";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/knowledge/base"
      render={internalProps => (
        <Base {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/knowledge/article-management"
      render={internalProps => (
        <ArticleManagement {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/knowledge/base" />} />
  </Switch>
);

export default Routes;
