import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Map from "../../components/interactive/Map";
import GenericConfirm from "../../modals/GenericConfirm";
import abbreviate from "number-abbreviate";
import moment from "moment";
import downloadFile from "../../components/helpers/downloadFile";
import GenericMessage from "../../modals/GenericMessage";
import { json2csv } from 'json-2-csv';
import Axios from "axios";

export default class extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      syncModal: false,
      filter: "all",
      pinModal: false,
      pinModalData: null,
      postcode: "NE4 5TF",
      range: "5",
      staticRange: "5",
      sentData: {}
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          let res;
          if(this.state.postcode){
            res = await this.props.api.get(
              `/customers/regions/field-data/${this.state.postcode}?radius=${this.state.range}${disableCache ? "&resetCache=true" : ""}`
            );
          } else {
            res = await this.props.api.get(
              `/customers/regions/field-data${disableCache ? "?resetCache=true" : ""}`
            );
          }
          this.setState({
            data: this.state.postcode ? res.data.data.data : res.data.data,
            staticRange: this.state.range,
            sentData: this.state.postcode ? res.data.data.sent : {},
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  downloadScope(){
    json2csv(this.state.data.filter(x => this.state.filter === "all" ? true : this.state.filter === "debt" ? x.type === "D" : x.type === "NO").map(x => x.customer), (err,csv) => {
      downloadFile(
        `field_data_${this.state.filter}_${moment().format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button
          className="ui-btn sync-button"
          onClick={() => this.setState({ syncModal: true })}
        >
          <i className="fad fa-sync" />
        </button>
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live customers?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        {this.state.pinModal ? (
          <GenericConfirm
            title={`${this.state.pinModalData.type === "D" ? "Debt Account" : "Non Occupier Account"} (${this.state.pinModalData.customer.AccountNumber})`}
            subTitle="Customer information."
            cancelText="Close"
            body={
              <>
                <b>Account Name:</b> {this.state.pinModalData.customer.CustomerName}<br />
                <b>Account Address:</b> {this.state.pinModalData.customer.ContactAddress}<br />
                <b>Account Balance:</b> £{this.state.pinModalData.customer.SalesLedgerBalance}
              </>
            }
            confirmText={"Open Profile"}
            onExit={c => {
              this.setState({
                pinModal: false
              }, () => {
                if(c){
                  window.open(`https://studio.green.energy/portal/support/customers/${this.state.pinModalData.customer.AccountNumber}/overview`)
                }
              })
            }}
          />
        ) : null}
        <div className="col-1 gap-10">
            <p className="ui-label">Report filters</p>
            <Card>

              <div
                className="padding-20 col-4 gap-20"
                style={{
                  alignItems: "end",
                  gridTemplateColumns: "auto auto max-content"
                }}
              >
                <div>
                  <p className="ui-label margin-bottom-10">Postcode</p>
                  <input
                    className="ui-input"
                    defaultValue={this.state.postcode}
                    onChange={e =>
                      this.setState({ postcode: e.currentTarget.value })
                    }
                    type="text"
                    placeholder="Postcode"
                  />
                </div>
                <div>
                  <p className="ui-label margin-bottom-10">Range</p>
                  <select
                    className="ui-select"
                    value={this.state.range}
                    onChange={e =>
                      this.setState({ range: e.currentTarget.value })
                    }
                  >
                    <option value="5">5 miles</option>
                    <option value="15">15 miles</option>
                    <option value="30">30 miles</option>
                    <option value="50">50 miles</option>
                    <option value="75">75 miles</option>
                    <option value="100">100 miles</option>
                  </select>
                </div>
                <div>
                  <button
                    className="ui-btn-compact"
                    onClick={() => this.getData()}
                  >
                    <i className="far fa-search margin-right-10" />
                  Search
                </button>
                </div>
              </div>
            </Card>
          </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Non Occupied Accounts"
                  figure={this.state.data.filter(x => x.type === "NO").length}
                />
                <Stat
                  label="Debt Accounts"
                  figure={this.state.data.filter(x => x.type === "D").length}
                />
                <Stat
                  label="Debt Amount"
                  figure={`£${abbreviate(Math.abs(this.state.data
                    .reduce((a, x) => (a += x.customer.SalesLedgerBalance), 0)
                    .toFixed(2), 2))}`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="margin-bottom-0">
          <select
            style={{ maxWidth: "300px" }}
            className="ui-select"
            value={this.state.filter}
            onChange={e => this.setState({ filter: e.currentTarget.value })}
          >
            <option value="all">All</option>
            <option value="not-occupied">Not Occupied</option>
            <option value="debt">Debt</option>
          </select>
          <button
                    className="ui-btn-compact margin-left-10"
                    onClick={() => this.downloadScope()}
                  >
                    <i className="fad fa-download margin-right-10" />
                  Download
                </button>
        </div>
        <Card style={{ height: 600, overflow: "hidden" }}>
          {!this.state.loading ? (
            <div>
              <Map
                user={this.props.user}
                markers={this.state.data.filter(x => this.state.filter === "all" ? true : this.state.filter === "debt" ? x.type === "D" : x.type === "NO").map((x,i) => ({
                  i: this.state.data.findIndex(y => y.customer.AccountNumber === x.customer.AccountNumber),
                  onClick: i => {
                    this.setState({
                      pinModal: true,
                      pinModalData: this.state.data[i]
                    })
                  },
                  lat: x.plot.latitude,
                  lng: x.plot.longitude,
                  infoHeading: `${x.type === "D" ? "Debt Account" : "Non Occupier Account"} (${x.customer.AccountNumber})`,
                  infoBody: `£${x.customer.SalesLedgerBalance.toFixed(2)}`,
                  colour: x.type === "D" ? "#FF0000" : "#0000FF",
                  scale: x.customer.SalesLedgerBalance > -250 ? 0.5 : x.customer.SalesLedgerBalance > -500 ? 0.75 : x.customer.SalesLedgerBalance > -750 ? 1 : x.customer.SalesLedgerBalance > -1000 ? 1.25 : 0.5
                }))}
                geofence={this.state.postcode ? true : false}
                geofenceDetails={{
                  lat: this.state.sentData.latitude,
                  lng: this.state.sentData.longitude,
                  range: this.state.staticRange
                }}
                showMarkers={true}
              />
            </div>
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
      </div>
    );
  }
}