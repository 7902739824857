import React from "react";
import Card from "../../../components/generics/Card";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import moment from 'moment';

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class SmartInterest extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      interested: 0,
      notInterested: 0,
      noResponse: 0
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    const si = await this.props.api.get("/customers/smart-interests");
    const customers = await this.props.api.get("/customers/count");
    this.setState({
      interested: si.data.data.interested,
      notInterested: si.data.data.notInterested,
      noResponse: customers.data.data - si.data.data.notInterested - si.data.data.interested,
      loading: false
    });
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Interested"
                  figure={`${this.state.interested.toLocaleString()}`}
                />
                <Stat
                  label="Not Interested"
                  figure={`${this.state.notInterested.toLocaleString()}`}
                />
                <Stat
                  label="No Response"
                  figure={`${this.state.noResponse.toLocaleString()}`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default SmartInterest;
