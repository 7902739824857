import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import moment from "moment";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class ReferralTrends extends React.Component {
  constructor() {
    super();
    this.state = {
      referrals: null,
      startDate: moment().startOf("day"),
      endDate: moment().endOf("day"),
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  getConversionRate(q, s) {
    if(s > q) return "100.00%";
    return (((q - (q - s)) / q) * 100).toFixed(2) + "%";
  }
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const referrals = await this.props.api.get(
            `/analytics/sales/referrals?start=${this.state.startDate.format(
              "YYYY-MM-DD"
            )}&end=${this.state.endDate.format("YYYY-MM-DD")}`
          );
          console.log(referrals.data.data)
          this.setState({
            referrals: referrals.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({error:true,loading:false});
        }
      }
    );
  }
  render() {
    return (
     this.state.error ?  <Card message="An error occurred" style={{height:"100%",borderRadius: "12px 12px 0 0"}} messageIcon="bug"/> : <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Start date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ startDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="Start date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">End date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ endDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="End date"
                />
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Total Conversions"
                  figure={this.state.referrals.reduce((a,b) => a += b.sales,0).toLocaleString()}
                />
                <Stat
                  label="Lead Conv. Rate"
                  figure={this.getConversionRate(this.state.referrals.reduce((a,b) => a += b.total,0), this.state.referrals.reduce((a,b) => a += b.sales,0))}
                />
                <Stat
                  label="Meaningful Lead Conv. Rate"
                  figure={this.getConversionRate(this.state.referrals.reduce((a,b) => a += b.quotes,0), this.state.referrals.reduce((a,b) => a += b.sales,0))}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Referral breakdown</p>
          <div className="col-1 gap-40">
            
            <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart
                    data={this.state.referrals.filter(x => x.sales).sort((a, b) => b.sales - a.sales)
                      .map(x => ({
                        name: x.referrer,
                        Conversions: x.sales,
                        Quotes: x.quotes,
                        Visits: x.total
                      }))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={20}
                      type="number"
                      domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Conversions"
                      fill={colors[0]}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Quotes"
                      fill={colors[1]}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="Visits"
                      fill={colors[2]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
              </Card>
              <Card style={{ height: 400 }} className="padding-20">
            {!this.state.loading ? <Table data={this.state.referrals.sort((a,b) => b.sales-a.sales).map(x => ({
                "Referrer": x.referrer,
                "Visits": x.total,
                "Quotes": x.quotes,
                "Conversions": x.sales,
                "Lead Conv. Rate": x.sales ? this.getConversionRate(x.total,x.sales) : "N/A",
                "Meaningful Lead Conv. Rate": x.sales ? this.getConversionRate(x.quotes,x.sales) : "N/A"
              }))}/>: (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>

        </div>
    );
  }
}

export default ReferralTrends;
