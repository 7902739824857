import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import downloadFile from "../../../components/helpers/downloadFile";
import moment from "moment";
import { json2csv } from "json-2-csv";
import {
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class HistoricalGrowth extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const customers = await this.props.api.get(
            `/customers/historical${disableCache ? "?resetCache=true" : ""}`
          );
          const thisWeek = customers.data.data[Object.keys(customers.data.data)[Object.keys(customers.data.data).length - 1]].total;
          const lastWeek = customers.data.data[Object.keys(customers.data.data)[Object.keys(customers.data.data).length - 8]].total;

          console.log(`${thisWeek} - ${lastWeek}`)

          const thisMonth = customers.data.data[Object.keys(customers.data.data)[Object.keys(customers.data.data).length - 1]].total;
          const lastMonth = customers.data.data[Object.keys(customers.data.data)[Object.keys(customers.data.data).length - 29]].total;

          this.setState({
            figures: {
              total: customers.data.data[Object.keys(customers.data.data).reverse()[0]].total.toLocaleString(),
              weekGrowthRate: (thisWeek - lastWeek) / thisWeek * 100,
              monthGrowthRate: (thisMonth - lastMonth) / thisMonth * 100
            },
            data: customers.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  render() {
    return this.state.error ? (
      <Card
        message="Contact support to reload"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="sync"
      />
    ) : (
        <div className="col-1 gap-40 margin-bottom-40">
          <button
            className="ui-btn sync-button"
            onClick={() => {
              let flatData = [];
              Object.keys(this.state.data).forEach(x => {
                const row = {
                  date: x
                };
                Object.keys(this.state.data[x].channels).forEach(y => {
                  row[y] = this.state.data[x].channels[y] ? this.state.data[x].channels[y] : 0;
                });
                flatData.push(row);
              });
              json2csv(flatData, (err, csv) => {
                downloadFile(
                  `historic_growth_${moment().format(
                    "YYYYMMDD"
                  )}.csv`,
                  "text/csv",
                  csv
                );
              });
            }}
          >
            <i className="fad fa-download" />
          </button>
          <div className="col-1 gap-10">
            <p className="ui-label">Overall statistics</p>
            <Card style={{ minHeight: 130 }}>
              {!this.state.loading ? (
                <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                  <Stat
                    label="Total Customers"
                    figure={this.state.figures.total}
                  />
                  <Stat
                    label="7 Day Growth Rate"
                    figure={this.state.figures.weekGrowthRate.toFixed(2) + "%"}
                  />
                  <Stat
                    label="28 Day Growth Rate"
                    figure={this.state.figures.monthGrowthRate.toFixed(2) + "%"}
                  />
                </div>
              ) : (
                  <div className="grid-center-center">
                    <Loader />
                  </div>
                )}
            </Card>
          </div>
          <Card style={{ height: 600 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <LineChart
                    data={Object.keys(this.state.data).map(x => ({
                      date: moment(x).format("DD/MM/YY"),
                      Total: this.state.data[x].total,
                      ...this.state.data[x].channels
                    }))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="date" />
                    <YAxis
                      width={40}
                      type="number"
                    // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey={"Total"}
                      stroke={colors[0]}
                      strokeWidth={3}
                      dot={false}
                    />
                    {
                      Object.keys(this.state.data[Object.keys(this.state.data).reverse()[0]].channels).map(x => <Line
                        isAnimationActive={false}
                        dataKey={x}
                        stroke={colors[2]}
                        dot={false}
                      />)
                    }
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
      );
  }
}

export default HistoricalGrowth;
