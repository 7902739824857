import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";

class GenericConfirm extends React.Component {
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  }
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={() => this.handleExit}
        >
          <Card style={{ minWidth: this.props.minWidth ? this.props.minWidth : 400 }}>
            <div className="padding-30 gap-20 col-1">
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">{this.props.title}</h1>
                <h2 className="ui-title-five ui-title-sub">
                  {this.props.subTitle}
                </h2>
              </div>
              <p className="ui-body">{this.props.body}</p>
              <div>
                <button
                type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit(false)}
                >
                  {this.props.cancelText ? this.props.cancelText : "Cancel"}
                </button>
                <button className="ui-btn-primary margin-top-20" onClick={() => this.props.onExit(true)}>
                {this.props.confirmText ? this.props.confirmText : "Confirm"}
                </button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default GenericConfirm;
