import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";

class CreatePartner extends React.Component {
  constructor() {
    super();
    this.state = {
      name: "",
      salesChannelCode: ""
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  savePartner = async e => {
    e.preventDefault();
    await this.props.api.post("partners", this.state);
    this.props.onExit(true);
  };
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }}>
            <form className="padding-30 gap-20 col-1" onSubmit={this.savePartner}>
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">
                  Create a partner
                </h1>
                <h2 className="ui-title-five ui-title-sub">
                Add a new partner to Green Studio.
                </h2>
              </div>
              <div className="col-1 gap-10">
              <p className="ui-label">Name</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  defaultValue={this.state.name}
                  onChange={e => this.setState({ name: e.currentTarget.value })}
                />
                <p className="ui-label">Sales Reference</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  value={this.state.salesChannelCode}
                  maxLength={12}
                  onChange={e => this.setState({ salesChannelCode: e.currentTarget.value.toUpperCase().replace(/ /g, "")})}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary margin-top-20">
                  Create
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default CreatePartner;
