import React from "react";
import { Redirect } from "react-router-dom";

import Routes from "../../routes/Portal";
import Loader from "../../components/generics/Loader";
import Axios from "../../../Axios";

class Portal extends React.Component {
  constructor() {
    super();
    this.state = {
      verified: null,
      loading: true,
      user: null
    };
  }
  componentDidMount() {
    this.verifyToken();
  }
  get api() {
    return Axios(localStorage.apollo_key);
  }
  verifyToken = async () => {
    Axios(localStorage.apollo_key)
      .get("auth/me")
      .then(res =>
        this.setState(
          {
            loading: false,
            verified: true,
            user: {
              ...res.data,
              informalGreeting: res.data.name.split(" ")[0]
            }
          },
          () =>
            document.documentElement.setAttribute(
              "data-theme",
              this.state.user.theme
            )
        )
      )
      .catch(() => {
        localStorage.removeItem("apollo_key");
        this.setState({
          loading: false,
          verified: false,
          user: null
        });
      });
  };
  render() {
    return this.state.loading ? (
      <div className="grid-center-center">
        <Loader />
      </div>
    ) : this.state.verified ? (
      <Routes
        api={this.api}
        user={this.state.user}
        userReload={this.verifyToken}
      />
    ) : (
      <Redirect to="/sign-in" />
    );
  }
}

export default Portal;
