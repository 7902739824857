import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import CustomerProfile from "../views/support/profile";
import Customers from "../views/support/Customers";

const Routes = props => (
  <Switch>
    <Route
    exact
      path="/portal/support/customers"
      render={internalProps => (
        <Customers {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/support/customers/:accountNumber"
      render={internalProps => (
        <CustomerProfile {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/support/customers" />} />
  </Switch>
);

export default Routes;
