import React from "react";
import moment from "moment";
import CardButton from "../../../components/interactive/CardButton";
import SmartInstallModal from "../../../modals/SmartInstall";
import { Helmet } from "react-helmet";
import Loader from "../../../components/generics/Loader";
import Card from "../../../components/generics/Card";
import GenericConfirm from "../../../modals/GenericConfirm";
import Table from "../../../components/analytical/Table";
import GenericMessage from "../../../modals/GenericMessage";

class SmartBooking extends React.Component {
    constructor() {
        super();
        this.state = {
            installModal: false,
            cancelModal: false,
            errorModal: false,
            errorMessage: null,
            cancelRef: null,
            smartRegistered: false,
            appointments: null
        }
    }
    componentDidMount() {
        if(!this.props.user.smartAccess){
            this.props.history.goBack();
        } else {
            this.getInterest();
            this.getAppointments();
        }
    }
    async getInterest() {
        const sr = await this.props.api.get(`/customers/${this.props.accountNumber}/smart`);
        this.setState({ smartRegistered: sr.data.data });
    }
    async getAppointments() {
        const bb = await this.props.api.get(`/customers/${this.props.accountNumber}/beboc/bookings`);
        this.setState({ appointments: bb.data.data });
    }
    async cancelAppointment(){
        try {
            await this.props.api.post(`/customers/${this.props.accountNumber}/beboc/cancel-booking`, {ref: this.state.cancelRef});
            this.setState({ cancelRef: null, appointments: null }, () => this.getAppointments());
        } catch (e){
            this.setState({ cancelRef: null, errorModal: true, errorMessage: "Cancellation error, please raise with smart team." });
        }
    }
    render() {
        return <div className="col-1 gap-40 margin-bottom-40">
            <Helmet>
                <title>({this.props.accountNumber}) Smart Booking - Green Studio</title>
            </Helmet>
            {this.state.installModal ? (
                <SmartInstallModal
                    accountNumber={this.props.accountNumber}
                    api={this.props.api}
                    onExit={() => {
                        this.setState({ installModal: false });
                    }}
                />
            ) : null}
            {this.state.cancelModal ? (
                <GenericConfirm
                    title={`Cancel this appointment?`}
                    subTitle={`Ref. ${this.state.cancelRef}`}
                    body={
                        <>
                            The customer will be notified via email shortly.
              </>
                    }
                    onExit={confirm => {
                        this.setState({ cancelModal: false }, () => {
                            if (confirm) this.cancelAppointment();
                        });
                    }}
                />
            ) : null}
            {this.state.errorModal ? (
                <GenericMessage
                    title={`An error occurred.`}
                    subTitle={`Please see below.`}
                    body={
                        <>
                            {this.state.errorMessage}
              </>
                    }
                    onExit={() => {
                        this.setState({ errorModal: false });
                    }}
                />
            ) : null}
            <div className="col-1 gap-10">
                <p className="ui-label">Quick actions</p>

                <div className="col-2 gap-30">

                    <CardButton
                        title={this.state.smartRegistered ? "Smart Registered" : this.state.smartRegistered === false ? "Smart Unregistered" : "Register for smart?"}
                        colour={this.state.smartRegistered ? "green" : "grey"}
                        subTitle="For a smart meter install"
                        icon="house-signal"
                        onClick={async () => {
                            await this.props.api.post(`/customers/${this.props.accountNumber}/smart`, { option: !this.state.smartRegistered });
                            this.setState({ smartRegistered: !this.state.smartRegistered });
                        }}
                    />

                    <CardButton
                        title="Book Smart Install"
                        colour="blue"
                        subTitle="Arrange an appointment with BES"
                        icon="calendar-day"
                        onClick={() => this.setState({ installModal: true })}
                    />

                </div>
            </div>
            <div className="col-1 gap-10">
                <p className="ui-label">BES Appointments</p>
                <Card style={{ height: 500 }} className="padding-20">
                    {console.log(this.state.appointments)}
                    {this.state.appointments ? (
                        <Table
                            data={this.state.appointments.map(x => ({
                                Reference: x.bookingReference,
                                Date: `${x.date} ${x.timeSlot.start} - ${x.timeSlot.end}`,
                                "Fuel Type": x.fuelType,
                                "Job Type": x.jobType,
                                Status: x.status,
                                "Engineer": x.engineer ? x.engineer.name : "N/A",
                                actions: [{
                                    icon: "times",
                                    colour: "orange",
                                    onClick: () => this.setState({
                                        cancelModal: true,
                                        cancelRef: x.bookingReference
                                    })
                                }]
                            }))}
                        />
                    ) : (
                            <div className="grid-center-center">
                                <Loader />
                            </div>
                        )}
                </Card>
            </div>
        </div>
    }
}

export default SmartBooking;
