import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Configuration from "../../views/partners/profile/Configuration";
import SalesOverview from "../../views/partners/profile/SalesOverview";
import ReconReports from "../../views/partners/profile/ReconReports";

const Routes = props => (
    <Switch>
        <Route
            path="/portal/partners/partner-management/:id/configuration"
            render={internalProps => (
                <Configuration {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/partners/partner-management/:id/sales-overview"
            render={internalProps => (
                <SalesOverview {...internalProps} {...props} />
            )}
        />
        <Route
            path="/portal/partners/partner-management/:id/reconciliation-reports"
            render={internalProps => (
                <ReconReports {...internalProps} {...props} />
            )}
        />
        <Route render={props => {
            return <Redirect to={`/portal/partners/partner-management/${props.match.params.id}/configuration`} />
        }} />
    </Switch>
);

export default Routes;
