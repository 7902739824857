import React from "react";
import moment from "moment";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";
import GenericMessage from "./GenericMessage";

class BalanceTransfer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromAccount: "",
      toAccount: "",
      amount: 0,
      amountIsCredit: true,
      date: moment(),
      loading: false,
      success: null,
      id: `BALANCE_TRANSFER_${Math.random().toString(36).substr(2, 34).toUpperCase()}`
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  transfer = async e => {
    e.preventDefault();
    this.setState({
      loading: true
    }, async () => {
      try {
        //from
        await this.props.api.post(`/transactions`, {
          amount: this.state.amountIsCredit ? -Math.abs(this.state.amount) : this.state.amount,
          id: `${this.state.id}_FROM`,
          date: this.state.date,
          description: "Balance Transfer",
          account: this.state.fromAccount
        });
        //to
        await this.props.api.post(`/transactions`, {
          amount: this.state.amountIsCredit ? this.state.amount : -Math.abs(this.state.amount),
          id: `${this.state.id}_TO`,
          date: this.state.date,
          description: "Balance Transfer",
          account: this.state.toAccount
        });
        this.setState({
          loading: false,
          success: true
        });
      } catch (e) {
        this.setState({
          loading: false,
          success: false
        });
      }
    });
  };
  render() {
    return (
      this.state.success === true ? <GenericMessage title="Balance transfer complete" subTitle={`On account number ${this.state.fromAccount} to ${this.state.toAccount}.`} body={<>This will reflect on the balance in real time.</>} cancelText="Close" onExit={() => this.props.onExit()} /> : this.state.success === false ? <GenericMessage title="Balance transfer failed" subTitle={`We hit an error when adding this to the sales ledger`} body={<>Please ensure this isn't already in the sales ledger. If the error persists please contact support.</>} cancelText="Close" onExit={() => this.props.onExit()} /> : <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }} loading={this.state.loading}>
            <form className="padding-30 gap-20 col-1" onSubmit={this.transfer}>
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">
                  Balance Transfer
                </h1>
                <h2 className="ui-title-five ui-title-sub">
                  Transfer from an existing account
                </h2>
              </div>
              <div className="col-2 gap-20">
              <div className="col-1 gap-10">
                <p className="ui-label">Account Number (From)</p>
                <input
                  type="text"
                  placeholder="e.g 200004"
                  minLength={6}
                  maxLength={6}
                  required
                  name="account"
                  className="ui-input"
                  defaultValue={this.state.fromAccount}
                  onChange={e => this.setState({ fromAccount: e.currentTarget.value })}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Account Number (To)</p>
                <input
                  type="text"
                  placeholder="e.g 200008"
                  minLength={6}
                  maxLength={6}
                  required
                  name="account"
                  className="ui-input"
                  defaultValue={this.state.toAccount}
                  onChange={e => this.setState({ toAccount: e.currentTarget.value })}
                />
              </div>
              </div>
              <div className="col-2 gap-20">
              <div className="col-1 gap-10">
                <p className="ui-label">Amount (£)</p>
                <input
                  type="number" 
                  min="0.01" 
                  step="0.01" 
                  max="5000"
                  placeholder="e.g £50.00"
                  required
                  className="ui-input"
                  onChange={e => this.setState({ amount: Number(e.currentTarget.value) * 100 < 0 ? 0 : Number(e.currentTarget.value) * 100 > 500000 ? 500000 : Number(e.currentTarget.value) * 100 })}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Amount Type</p>
                <div className="col-2 gap-5">
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.amountIsCredit ? "-secondary" : ""
                    } ui-btn-compact margin-right-5`}
                    onClick={() =>
                      this.setState({ amountIsCredit: true })
                    }
                  >
                    Credit
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      !this.state.amountIsCredit ? "-secondary" : ""
                    } ui-btn-compact`}
                    onClick={() =>
                      this.setState({ amountIsCredit: false })
                    }
                  >
                    Debit
                  </button>
                  </div>
              </div>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Transaction Date</p>
                <input
                  type="date"
                  required
                  className="ui-input"
                  defaultValue={this.state.date.format("YYYY-MM-DD")}
                  max={moment().format("YYYY-MM-DD")}
                  onChange={e => {
                    this.setState({ date: moment(e.currentTarget.value, "YYYY-MM-DD") });
                  }}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">{this.state.type === "GC_DIRECT_DEBIT" ? "GoCardless Payment ID" : this.state.type === "GC_REFUND" ? "GoCardless Refund ID" : this.state.type === "STRIPE_TOP_UP" ? "Stripe Charge ID" : this.state.type === "STRIPE_REFUND" ? "Stripe Refund ID" : "External Sales ID"}</p>
                <input
                  type="text"
                  required
                  disabled={(this.state.type === "GC_DIRECT_DEBIT" || this.state.type === "STRIPE_REFUND" || this.state.type === "STRIPE_TOP_UP" || this.state.type === "GC_REFUND") ? false : true}
                  className="ui-input"
                  value={this.state.id}
                  onChange={e => this.setState({ id: e.currentTarget.value })}
                />
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary margin-top-20">
                  Transfer £{(this.state.amountIsCredit ? (this.state.amount / 100) : -Math.abs(this.state.amount / 100)).toFixed(2)}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default BalanceTransfer;
