import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";

class AccountSettings extends React.Component {
  constructor(props) {
    console.log(props.user);
    super(props);
    this.state = {
      changed: false,
      password: "",
      name: props.user.name,
      theme: props.user.theme,
      invalid: false
    };
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  saveAccount = async e => {
    e.preventDefault();
      try {
        const changes = {
          name: this.state.name,
          theme: this.state.theme
        };
        if(this.state.password) changes.password = this.state.password;
        await this.props.api.patch(`users/${this.props.user.id}`, changes);
        this.props.onExit(true);
      } catch (e) {
        console.log(e);
        this.setState({ invalid: true });
      }
  };
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }}>
            <form
              className="padding-30 gap-20 col-1"
              onSubmit={this.saveAccount}
            >
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">Account settings</h1>
                <h2 className="ui-title-five ui-title-sub">
                  Make changes to your account.
                </h2>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Name</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  defaultValue={this.state.name}
                  onChange={e => this.setState({ name: e.currentTarget.value })}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">New Password</p>
                <input
                  type="password"
                  onChange={e =>
                    this.setState({
                      password: e.currentTarget.value
                    })
                  }
                  className="ui-input"
                  placeholder="Enter a new password..."
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Theme</p>
                <select
                  className="ui-select"
                  defaultValue={this.state.theme}
                  onChange={e => {
                    this.setState({ theme: e.currentTarget.value });
                    document.documentElement.setAttribute(
                      "data-theme",
                      e.currentTarget.value
                    );
                  }}
                >
                  <option value="light">Material Light</option>
                  <option value="dark">Apollo Dark</option>
                  <option value="midnight">Midnight</option>
                </select>
              </div>
              <div className="margin-top-20">
                <button
                type="button"
                  className="ui-btn margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default AccountSettings;
