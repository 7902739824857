import React from "react";

class EnergyRegionMap extends React.Component {
  render() {
    return (
      <svg
        height="100%"
        version="1.1"
        class="energy-region-map"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 579 965"
        style={{enableBackground: "new 0 0 579 965"}}
        xmlSpace="preserve"
      >
        <g id="_x23_d11d1dff">
          <path
            class="region-area-17"
            d="M259.9,1.9c2.8-2.1,6.5-1.8,9.6-0.6c2.7,1.6,6.1,2.4,7.9,5.2c5.2,6.8,8.7,14.7,11.5,22.8
		                    c1.5-2.6,2.7-5.3,4.1-8c0.7,0,2-0.1,2.7-0.2c0.4-0.5,1.3-1.5,1.7-2c17.2,0.7,34.4,2,51.6,2.9c8.3,0.1,16.6-0.1,24.8,0.8
		                    c-0.1,0.6-0.2,1.8-0.3,2.5c-0.5,4.8-1.9,9.5-3,14.2c-1.2,3.8-1.8,7.8-3,11.6c-0.7,3.1-4.2,4.1-6.5,5.8c-3.7,3-7.7,5.5-11.7,8.2
		                    c-4,2.3-7.3,5.9-11.9,7.2c-2.9,4.3-7.8,6.4-11.9,9.2c-10.2,7.2-20.2,14.8-30.8,21.5c5,2.7,10.7,3.6,16.1,5.4
		                    c0.1,1.2,0.1,2.5,0.2,3.7c-2.3,3-5.8,4.6-8.8,6.8c-4.5,0.5-8.9-0.8-13.4-1c-3.8,5.3-8.4,10.1-10.8,16.3c9.1,2.2,18.6,1.8,27.9,1.9
		                    c7.8-3,14.3-8.6,21.5-12.6c2.3-0.6,5.2-0.6,7.2,0.9c3.6,2.5,7.4,4.7,10.6,7.6c3.2,1.1,6.1,3.2,9.6,3.1c7.4,0,14.8-0.2,22.1,1
		                    c14.9,1.8,30.6,4.1,43.4,12.6c3.7,2.3,6.2,6,8.2,9.8c0.6,2.2,0.1,4.5,0.2,6.8c-4.1,5.2-11.1,6-16.6,9c-3,3.4-3.3,8.4-3.5,12.7
		                    c0,3.9-1,7.8-2.4,11.4c-1.3,3.8-4.9,6.2-6.2,10c-2.5,6.1-7.5,10.4-10.8,15.9c-2.7,4.5-6.9,7.8-9.7,12.2
		                    c-11.5,14.2-23.7,27.8-36.1,41.2c-1.7,2-4.3,0.6-6.5,0.6c-5.4-0.4-10.8-1.1-16.2-2c-1.2-0.4-2.5-0.1-3.5,0.7
		                    c-1.1,1.1-2.6,1.7-3.7,2.9l0.1,0.5c-0.7,0.1-2.1,0.2-2.8,0.3c-1,1.1-2.1,2.2-3.1,3.3l-0.2,0.6l-1.3-0.3l0.1,1.2
		                    c-0.6,0-1.9,0.1-2.5,0.1c-2.1,5-3.1,10.6-6.8,14.7c-1.8,1.4-3.8-0.3-5.5-1c-3.6-2.3-8.1-2.6-11.8-4.9c-3.4,2.7-8.3,2.6-11.4,5.8
		                    c-2.5,0.8-5.2,1.4-7.1,3.5c-4.3,1.8-9.1,2-13,4.6c-2.5,1.8-5.7,1.3-8.6,1.3c-2.4-0.8-4.8-1.2-7.2-2.1c-2.6-1.2-5.4-2.1-8.2-2.2
		                    c-2.3-0.5-4.5,0.1-6.4,1.5l0.6-1.6c-5.8,0.1-11.4,1.1-17.2,1.7c-4.5-5.1-7.6-11.9-7.4-18.8c-2-2.1-3.9-4.2-5.9-6.3
		                    c-4,3.5-7.1,7.7-10.7,11.5c-2.9,2.2-5.5,4.7-7.4,7.8c1.6,5.2,0,10.4-1.4,15.4c-1.5,4.1-6.5,4-9.3,6.5c-4.6,13.9-9.1,27.8-14,41.5
		                    c-3.5,1.6-7.2,2.3-10.9,2.8c-1,0.4-2.1,0.8-3.1,1.2c-1,0.1-1.9,0.1-2.9,0.2c-2.2-1.2-4.1-3-5.3-5.2c-0.9-5.8,1.7-11.1,4.1-16.2
		                    c2-5.1,4.8-9.8,7.4-14.7c4.3-8.1,11.2-14.5,17.9-20.5c-1.2-1.7-2.6-3.2-3.5-5.1c-0.2-2.2,0-4.5,0-6.8c1.8-2.2,4-3.9,6.1-5.7
		                    c-0.3-0.3-0.9-0.8-1.2-1c0.7-1,1.4-2,2.1-3c-1.4-0.8-2.9-1.5-4.3-2.1c0.1-1,0.2-2,0.4-3c0.8-1,1.6-1.9,2.4-2.9
		                    c1.7-0.8,3.6-1.4,5-2.8c2.1-11.2,2.7-22.6,4.6-33.9c-5.2-0.2-10.3,0.5-15.3,1.7c-7.1,0.9-14,2.6-21.2,3.1c-0.7-0.3-2.1-0.9-2.7-1.2
		                    c-2.2-2.9-4.3-5.9-6.4-8.8c4.4-2.9,9.7-4,14.4-6.4c6.8-3.3,14.8-4.5,20.8-9.4c-5.1-2.5-11-2.3-16.2-4.6c-3.7-1.4-7.8-1.4-11.3-3.3
		                    c0.4-1.9,0.7-4,2.2-5.3c2.9-1.6,6.7-1.7,9-4.1c1.8-4.1,3.4-8.4,6.9-11.4c8.5,1.1,16.8,3.5,25.4,3.4c-0.2-3.3-3.9-5.1-5.1-8.1
		                    c-3-2.1-5.2-5-7.4-7.9c-0.1-1.1-0.2-2.2-0.3-3.4c4.5-2.9,8.7-6.2,13.1-9.2c4.9,1.6,8.4,6.5,13.8,6.9c5-3.2,10.1-6.5,14.4-10.8
		                    c-4.4-4.1-10.3-6.2-14.7-10.3c0.8-0.4,2.2-1.1,3-1.4c1.4-0.3,2.8-0.6,4.1-0.9c3.3-0.2,6.6-0.1,9.8-0.8c1.1-6.4,0-13.1,0.4-19.6
		                    c0.6-5.7-1.5-11.4-4.6-16.1c-2.5-3.7-2.6-8.5-4.9-12.3c-4-6.5-5-14.2-6.1-21.6c-0.2-2.3,1.6-4.1,2.8-5.8c2.2,0.1,4.8-0.6,6.6,1
		                    c1.7,1.4,2.7,3.5,4,5.2c1.7,1.1,3.8,1.6,5.7,2.5c1-2.7,1.6-5.5,1.9-8.4c0.3-0.5,0.8-1.6,1.1-2.1c0.3-0.7,0.9-2,1.2-2.6
		                    c3.1,0.4,5.7,2,7.7,4.3c1.6-0.7,3.3-1.2,4.8-2c-0.9-4.6-2.2-9.1-3-13.8c0.7-0.8,1.5-1.5,2.2-2.3c2.8,0.1,5.7-0.2,8.4,0.8
		                    c1.1-0.1,2.2-0.3,3.2-0.4c0-4.1,0.1-8.2,1.2-12.1c0.9-2.1,3.6-1.1,5.4-1.5c2.1,1.4,3.7,4.1,6.3,4.5c2.9-2.6,5.6-5.6,8.3-8.4
		                    c-3.4-2.8-7.6-4.5-11-7.4c-1.4-1.6-1.8-3.9-2.4-5.9c3.3-3.1,6.4-6.7,10.9-7.9c1-0.5,2.8-0.6,2.9-2C255,13.6,255.5,6.8,259.9,1.9z"
          ></path>
          <path
            class="region-area-17"
            d="M152,27.3c6.7-4,11.4-11.4,19.6-12.4c3.3,3.8,6.4,8.1,7.2,13.2c-0.5,3.6-1.4,7.2-2.2,10.8
		                    c-3.3,2.9-7.7,4.2-11.2,6.8c-0.5,0.2-1.5,0.6-2,0.7c-0.4,4.7-3.7,8.8-2.8,13.6c-0.2,3.1-0.8,6.5-2.4,9.2c-3.5,2.9-7.5,5.3-11.6,7.3
		                    c-3.1,1.4-6.4-0.6-9.6-0.3c-6,4.2-11.5,9.1-17.7,13.2c-6.3,14.7-10.9,30.1-17.3,44.8c-0.5,2-2.7,2.1-4.2,2.8
		                    c-3.7-2.1-7.1-4.7-10.2-7.6c-0.5-1.7-0.7-3.4-1.1-5.1c0.3-1.6,0.4-3.2,1-4.7c1.2-1.3,2.8-2,4.3-2.8c3.6-4.2,5.5-9.4,8.1-14.2
		                    c0.2-3.1,1.7-6.8-1.1-9.3c-2-1.8-2.8-4.2-2.1-6.9c3.7-0.5,7.4-0.1,11-0.6c2.9-1.6,3.6-5.3,4.7-8.2c1-0.7,2-1.3,3-2
		                    c4.3-0.5,3.7-5.3,4-8.5c-0.6-5-3.8-9.3-5.6-13.9c0.8-4.1,1.5-8.2,3.6-11.8c1.5-1.4,2.9-3,4.7-3.9c4.7-1.1,9.3,1,13.9,0.7
		                    c2.1-3,3.4-6.6,5.9-9.3C144.4,25.7,148.7,28.1,152,27.3z"
          ></path>
          <path
            class="region-area-17"
            d="M147.3,101.6c1-0.2,2.1-0.4,3.1-0.5c3.6,2.4,7,5.4,9.2,9.2c0.3,1.1,0.6,2.2,1,3.3c0.1,0.5,0.2,1.6,0.2,2.1
		                    c0.5,0.4,1.5,1.3,2,1.7c0.3,3.8,0.2,7.6,0.1,11.4c-0.3,0-1,0.1-1.4,0.1c-1,2.8-3.5,4.3-5.4,6.4c-0.2,3.7,1,7.4,3.1,10.5
		                    c3.6,3,9.1,2.4,12.5,5.5c0.4,4.6-1.7,9.1-3.4,13.2c-3,4-8,6.5-13.1,5.2c-1.9-2.3-0.8-5.7-1.9-8.4c-3.9-1-8.6-1-11.6-4.1
		                    c-1.5-1.5-3-3-4.4-4.5c-0.3-1.6-0.7-3.1-1.1-4.7c0.4-2.6,1.5-5.3,0.9-7.9c-4-4.4-12.2-3.9-14.2-10.2c-1-1.9-1-4.1-0.9-6.2
		                    c3.1-3.5,5.7-7.5,6.9-12.1c0.6-0.3,2-1,2.6-1.3c2.5,0,5,0,7.5,0c1.2,1.3,2.6,4.3,4.8,2.7C145.5,109.3,144.3,104.5,147.3,101.6z"
          ></path>
          <path
            class="region-area-17"
            d="M127.2,171.6c2.3-1,5.3-2.5,7.4-0.3c3.2,2.4,1.6,6.7,3.1,9.9c1.2,3,2.9,5.9,3.2,9.2c-2.1,1-4.4,1.2-6.7,1.1
		                    c-0.9-1.3-1.8-2.6-2.7-3.9c-0.3-3.4-1.7-6.4-3.9-9C125.9,176.6,126.7,173.9,127.2,171.6z"
          ></path>
          <path
            class="region-area-17"
            d="M103.2,220.5c4.6-2.6,8.8-7.3,14.7-6.5c0.8,2.1,1.1,4.3,1.1,6.5c-3,3.1-6.3,5.9-9.6,8.8
		                    c-3.2-0.3-4.3-3.7-6.3-5.8C103.1,222.4,103.1,221.5,103.2,220.5z"
          ></path>
          <path
            class="region-area-17"
            d="M86.2,226.3c1.6-0.4,3.1-1.6,4.8-1.1c1.5,0.8,2.7,2.1,4,3.2c-0.2,0.8-0.5,2.2-0.7,3l2.2,0.1
		                    c-1.5,1.6-3.2,5.2-5.4,2.2c-0.3,0.4-0.9,1.2-1.2,1.6C88.2,232.6,86.3,229.7,86.2,226.3z"
          ></path>
          <path
            class="region-area-17"
            d="M115.7,307.8c6.4-5.1,13.1-9.9,20.3-13.9c0.8,0.4,2.3,1.2,3.1,1.6c0.7,6.1-4.2,10.6-8.4,14.3
		                    c-0.5,0.1-1.6,0.1-2.1,0.2c-3.6,3.5-9.7,2.7-12.6,7c0.6,2,2.1,3.8,2.2,6c-0.4,2.1-1.2,4.1-1.8,6.1c-4.1,3.4-8,7.7-13.5,8.6
		                    c-2.8-0.4-6.1-0.2-8.5-2c-0.5-2.9,0.1-5.9-0.2-8.8c-2.1-2.1-5-3.1-7.6-4.5c1.1-2.4,2.3-4.6,3.7-6.8c5.6-2.3,11.2-5.4,17.5-5.4
		                    C110.7,310.2,113.1,308.7,115.7,307.8z"
          ></path>
          <path
            class="region-area-17"
            d="M170.3,335.2c1.7-0.1,3.4-0.1,5-0.1c0.6,0.4,1.7,1.1,2.3,1.5c0.8,2.3,1.4,4.7,2.2,7c2.7,2.7,2.5,6.8,2.9,10.4
		                    c0.3,5.1-6.1,5.3-8.5,8.7c-1.6,0-3.1,0-4.7,0c-0.5-0.4-1.5-1.1-1.9-1.4c-0.6-1-1.3-1.9-1.9-2.9c-0.5-1.1-1-2.2-1.6-3.3
		                    c-0.5-3.5,1-6.7,2.4-9.9C170.7,343.6,169.6,338.9,170.3,335.2z"
          ></path>
        </g>
        <g id="_x23_d1a01dff">
          <path
            class="region-area-18"
            d="M316.9,277.7c4.4,0,8.8,0.1,13.1,1.1c4.1,1,8.2,1.9,12.5,1.5c5.5,3.9,11.9,9.3,10.3,16.9
		                    c-0.4,1.1-0.8,2.3-1.1,3.5c-0.8,1.8-2.2,3.2-4,4c-1.2,0.4-2.4,0.8-3.6,1.2c-1.9-0.8-3.7-1.6-5.5-2.6c-3.6-1.7-6.9-3.7-10.7-4.9
		                    c-1.1,2.1-2.2,4.1-3.6,6.1c-0.7,2.8-3.1,4.5-5.1,6.3c-8.7-0.1-17.4,0.1-26.1,0.1c0.4,3.5,3,5.9,5.1,8.5c20.4-0.4,40.8,0.2,61.1-0.1
		                    c4.7,4.9,6,11.8,8.5,17.9c3.4,7.5,6.1,15.2,8.9,22.9c1.2,3.2,3.3,5.8,5.2,8.5c-0.4,0.3-1.4,0.9-1.8,1.2c0.6,0.2,1.7,0.7,2.3,0.9
		                    c-7.4,6-15.7,10.9-22.9,17c-3.1,5-4.6,10.9-8.2,15.5c-5.3,0.8-10.5,2.8-15.9,3c-14.6,10.1-28.8,20.8-43.7,30.6
		                    c-5.3-0.7-10.8-0.9-15.9-2.5c-2-0.1-3.9-0.1-5.9,0.2c-3.3,2.1-5.8,5.3-9.4,7.1c-2,0.8-3.7-0.7-5.5-1.2c-6.2,2.2-11.1,6.9-17.2,9.1
		                    c-4.7,0.8-9.5,0.5-14.3,0.4c-4.6-2.5-7.6-6.7-10.3-11c-3.2-2.9-5.5-6.5-8.7-9.4c-0.2,1.9-0.3,3.8-0.4,5.8c1.9,3.3,1.8,7.2,2,10.9
		                    c-1.3,0.9-2.6,1.8-3.8,2.8c-2.4,0-4.8,0-7.2,0c-4.8-6.9-10.3-13.2-15.2-20c-2.1,1.2-3.8,3-5.7,4.4c-5.3-1.7-8.9-6.3-13.8-8.9
		                    c-2.1-0.3-1.4-2.5,0.1-2.9c-0.8-0.6-1.5-1.2-2.2-1.8c2.4-0.4,5.1-0.2,7.3-1.4c2.4-5.9-0.3-13.4,4.1-18.5c1.5-2.2,3.2-4.2,5-6.1
		                    c3-5.8,7.6-10.5,10.9-16.1c4.8-6.5,9.4-13,14.8-18.9c0.8-2.5,3.2-4.7,2.7-7.4c-2.8-4.6-5.6-9.2-8.4-13.8c-1.8-2.3-3.3-4.7-4.6-7.3
		                    c4.1-4.3,7.9-8.9,11.4-13.6c4.8-4.2,11.1-6.2,16.4-9.6c1.8-1.4,4.1-2,6.4-1.5c2.9,2,6.4,2.7,9.8,3.2c3.3,1.6,7.1,1.5,10.6,2.3
		                    c3.5-1.3,7-2.5,10.4-4c5.8-2.7,11.7-5.1,17.5-7.6c2.5-1,4.6-3,7.4-3c1.8,0,3.5,0.9,5.1,1.6c4.1,2,8.4,3.4,12.3,5.7
		                    c4-4.6,5.1-11,7.4-16.6c4.6-2.4,8.6-5.6,13.1-8.2L316.9,277.7z"
          ></path>
        </g>
        <g id="_x23_1dd12eff">
          <path
            class="region-area-15"
            d="M381.9,373.9c1.6-1.1,3.7-0.8,5.5-1c2.5,2.2,5.4,4.2,6.3,7.6c0.4,1.1,0.7,2.2,1.1,3.3
		                    c0.7,21.4,2.3,42.8,2.1,64.2c-0.2,4.4,0.8,8.7,0.8,13c0,7,0.1,14,0.5,21c0.4,4-0.5,8,0.4,11.9c6.6,3,14.2,3.3,21.2,5
		                    c3.3,1,6.7,1,9.9,2.4c2.2,0.8,2.1,3.5,3,5.3c0.1,0.5,0.2,1.5,0.2,1.9c4.3,3.2,5.8,8.4,8.2,13c3.6,4,4.8,9.7,9,13.2
		                    c7.5,7,14.4,14.5,21.7,21.6c0,2.3,0,4.6,0,6.9c-5.4,3.2-9.8,7.6-14.8,11.2c-4.9,1.9-10,3.1-15.1,4.1l0-1.7l-0.9,0
		                    c-0.1,0.6-0.2,1.9-0.2,2.6c-4.4,0.9-8.9,1.6-13,3.8c-2.3,1.3-4.9,1.6-7.4,2.2c-7.1,1-13.8,4.5-21,4.8c-2.5-3.2-5-7.2-9.4-7.6
		                    c-9.3-1.7-18.9-0.5-28.4-0.9c-0.7-1.1-1.3-2.2-2-3.3c-2.7-4.2-3.8-9.2-7-13.1c-1.3-3-2.9-5.9-4.6-8.7c-2,0.1-4.1,0.1-6.1,0
		                    c-2.2-0.6-4.5-0.7-6.8-1.1c-2-2.6-2.2-6-3-9.1c-0.4-2.6-1.7-4.9-2.1-7.5c3.4-3,7.4-5.3,11-8c2-1.7,1-4.8,0.9-7
		                    c-1.2-5.6-0.8-11.5-2.5-17.1c-1.7-5.8-0.5-12.1-3.6-17.5c-3.8-2.5-6.6-6.2-10.3-8.9c0-0.6,0.1-1.7,0.2-2.3
		                    c3.3-3.3,9.2-5.7,8.9-11.2c-0.1-4.1,0.4-8.3-0.4-12.3c-0.2-2.4-2.8-2.8-4.6-3.5c-1.1-1.1-2.6-1.9-3.4-3.3c-0.3-1.5,1.3-2.4,1.9-3.6
		                    c0.9-2.8,2.1-5.5,2.6-8.4c-2.8-3.5-6.5-6.2-9.5-9.5c-2-2.4-1.4-5.7-0.2-8.4c4.9-3.1,9.8-6.2,14.4-9.6c5.2-1,10.2-3,15.5-3.9
		                    c2.4-0.2,3.3-2.5,4-4.4c1.5-3.2,3.1-6.5,4.3-9.8C366.5,384.4,374,378.9,381.9,373.9z"
          ></path>
        </g>
        <g id="_x23_b1d11dff">
          <path
            class="region-area-16"
            d="M307.8,430.9c1.1-3.4,3.9-5.7,7.2-6.9c2.4,0.9,3.6,3.2,5.4,4.8c2.9,2.8,6.1,5.2,8.7,8.4
		                    c-1,0.7-2.1,1.4-3.1,2.1c0.4,3.4-0.8,6.4-1.6,9.6c1.2,3.5,5.9,3.8,8.4,6c0,5.1,0,10.2-0.9,15.2c-3.1,3-6.5,5.7-9.4,9
		                    c0.4,2.4,2.7,3.6,4.3,5.1c3.4,2.6,6.4,5.8,8.4,9.6c1.8,4.1,1.6,8.7,2.5,13c1.7,6.7,1.5,13.7,2.4,20.5c-2.2,5.6-9.1,6.4-12.4,11.1
		                    c1.1,2.3,1.6,4.9,1.4,7.4c-3.9,5.1-7.9,10.3-10.5,16.2c-0.5,14,0.7,28-0.5,42c-0.2,7.1,4,13.2,6,19.8c1.3,4.6,4.1,8.9,4.2,13.9
		                    c-5.3,3.2-11.4,4.4-17.3,5.9c-3.2,0.6-6.5,0.2-9.8,0.2c-3.7-5.8-4.8-12.8-8-18.9c-4.5-6.8-12.5-10.9-15.3-18.9
		                    c-4.4-6.1-7.8-12.9-12-19.2c0.4,0,1.3,0,1.7,0c1.9-3.9,4.9-7.1,6.9-10.9c-2.7-2.1-5.6-4.1-8.1-6.5c-1.1-1.9-0.1-4.1,0.9-5.8
		                    c-0.7-0.7-1.4-1.4-2-2.1c0-0.5,0-1.6,0-2.1c0.5-0.3,1.6-1,2.1-1.3c3.3-1.9,7.1-1.9,10.8-1.6c1.2,0.7,2.5,1.2,3.8,1.9
		                    c1.2-0.9,2.4-1.8,3.6-2.7c-0.1-5.4-2.4-11.9,1.7-16.4c2-2.5,3.6-5.3,5.7-7.9c-1.4-1.8-2.5-3.7-4-5.4c-1.2,0.6-2.5,1.3-3.7,2
		                    c-2.4,3.6-5.6,6.7-8.8,9.5c-2.6,0.4-5.2,0-7.8-0.1c-0.8-0.8-1.7-1.5-2.6-2.2c0.2-3.6,0-7.3,0.5-10.9c-0.2-1.5-0.3-2.9-0.5-4.4
		                    c-1.2-1.3-2.6-2.3-3.9-3.5c-1.4,0.3-2.7,0.7-4,1.1c-0.6,0-1.7,0.1-2.3,0.2c-1.5-4.9-2.4-9.9-3-15c-2-7.2-5.6-14.3-4.7-21.9
		                    c0.8-4.5,1.8-9.3,5-12.7c4.6-5.3,7.2-12.2,12.9-16.5c0-0.5,0.1-1.6,0.1-2.2c1.1-1.6,1.9-3.5,3.4-4.9c4.1-1.2,8.5-0.6,12.3,1.2
		                    c2.2-0.5,4.4-1,6.5-1.7c2.2-1.4,3.1-4,5-5.7c4.1-2.8,8.2-5.7,12.5-8.3C306.4,430.4,307.3,430.7,307.8,430.9z"
          ></path>
        </g>
        <g id="_x23_1dd1cfff">
          <path
            class="region-area-23"
            d="M329.5,548.1c3.5,1.5,2,6.5,4.5,9.1c4.2,0.8,8.4,0.6,12.6,0.3c5.8,8.5,9.1,18.4,15,26.7
		                    c9.5-0.1,19.1,0.2,28.6-0.5c4.7,1.2,5.6,7.3,9.9,9.1c3.8-0.1,7.2-2.3,10.9-3c8.4-2.4,16.8-5.4,25.4-7c6.7-2.6,13.5-5.2,20.6-6.7
		                    c1.5,2.9,4.4,4.9,5.9,7.9c3.9,7,8.8,13.6,10.7,21.5c2.6,3.5,4.7,7.4,5.3,11.8c-1,1.7-1.7,3.5-2.4,5.3c-2,2.3-5.5,3.4-6.5,6.5
		                    c0.4,2.2,1.4,4.3,2.1,6.5c2.3,4.3,4,8.9,5.6,13.5c0.5,3.6,0.1,7.3,0.1,11c-6.2-1-11.8-4.1-17.8-5.4c-4.5,1.8-9.6,2.4-13.8,5.2
		                    c-4.1-2.1-7.2-5.6-10.8-8.4c-4.5,0.6-9,0.4-13.5,0.1c-3.2-3.6-6.7-6.9-9.6-10.6c-2-2.1-3.7-4.8-6.4-6.1c-4.5-1.2-9.1-2.2-13.7-2.2
		                    c-7.1,2.5-14.2,5.3-21.3,8.2c-5.2,1-9.3-3.1-14-4.4c-4.7,0.6-8,5.5-13,5c-4.1-1-8.1-2.4-12.1-3.9c-2.5-4.4-3.7-9.6-5.8-14.2
		                    c-2.1-5.9-6.2-11.4-5.6-18c0.2-14.5-0.4-29.1,0.1-43.7c1.1-1.4,2.1-3,3.2-4.5C325.9,554.1,327.8,551.1,329.5,548.1z"
          ></path>
        </g>
        <g id="_x23_1d61d1ff">
          <path
            class="region-area-13"
            d="M254.9,594.7c1.5-3.6,5.5-4.9,9-5.8c5.8,8,10.1,17,15.9,24.9c2.9,4.2,7.8,6.5,10.5,10.9
		                    c1.9,2.5,2.9,5.3,3.9,8.3c1.2,4.1,3.4,7.8,4.4,12c0.4,2.1-2.1,2.9-3.3,4.1c-6.5,2.8-12.6,6.2-18.8,9.7c-2.6,1.4-4.1,4-5.2,6.6
		                    c-0.7,1.5-1.5,3.1-2.3,4.6c-2.1,2.7-3.6,5.7-5,8.8c-1.8-0.4-3.7-1-5.2-2.1c-1.3-0.5-2.7-0.9-4-1.4c-2-1.2-4.3-1.7-6.6-2.2
		                    c-1.2-1-2.3-2-3.5-3c-5.8,6.9-12.2,13.1-18.1,19.9c-1.1,3.9,0.9,8.5-1.2,12.3c-2.9,3.5-6.5,6.5-9.5,10c-3.8,0.5-7.6,1.8-10.9,3.9
		                    c-5.6,0.6-11.1,0.1-16.7,0.5c-3,1-4.9,3.9-7.6,5.5c-1.8,1.6-3.8-0.5-5.5-1.2c-4-0.8-7.8-2.2-11.3-4.2c0-0.2,0-0.8,0-1
		                    c4-4.5,7.9-9.2,12.4-13.3c1.3-1.3,2.9-2.6,3.5-4.3c-0.8-8.7,0.7-17.4,0.5-26.1c0.4-3-1.9-5.4-2.7-8.2c-4.8-2.6-7.7-7.4-11.9-10.6
		                    c-2.2,0.6-4.3,1.5-6.4,2.3c-4.3,2.8-8.7,5.7-13.6,7.3c-2.3-0.8-4.8-1.5-5.7-4.1c-0.3-1.1-0.7-2.3-1-3.4c-0.1-1.6-0.1-3.2-0.2-4.8
		                    c2.8-2.8,5.9-5.2,9.5-6.7c2.7-4.3,7.3-6.8,10.8-10.3c3-3.1,7.4-4.8,9.6-8.6c-1.4-3.2-3.4-6.2-4.8-9.4c-0.1-0.5-0.3-1.4-0.4-1.9
		                    c-1.8-2.4-3.6-4.8-4.7-7.6c-0.3-2.5-0.2-4.9-0.3-7.4c4.1-2.8,7-7.8,12.1-8.7c4.6,2,7.8,6.1,11.1,9.7c2,3.5,6,5.4,7.8,9.1
		                    c-0.8,3.7-2.5,7.1-3.9,10.6c0.9,0,2.7,0,3.6-0.1c0.4,0.6,1.3,1.7,1.8,2.2c3.5-0.4,7.8-0.7,9.9-3.9c1.2-1.5,2.4-3,3.6-4.4
		                    c1.9,0,3.8,0,5.6,0c3.8,3.5,6.7,7.9,10.4,11.5c4.8-0.1,7.9-3.8,11.4-6.4c1.8,0,3.5,0,5.3,0c5,4.5,9,10,14.3,14.3
		                    c2.1-1.5,4.3-2.8,6.6-3.9c-0.2-3.2-2.9-5.1-4.6-7.6c0.2-1.4,0.8-2.6,1.3-3.9c2.7,0.2,5.6-0.5,8.2,0.5c2.9,2.5,4.8,6,6.7,9.2
		                    c1.7,0.9,3.3,1.8,4.9,2.8c2.3-1.6,4.5-3.5,6.6-5.4c-1.2-1.6-2.3-3.3-3.2-5.1c-4.8-0.5-8.5-3.7-12.5-6.2c-2.6-1.7-5.3-3.7-6.2-6.8
		                    C258.5,602.2,257.4,598.1,254.9,594.7z"
          ></path>
        </g>
        <g id="_x23_d11d7eff">
          <path
            class="region-area-11"
            d="M384.7,636.8c2.9-0.6,5.4-2.2,8.3-2.6c4.4,0.5,8.8,1.6,13.1,2.6c4.9,5.6,9.5,11.5,15.4,16.2l0.2,0.6
		                    c4.5-0.1,9.1-0.1,13.6,0.2c1.8,1.6,3.7,3.2,5.6,4.7c1.1,1.4,2.4,2.8,4.2,3.2c2.7-0.6,5.3-1.7,7.8-2.9c4.1-1.6,8.5-1.5,12.8-1.2
		                    l0,0.9c1.9,0.6,3.8,1.2,5.8,1.7c3.1,1.5,6.4,2,9.8,1.4c0.4,1.7,1,3.4,1.4,5.1c2.1,6.5,3.5,13.9,0.3,20.2c-1,3-3.2,5.4-5.9,7
		                    c-4.2,2.4-8,5.4-12.3,7.7c-1.7,0.8-2.9,2.3-4.1,3.7c-0.4,0.1-1.2,0.4-1.6,0.6c-0.1,3.2,1,7.7-2.8,9.3c-7.9,4.2-15,10.8-24.2,12
		                    c-7.4,1.5-14.5,4.2-22,5.2c-0.9,0.2-1.8,0.5-2.5,1.1c-2.4,4.9-2.2,10.6-4.2,15.7c-1.5,6-1.4,12.3-3.3,18.1
		                    c-4.2,8.6-11.3,15.3-16.7,23.1c-2.6,4.9-5.1,9.8-7,15.1c-0.7,1.7-2,4.5-4.2,3.2c-4.8-1.8-9.6-3.7-14.4-5.4
		                    c-2.1-0.8-4.6-1.8-5.2-4.2c-0.1-4.1,0.1-8.3-0.4-12.4c-2-6.2-8.1-9.5-11.6-14.8c-0.9-0.6-1.7-1.1-2.6-1.7
		                    c-4.4-5.7-5.7-13.4-5.3-20.4c2.2-4.4,4.4-8.9,7-13.1c2.4-3.9,3.7-8.3,6.2-12.2c-0.6-2.5-1.1-5-2-7.5c-1.9-5.3-2.5-11-4.4-16.2
		                    c-1.8-6.1-5.1-11.6-7.1-17.7c-1.6-11.2,1.6-22.9-2.2-33.8l-0.6,0.1c-0.5-1-1-2-1.4-3.1c-1.8-1.5-2.5-3.5-1.8-5.8
		                    c4.9-1.1,9.8,0.1,14.7,0.9c1.4,1,2.9,1.8,4.5,2.1c0.9-0.7,1.9-1.4,2.8-2c2.3-1,4.5-2.1,6.5-3.6c4.8,0.6,9,3.3,13.5,5
		                    C373.8,640.5,379.7,639.9,384.7,636.8z"
          ></path>
        </g>
        <g id="_x23_b11dd1ff">
          <path
            class="region-area-14"
            d="M318.9,644.1c1.6-0.6,3.3-0.8,5-0.9c1.6,4.2,6,7.2,5.9,12c0.1,8,0,15.9,0.1,23.9c-0.1,5.9,4.1,10.4,5.7,15.9
		                    c1.6,4.2,2.7,8.6,3.9,13c2.1,5.5,3.8,11.2,4.1,17.1c-0.4,2.1-1.8,3.9-2.8,5.8c-3.2,5-5.3,10.5-8.3,15.6c-0.8,1.5-2,2.8-2.2,4.5
		                    c0.3,5.4,1.7,10.6,3.3,15.7c1.1,2.9,3.5,4.9,5.5,7.1c4,3.7,7.4,7.9,11.5,11.5c0.1,4.6,0.1,9.2-0.3,13.8c-2.4,1.5-4.9-0.3-7.2-0.8
		                    c-2.4-0.5-4.7-1.2-7-1.7c-6.8,1.4-13.5,3.1-20.5,3.2c-0.8,0.5-1.6,1-2.4,1.6c-0.8,0.4-1.7,0.9-2.5,1.3c-5.9,3.4-11.4,7.4-17.3,10.8
		                    c-3.5,2.5-7.8,4.1-10.6,7.4c-1.3,5.1-1.4,10.5-1.2,15.7c-0.6,0.1-1.7,0.2-2.2,0.3c-9.1-4.1-18.7-7.2-27.1-12.6
		                    c-2,1.3-3.9,2.7-6,3.9c0.3-4.1,2.7-7.6,3.4-11.7l0.5-0.1c3.5-4.2,7.3-8.1,11.3-11.8c-0.8-1-1.5-1.9-2.4-2.8
		                    c-4.6-5.7-9.3-11.3-14.1-16.8c-6.8-5.4-13.7-10.7-20.3-16.4c-0.1-5.9-0.4-11.8,0.9-17.6c0.8-2.7,1.5-5.4,2.2-8
		                    c0.2-1,0.9-2.1,0.6-3.1c-1.8-2.2-4.2-3.8-6.3-5.7c-2.1-2.5-4.4-4.9-6.9-7.1c0.1-0.4,0.2-1.2,0.2-1.6c1.8-1.8,4.4-3.4,4.4-6.3
		                    c-1.4-1.5-3.9-2.7-3.8-5.1c-0.1-1.5,1.3-2.2,2.2-3l0.4,0c3.1-3.3,6.8-6.2,9.5-9.8c0.3-3.5-0.3-7,0.4-10.5c2.7-3.9,6.4-6.9,9.4-10.6
		                    c3.1-2.8,5.8-8.3,10.8-6.2c4.9,2.7,10.1,4.8,15.3,7c3.3-4.8,6.4-9.9,7.8-15.7c2.3-2.9,5.1-5.4,8.5-6.9c5.7-2.8,11.1-6,16.6-9.2
		                    c5.4-2.4,11.3-2.6,16.7-4.6C315.5,644.4,317.2,644.2,318.9,644.1z"
          ></path>
        </g>
        <g id="_x23_5e59a2ff">
          <path
            class="region-area-10 availableRegion"
            d="M490.9,702.2c5.1-0.1,10-2.4,15.2-1.8c4.2,0.4,8.3,1.6,12.5,1.8c0.4,0.4,1.2,1.3,1.7,1.7
		                    c0.5,0.1,1.5,0.3,2,0.4c4.5,1.5,9.4,1.6,13.6,3.8c4,1.8,7.5,4.3,11.1,6.9c9,5.7,16.6,13.5,22.5,22.4c3,3.8,3.5,8.9,6.2,12.9
		                    c4.3,7.3,3.2,16.3,1,24.2c-1.7,3.2-3.7,6.4-4.1,10c-3,7.5-8.6,13.4-13.1,20c-1.5,1.9-3.9,2.9-5.3,5c-2,2.6-3.9,5.3-6.7,6.9
		                    c-2.1,2.8-4.4,6.5-8.3,6.2c-4.8,0-9.5,1.1-14.2,1.3c-1.5,0.9-0.1,2.5,0.3,3.7c2.3,3,6.4,5.5,5.6,9.9c-1.5,0.9-2.9,1.9-4.3,2.9
		                    c-7.3,2.6-15.1,2.2-22.7,2.8c-1.3,0-2.4,0.9-3.6,1.3c3.1,2,5.4,5,8.7,6.8c0,0.6,0,1.7,0,2.3c-1.2,0.9-2.5,1.8-3.8,2.6
		                    c-1.1,3.6-1.8,8.4-5.6,10.2c-10.9,0.5-21.8,0.1-32.7,0.3c-0.6-2-1.2-4-1.7-5.9c-1.1-0.6-2.1-1.2-3.2-1.8c0.1-3.7,0.7-7.4,0.9-11.2
		                    c-0.8-3.5-1.7-7.1-4.1-9.9c-1.9-2.6-1.7-7.6-5.8-7.9c-4.1-0.8-4.2,5.2-7.9,5.6c-4.5,1-8.1,4.2-12.6,5.1c-3.8,1.7-8.3-0.1-12.1,1.9
		                    c-2.9,1.6-5.3,3.9-8.4,5c-7.6-1.3-15.4-1.7-22.8-4c-3-2.7-3-7.3-5.6-10.2c-3.5-2.5-8-2.6-11.8-4.3c-4.3-1.9-9-2.8-12.8-5.7
		                    c0.2-5.3,0.4-10.7-0.1-16c0.5-0.2,1.5-0.7,2-0.9c4.6,2.3,9.3,4.5,14.2,5.9c1.3-0.4,1.6-2.1,2.3-3.1c3.1-6.5,4.3-14.1,9.4-19.5
		                    c2.5-2.9,4.5-6.2,7-9c3.5-4.3,7.8-8.5,8.5-14.3c0.8-5.2,2.8-10.2,3.3-15.4c1.3-5.2,1.2-10.9,3.7-15.8c7.5-2.1,14.9-4.7,22.7-5.6
		                    c5.3-0.8,10.1-3.2,14.5-6.1c4.7-3.1,9.8-5.6,14.5-8.6c0.2-1.7,0.4-3.3,0.5-5c2.2,0.1,4.3,0,6.5,0.3c2.6,0.4,4.1,2.7,5.4,4.8
		                    c0.5,0,1.5,0.2,2,0.2c1.2,0.6,2.4,1.2,3.7,1.8c0.7-2.3,1.5-4.6,2.3-6.8c0.3,0,0.9-0.2,1.2-0.2C484,706.4,486.6,702.4,490.9,702.2z"
          ></path>
        </g>
        <g id="_x23_6d1dd1ff">
          <path
            class="region-area-21"
            d="M203.1,718.2c3.3,0.1,6-2,9-2.9c2.1,0.5,3,2.7,4.3,4.2c-0.6,0.2-1.9,0.6-2.6,0.8l2.7,0.3
		                    c-1.3,2-2.8,3.9-4.2,5.8c4.1,5.7,10,9.7,14.3,15.2c-1.3,3.7-2.5,7.5-3.5,11.3c-0.2,5.6-0.1,11.2,0,16.8c4.5,4.7,10.4,7.6,15,12.2
		                    c7,5.3,12.3,12.4,17.9,19.1c-0.1,2,0.1,4-0.3,6c-2.1,3.4-5.2,6-7.4,9.3c-1.7,2.3-1.3,5.5-3.1,7.8c-1.4,1.8-3.1,3.3-4.7,5
		                    c-3.9-0.4-7.8-1.4-11.4-3.1c-3-1.5-7.1-2-9.6,0.7c-3.2,2.5-5.2,6.1-7.7,9.2c-0.6,0-1.7,0.1-2.2,0.1c-2.7,3-6.5,4.9-10.6,4.5
		                    c-3.9,0.2-6.6-3-9.5-5c-2.4-1.4-4.8-2.8-7-4.5c-1.5-2.6-3.3-4.9-4.5-7.6c-1.3-2.6-0.3-5.7-1.8-8.1c-0.7-1.2-1.5-2.4-2.3-3.5
		                    c-2.4-3.3-2.9-9.3-7.8-9.6c-3.7-0.7-6.1,2.6-8.5,4.8c-1.9,0.4-3.9,0.9-5.8,1.2c-1.1-0.7-2.3-1.3-3.4-1.9c-1.6,0.4-3.2,0.8-4.8,1
		                    c-1.1-0.8-2.3-1.4-3.4-2.4c-1.7-5.9-1.5-12.1-0.3-18c-0.4-0.3-1.2-0.9-1.6-1.1c-0.3-1-0.6-2-0.9-3c-1.3-0.4-2.6-0.7-3.9-0.9
		                    c-4.1-2.5-8.9-1.4-13.4-1.4c-2,2.4-4.9,3.8-7,6.1c-1.7,1.7-3.5,3.7-6.1,3.7c-6.1,0.4-12.2-0.2-18.4-0.2c-2.3-4.4-4.6-8.9-7.5-12.9
		                    c-2.7-1.7-5.4-3.3-6.8-6.3c-0.5-2.8-1.4-5.6-0.8-8.4c0.9-4.3,0.3-8.8,0.7-13.1c0.4-2.3,2.3-3.7,4.1-4.8c3.9-0.4,7.8,0.2,11.6,1.1
		                    c5.5-0.2,11,1.2,16.5,1.2c3.4-2.2,6.3-5.3,10.3-6.6c1.2-2.1,2.1-4.7,4.6-5.4c6.6-1.3,13.1,1.1,19.6,2.1c1.5-1,3-2.1,4.5-3.2
		                    c0.7,0,2,0,2.7,0.1c0.4-1.5,0.9-3.1,1.3-4.6c0.6-0.8,1.1-1.6,1.7-2.4c1.3-0.5,2.6-0.9,3.9-1.4c1.7-2.8,3.5-5.5,6.6-7
		                    c6.1,2.5,12.1,5,18.1,7.6c3.5-1.8,5.8-5.2,9.4-6.9C193.8,718,198.4,718.3,203.1,718.2 M211.2,717.1
		                    C211.4,720.5,214.2,715.6,211.2,717.1z"
          ></path>
        </g>
        <g id="_x23_4c1492b3">
          <path
            class="region-area-21"
            d="M211.2,717.1C214.2,715.6,211.4,720.5,211.2,717.1z"
          ></path>
        </g>
        <g id="_x23_775644ff">
          <path
            class="region-area-20"
            d="M315.2,802.3c6.6-1.3,13.2-2.2,19.8-3.2c5.4-0.4,10.5,1.9,15.6,3.3c2.4,1,6.1,1.3,6.5,4.6
		                    c0.2,6.2-0.2,12.4,0.3,18.6c5.3,2.6,11.1,4.1,16.5,6.5c1.3,0.5,2,1.8,2.8,2.9c1.5,0,3.1,0,4.6,0c3,3,4.2,7.1,5.8,10.9
		                    c7.9,2.3,16.6,1.8,24.3,5c0.9,2.7,0.1,5.6,0.1,8.4c-0.8,0.6-1.5,1.2-2.3,1.8c-0.1,3.1-3.1,4.4-5.3,5.9c-4.7,2.8-8.9,6.4-13.6,9.2
		                    c-1.8,1.1-3.6,2.5-4.2,4.6c-2.1,5.6-5,11-6.2,16.9c1.1,2,2.6,3.7,4,5.5c0,1.5,0,3,0,4.5c-1.5,2-3,4-4.4,6c0.2,3.3,0.5,6.7-0.2,10
		                    c-3.6,5.4-8.7,9.6-12.2,15.1c-2.3-0.1-4.5-0.2-6.8-0.3c-4.8-3.2-9.2-6.9-14.1-9.7c-2.1-1.3-3.6-3.1-5.2-4.9
		                    c-3.1-0.4-5.1-2.9-7.3-4.8c-3.3-1.8-6.6-3.7-10-5.4c-0.8,4.8-0.6,9.8-0.7,14.6c-1.1,1.1-2.3,2.2-3.4,3.4c-3.5-0.1-7-0.2-10.5,0.1
		                    c-9,0.5-18,0-26.9,0.2c-3.3,2-3.5,6.5-5.4,9.6c-3.3,0.1-6.7,0.1-10-0.1c-4.7-1.5-9.3-3-14.1-4.2c-1.4-0.3-2.8-0.6-4.2-0.8
		                    c-1.1-1.1-2.1-2.1-3.2-3.2c0.2-2.9-0.4-6,0.6-8.8c2.4-3.2,6.5-4.8,8.6-8.2c1.6-3.7,2.3-7.7,3.4-11.6c1.5-4.2,1.2-8.8,2.5-13.1
		                    c0.7-2.3,0.8-4.8,0.8-7.2c5.5-5.8,14-8.7,17.3-16.5c5-6.7,4.2-15.4,4.5-23.2c0-6.7,1.4-13.4,1.5-20.1c-0.1-2,1.1-3.8,2.6-5
		                    c7.1-5,14.8-9.2,22.1-14C311,804.6,312.8,802.9,315.2,802.3z"
          ></path>
        </g>
        <g id="_x23_497744ff">
          <path
            class="region-area-22"
            d="M245.1,831.9c2.7-1.4,4.8-3.7,7.7-4.6c8.9,4.4,17.8,9,27.4,11.5c0.8,6.4,0.1,12.8-0.4,19.2
		                    c-0.3,5.4-4,9.6-6.9,13.9c-3.4,4.8-9.3,6.5-13.3,10.6c-1.4,3.6-1.1,7.5-2,11.3c-0.6,6.6-2.3,13-4.5,19.2c-1.7,4.9-6.6,7.5-10.1,11
		                    c-0.3,2.7,0,5.5,0.1,8.2c-1.1,0.6-2.1,1.3-3.2,2c-6.2-0.6-11.8-3.3-17.9-4.4c-13.4-3.4-26.7-7.4-40.2-10.8c-1.3-0.2-3.3-1-4.1,0.5
		                    c-6.2,10.9-11.3,22.6-18.4,32.9c-1.3,3.3-2.9,6.4-4.5,9.5c-3.7,1-7.8,1.5-11.5-0.2c-20.6-8.8-40.5-19.4-61.2-27.9
		                    c-3.7,2.2-5.9,6.1-9.3,8.6c-4.3,2.1-9.3,2.8-14,1.6c-2.8-0.5-6.1-2-8.4,0.5c-0.2,4.8-4.9,6.9-7.6,10.1c-4.1,4.1-7.5,10.1-14,10.2
		                    h-1.1c-5.7-4.4-12.1-7.5-17.8-11.8c-3.2-2.1-6.6-3.8-9.8-5.8V946c0.4-0.6,1.2-1.7,1.6-2.2c-0.3-2.3-0.6-4.6-0.8-6.9
		                    c6.1-2.4,12.8-1.6,19.1-2.5c8-1,16,0.4,24-1c1.9-0.2,2.1-2.6,3.1-3.8c-0.6-2.4-1.5-5.2,0.6-7.2c3.8-2.5,8.4-3.3,12.4-5.4
		                    c3.1-3.1,3.9-7.8,7.4-10.5c3.6-2.4,8.3-0.5,12.1-2.2c5.1-3,9.2-7.6,14.5-10.3c4-2.8,7.7-6,11.4-9.2c1-4.7-3.9-8.6-3-13.5
		                    c0.5-3.9,2-9.2,6.8-9.2c4.4,0.2,6,4.8,7.8,8c2.6,0,5.2,0,7.8,0c2.7-4,4.6-8.4,7-12.5c2-2.9,4.2-5.6,6-8.5c4.6-1.4,9.4-2,14.3-1.9
		                    c5.6,0.2,11.1-1.2,16.7-1.2c4.2,3.4,8.4,6.7,12.9,9.6c4.3,3.5,8.6,7,13.5,9.6c8.1-1.2,16.2-2.6,24.1-4.4c1-4,1.9-8,3.8-11.6
		                    c1.6-2.1,4.4-2.9,6-5.1C233.2,838.7,238.9,834.7,245.1,831.9z"
          ></path>
        </g>
        <g id="_x23_474747ff">
          <path
            class="region-area-12 availableRegion"
            d="M451,832.5c1.3,0,2.7,0,4,0c0,3.5,1.8,6.6,4.2,9c0,0.5,0.1,1.3,0.2,1.8c0.7,1.1,1.3,2.2,2,3.3
		                    c0,3.1,0,6.2,0.1,9.3l-1.5-0.6c0.2,0.5,0.6,1.4,0.8,1.9c-0.6,0.5-1.2,1.1-1.6,1.7c0.8,0.9,1.7,1.7,2.7,2.5c1.2,0.9,2.4,1.7,3.6,2.5
		                    c0,0.9,0.1,2.7,0.1,3.6c-6.4,0.8-12.7,1.9-18.5,5c-2.1,0-4.2,0-6.2,0c-1.1-0.5-2.2-0.9-3.4-1.3l0.1-0.8c-0.7,0-2,0-2.7,0
		                    c-4.2-2.5-8.9-3.7-13.6-5l0-0.6c-2.6-0.8-5.2-1.7-7.8-2.6c-0.1-2.7-0.1-5.3,0-8l-1.1,0.6c0-1.1,0-3.2,0.1-4.3
		                    c1.9-1.5,3.7-2.9,5.6-4.3c1.6-1.2,3.1-2.4,4.8-3.4c3.2-0.4,6.5-0.2,9.8-0.3l-0.4,1.6c1.6-0.5,3.2-1.1,4.6-1.9
		                    c0.7-0.7,1.4-1.4,2-2.2c1.3-0.8,2.6-1.6,3.8-2.5c1.7,0,3.4,0.1,5.1,0.1C449,835.9,450,834.2,451,832.5z"
          ></path>
        </g>
        <g id="_x23_242424ff">
          <path
            class="region-area-19"
            d="M399.6,872.6c5.1-3.2,9.7-7.2,15.3-9.5c3.4,2.6,7.5,3.7,11.6,4.7c3,1.8,6.2,3,9.5,4.1c2.7,0.8,5.1,2.5,8,2.8
		                    c2.6,0,4.7-1.6,6.9-2.7c1.1-0.5,2.2-1.1,3.3-1.6c5.8-0.5,11.6-0.4,17.3-0.4c0.7,1.2,1.3,2.5,2,3.8c5.2,0.1,10.5-0.9,15.7-0.2
		                    c0.4,0.7,0.8,1.4,1.3,2.1c0.4-0.6,1.2-1.7,1.6-2.3c1.9,5.7,2.6,11.7,4.3,17.5c0.3,1.5,1.9,2.9,3.4,2.3c5.4-1,10.7-2.4,16.2-2.9
		                    c4.8,1.6,9-3,14-2.3c1,1.3,2.3,2.1,3.8,2.7c-0.5,3.5-1.7,6.9-2.7,10.2c-1,7.3-0.5,15.8-5.6,21.8c-5.1,1.8-11.2-0.2-15.9,3.1
		                    c-4.5,3.4-8.4,7.5-12.9,10.9c-1.9,1.7-4.5,1-6.8,1.3c-1.5-1.2-2.7-3.4-5-2.6c-5.8-0.1-10.1,4.4-15.3,6.4
		                    c-5.5,2.2-11.2,3.8-16.5,6.5c-3.8,2-8.1,2.7-12.3,3.5c-4.1-1.3-8.8-1.6-12.4-4.3c-5.1-4.1-11-7.9-17.6-8.3
		                    c-10.6,1.2-21.3,1.6-32,2.6c-2.2,0.2-4.4,0.1-6.6-0.2c-1.3,0.1-2.6,0.3-3.9,0.4c2.2-6,7.4-10.2,11.5-15c2.1-2.7,1.2-6.3,1.5-9.5
		                    c-0.2-5.3,5-8.1,7.8-11.9c-2.2-2.6-4.7-4.9-6.7-7.7c-0.3-1.8,0.6-3.4,1.1-5c1.8-4.4,2.9-9.2,5.9-13.1
		                    C392.6,876.9,396.2,874.8,399.6,872.6z"
          ></path>
        </g>
      </svg>
    );
  }
}

export default EnergyRegionMap;