import React from "react";
import Card from "../../components/generics/Card";
import Table from "../../components/analytical/Table";
import CardButton from "../../components/interactive/CardButton";
import CreatePartner from "../../modals/CreatePartner";

class PartnerManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      partners: null,
      createPartnerModal: false
    };
  }
  componentDidMount() {
    this.getPartners();
    this.props.setNavigationOptions([]);
    this.props.setTitle("Partner Management");
  }
  async getPartners() {
    const res = await this.props.api.get("partners");
    this.setState(
      {
        partners: res.data.data.filter(x => x.active)
      });
  }
  render() {
    return (
      <div className="col-1 gap-50">
        {this.state.createPartnerModal ? <CreatePartner api={this.props.api} onExit={reload => {
          if(reload) {
            this.setState({
              partners: null
            });
            this.getPartners();
          }
          this.setState({createPartnerModal: false});
        }}/> : null}
        <div className="col-1 gap-10">
          <p className="ui-label">Quick actions</p>
          <div className="col-2 gap-50">
            <CardButton
              title="Create a new partner"
              colour="green"
              subTitle="Add a new partner to Green Studio"
              icon="handshake"
              onClick={() => this.setState({createPartnerModal: true})}
            />
          </div>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Partners</p>
          <Card
            loading={this.state.partners === null}
            style={{ height: 500 }}
            className="padding-20"
          >
            {this.state.partners ? <Table compact onClick={i => this.props.history.push(`/portal/partners/partner-management/${this.state.partners[i].id}`)} data={this.state.partners.map(x => ({
              "Name": x.name,
              "Reference": x.salesChannelCode,
              "Tariffs": x.tariffs.length ? x.tariffs.map(x => x.dyballName).join(", ") : "N/A",
              "White Label": !!x.whitelabel,
              "SFTP": !!x.sftp,
              "API": !!x.api,
            }))}/> : null}
          </Card>
        </div>
      </div>
    );
  }
}

export default PartnerManagement;
