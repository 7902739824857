import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Table from "../../components/analytical/Table";
import GenericConfirm from "../../modals/GenericConfirm";
import moment from "moment-business-days";
import abbreviate from "number-abbreviate";
import downloadFile from "../../components/helpers/downloadFile";
import { json2csv } from 'json-2-csv';
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell
} from "recharts";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class PayoutForecasting extends React.Component {
  constructor() {
    super();
    this.state = {
      monthSoFar: null,
      monthSoFarLastPeriod: null,
      monthToGo: null,
      monthToGoLastPeriod: null,
      monthTotal: null,
      monthTotalLastPeriod: null,
      nextMonthTotal: null,
      todaysTotal: null,
      todaysTotalLastPeriod: null,
      nextDayTotal: null,
      nextDayTotalLastPeriod: null,
      nextWeekTotal: null,
      nextWeekTotalLastPeriod: null,
      next28Total: null,
      next28TotalLastPeriod: null,
      data: null,
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    try {
      let forecast = await this.props.api.get(`/billing/forecast`);
      forecast = forecast.data.data
        .map(x => ({
          ...x,
          payoutDate: moment(x.payoutDate)
        }))
        .sort((a, b) => a.payoutDate - b.payoutDate);
      this.setState({
        data: forecast,
        monthSoFar: forecast
          .filter(
            x =>
              x.payoutDate.isSameOrAfter(moment().startOf("month"), "day") &&
              x.payoutDate.isSameOrBefore(moment(), "day")
          )
          .reduce((a, x) => (a += x.amount), 0),
        monthSoFarLastPeriod: forecast
        .filter(
          x =>
            x.payoutDate.isSameOrAfter(moment().subtract(1,"month").startOf("month"), "day") &&
            x.payoutDate.isSameOrBefore(moment().subtract(1,"month"), "day")
        )
        .reduce((a, x) => (a += x.amount), 0),
        monthToGo: forecast
          .filter(
            x =>
              x.payoutDate.isAfter(moment(), "day") &&
              x.payoutDate.isSameOrBefore(moment().endOf("month"), "day")
          )
          .reduce((a, x) => (a += x.amount), 0),
        monthToGoLastPeriod: forecast
        .filter(
          x =>
            x.payoutDate.isSameOrAfter(moment().subtract(1,"month"), "day") &&
            x.payoutDate.isSameOrBefore(moment().subtract(1,"month").endOf("month"), "day")
        )
        .reduce((a, x) => (a += x.amount), 0),
        monthTotal: forecast
          .filter(x => moment().isSame(x.payoutDate, "month"))
          .reduce((a, x) => (a += x.amount), 0),
        monthTotalLastPeriod: forecast
        .filter(x => moment().subtract(1,"month").isSame(x.payoutDate, "month"))
        .reduce((a, x) => (a += x.amount), 0),
        nextMonthTotal: forecast
          .filter(x =>
            moment()
              .add(1, "month")
              .isSame(x.payoutDate, "month")
          )
          .reduce((a, x) => (a += x.amount), 0),
        todaysTotal: forecast.find(x => moment().isSame(x.payoutDate, "day")) ? forecast.find(x => moment().isSame(x.payoutDate, "day"))
          .amount : 0,
        todaysTotalLastPeriod: forecast.find(x => moment().subtract(1,"month").isSame(x.payoutDate, "day")) ? forecast.find(x => moment().subtract(1,"month").isSame(x.payoutDate, "day"))
        .amount : 0,
        nextDayTotal:
          forecast[
            forecast.findIndex(x => moment().isBusinessDay() ? moment().isSame(x.payoutDate, "day") : moment().prevBusinessDay().isSame(x.payoutDate, "day")) + 1
          ],
        nextDayTotalLastPeriod: forecast[
          forecast.findIndex(x => moment().isBusinessDay() ? moment().isSame(x.payoutDate, "day") : moment().prevBusinessDay().isSame(x.payoutDate, "day"))
        ],
        nextWeekTotal: forecast
          .filter(
            x =>
              x.payoutDate.isAfter(moment(), "day") &&
              x.payoutDate.isSameOrBefore(moment().add(7, "days"), "day")
          )
          .reduce((a, x) => (a += x.amount), 0),
        nextWeekTotalLastPeriod: forecast
        .filter(
          x =>
            x.payoutDate.isAfter(moment().subtract(7,"days"), "day") &&
            x.payoutDate.isSameOrBefore(moment(), "day")
        )
        .reduce((a, x) => (a += x.amount), 0),
        next28Total: forecast
          .filter(
            x =>
              x.payoutDate.isAfter(moment(), "day") &&
              x.payoutDate.isSameOrBefore(moment().add(28, "days"), "day")
          )
          .reduce((a, x) => (a += x.amount), 0),
        next28TotalLastPeriod: forecast
        .filter(
          x =>
            x.payoutDate.isAfter(moment().subtract(28,"days"), "day") &&
            x.payoutDate.isSameOrBefore(moment(), "day")
        )
        .reduce((a, x) => (a += x.amount), 0),
        loading: false
      });
    } catch (e) {
      console.log(e)
      this.setState({ error: true, loading: false });
    }
  }
  download = () => {
    json2csv(this.state.data.map(x => ({
      payoutDate: x.payoutDate.format("DD/MM/YYYY"),
      amount: x.amount,
      directDebits: x.directDebits ? x.directDebits : 0
    })), (err,csv) => {
      downloadFile(
        `payout_forecast_${moment().format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  render() {
    return this.state.error ? (
      <Card
        message="Contact support to reload"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="sync"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
         <button className="ui-btn sync-button" onClick={this.download}>
          <i className="fad fa-download" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 120 }}>
            {!this.state.loading ? (
              <div className="col-4 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  compact
                  icon={`chevron-${this.state.monthSoFar >= this.state.monthSoFarLastPeriod ? "up" : "down"}`}
                  positive={this.state.monthSoFar >= this.state.monthSoFarLastPeriod ? true : false}
                  figureColour="blue"
                  label={`${moment().format("MMMM")} So Far`}
                  figure={`£${abbreviate(this.state.monthSoFar, 3)}`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.monthToGo >= this.state.monthToGoLastPeriod ? "up" : "down"}`}
                  positive={this.state.monthToGo >= this.state.monthToGoLastPeriod ? true : false}
                  figureColour="blue"
                  label={`${moment().format("MMMM")} To Go`}
                  figure={`£${abbreviate(this.state.monthToGo, 3)}`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.monthTotal >= this.state.monthTotalLastPeriod ? "up" : "down"}`}
                  positive={this.state.monthTotal >= this.state.monthTotalLastPeriod ? true : false}
                  figureColour="blue"
                  label={`${moment().format("MMMM")} Total`}
                  figure={`£${abbreviate(this.state.monthTotal, 3)}`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.nextMonthTotal >= this.state.monthTotal ? "up" : "down"}`}
                  positive={this.state.nextMonthTotal >= this.state.monthTotal ? true : false}
                  figureColour="blue"
                  label={`${moment()
                    .add(1, "month")
                    .format("MMMM")} Forecast`}
                  figure={`£${abbreviate(this.state.nextMonthTotal, 3)}`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <Card style={{ height: 450 }}>
          {!this.state.loading ? (
            <div className="padding-15">
              <ResponsiveContainer>
                <BarChart
                  data={this.state.data.map(x => ({
                    date: x.payoutDate.format("DD/MM/YY"),
                    Payout: x.amount
                  }))}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="date" />
                  <YAxis
                    width={60}
                    type="number"
                    // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={value => `£${abbreviate(value, 2)}`}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"Payout"}
                    fill={colors[1]}
                  >
                    {this.state.data
                      .map(x => ({
                        date: x.payoutDate.format("DD/MM/YY"),
                        Payout: x.amount
                      }))
                      .map((entry, index) => {
                        return (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              moment(entry.date, "DD/MM/YYYY").isAfter(moment())
                                ? colors[1]
                                : colors[0]
                            }
                          />
                        );
                      })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
        <div className="col-1 gap-10">
          <p className="ui-label">Forecasted payouts</p>
          <Card style={{ minHeight: 120 }}>
            {!this.state.loading ? (
              <div className="col-4 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  compact
                  icon={`chevron-${this.state.todaysTotal >= this.state.todaysTotalLastPeriod ? "up" : "down"}`}
                  positive={this.state.todaysTotal >= this.state.todaysTotalLastPeriod ? true : false}
                  figureColour="blue"
                  label="Today"
                  figure={`£${
                    this.state.todaysTotal
                      ? abbreviate(this.state.todaysTotal, 2)
                      : 0
                  }`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.nextDayTotal && this.state.nextDayTotalLastPeriod ? this.state.nextDayTotal.amount >= this.state.nextDayTotalLastPeriod.amount ? "up" : "down" : "up"}`}
                  positive={this.state.nextDayTotal && this.state.nextDayTotalLastPeriod ? this.state.nextDayTotal.amount >= this.state.nextDayTotalLastPeriod.amount ? true : false : true}
                  figureColour="blue"
                  label={`Next Payout (${this.state.nextDayTotal.payoutDate.format(
                    "Do MMM"
                  )})`}
                  figure={`£${
                    abbreviate(this.state.nextDayTotal.amount, 2)
                  }`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.nextWeekTotal >= this.state.nextWeekTotalLastPeriod ? "up" : "down"}`}
                  positive={this.state.nextWeekTotal >= this.state.nextWeekTotalLastPeriod ? true : false}
                  figureColour="blue"
                  label="Next 7 Days"
                  figure={`£${abbreviate(this.state.nextWeekTotal, 3)}`}
                />
                <Stat
                  compact
                  icon={`chevron-${this.state.next28Total >= this.state.next28TotalLastPeriod ? "up" : "down"}`}
                  positive={this.state.next28Total >= this.state.next28TotalLastPeriod ? true : false}
                  figureColour="blue"
                  label="Next 28 Days"
                  figure={`£${abbreviate(this.state.next28Total, 3)}`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default PayoutForecasting;
