import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import CardButton from "../../../components/interactive/CardButton";
import Table from "../../../components/analytical/Table";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class HistoricalGrowth extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      syncModal: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const tariffs = await this.props.api.get(
            `/customers/tariffs${disableCache ? "?resetCache=true" : ""}`
          );

          this.setState({
            data: tariffs.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button
            className="ui-btn sync-button"
            onClick={() => this.setState({ syncModal: true })}
          >
            <i className="fad fa-sync" />
          </button>
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live customers?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    sales: null,
                    quotes: null,
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        
          <Card style={{ height: 600 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart
                    data={Object.keys(this.state.data).map(x => ({
                      name: x,
                      Dual: this.state.data[x].dual,
                      Electric: this.state.data[x].electric,
                      Gas: this.state.data[x].gas

                    })).sort((a,b) => (b.Dual + b.Electric + b.Gas) - (a.Dual + a.Electric + a.Gas))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={40}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                        isAnimationActive={false}
                        dataKey={"Dual"}
                        bac
                        dot={false}
                        stackId="a"
                        fill={colors[0]}
                      />
                      <Bar
                        isAnimationActive={false}
                        dataKey={"Electric"}
                        dot={false}
                        stackId="a"
                        fill={colors[2]}
                      />
                      <Bar
                        isAnimationActive={false}
                        dataKey={"Gas"}
                        dot={false}
                        stackId="a"
                        fill={colors[1]}
                      />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
      </div>
    );
  }
}

export default HistoricalGrowth;
