import React from "react";
import Card from "../../../components/generics/Card";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import downloadFile from "../../../components/helpers/downloadFile";
import { json2csv } from 'json-2-csv';
import moment from 'moment';

class TransactionExplorer extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      data: [],
      account: "",
      id: "",
      filter: "account"
    };
  }
  getData = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true
    }, async () => {
      try {
        let res;
        if (this.state.filter === "account") {
          res = await this.props.api.get(
            `/transactions?account=${this.state.account}`
          );
        } else {
          res = await this.props.api.get(
            `/transactions/${this.state.id}`
          );
        }
        console.log(res.data);
        this.setState({
          data: res.data.data.sort((a, b) => moment(b.TransctionTime) - moment(a.TransctionTime)).map(x => {
            const vendor = x.Source.indexOf("Green Studio") > -1 ? "Studio" : x.Source === "Green Core API" ? x.Description.indexOf("Top") > -1 ? "Stripe" : x.Description.indexOf("Direct Debit") > -1 ? "GoCardless" : "Other" : "Historic";
            return {
              "Vendor": vendor === "Studio" ? <><i className={`fa fa-cog colour-purple margin-right-5`}></i> {x.Source.split("(")[1].slice(0, -1)}</> : (vendor === "Stripe" || x.Description === "Account Topup") ? <><i className={`fab fa-stripe-s colour-blue margin-right-5`}></i> Stripe</> : (vendor === "GoCardless" || x.Description === "Direct Debit") ? <><i className={`fa fa-university colour-green margin-right-5`}></i> GoCardless</> : vendor === "Other" ? <><i className={`fa fa-question margin-right-5`}></i> Other</> : <><i className={`fa fa-history colour-grey margin-right-5`}></i> Historic</>,
              "Account": x.SalesAccountNumber.replace(/D/g, ""),
              "Description": x.Description,
              "Amount": `£${x.Amount.toFixed(2)}`,
              "Transaction Date": moment(x.TransctionTime).format("DD/MM/YYYY HH:mm"),
              "Import Date": moment(x.ImportedOn).format("DD/MM/YYYY HH:mm"),
              "External ID": x.ExternalSalesId,
              actions: vendor === "Stripe" || vendor === "GoCardless" ? [{
                icon: "arrow-right",
                colour: "blue",
                onClick: () => window.open(vendor === "Stripe" ? x.ExternalSalesId.indexOf("re_") > -1 ? `https://dashboard.stripe.com/search?query=${x.ExternalSalesId}` : `https://dashboard.stripe.com/payments/${x.ExternalSalesId}` : x.ExternalSalesId.indexOf("RF") > -1 ? `https://manage.gocardless.com/refunds/${x.ExternalSalesId}` : `https://manage.gocardless.com/payments/${x.ExternalSalesId}`, "_blank")
              }] : [],
              rowColour: x.Amount < 0 ? "container-lighter" : null
            }
          }),
          loading: false
        });
      } catch (e) {
        this.setState({
          loading: false,
          data: null
        })
      }
    });
  }
  async download() {
    const res = await this.props.api.get(
      `/transactions?account=${this.state.account}`
    );
    json2csv(res.data.data, (err, csv) => {
      downloadFile(
        `transactions_${this.state.account}_${moment().format(
          "YYYYMMDD_hhmm"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  render() {
    return <div className="col-1 gap-40 margin-bottom-40">
      {this.state.data.length ? <button className="ui-btn sync-button" onClick={() => this.download()}>
        <i className="fad fa-download" />
      </button> : null}
      <div className="col-1 gap-10">
        <p className="ui-label">Report filters</p>
        <Card>
          <form
            className="padding-20 col-4 gap-20"
            style={{
              alignItems: "end",
              gridTemplateColumns: "auto auto max-content"
            }}
            onSubmit={this.getData}
          >
            <div>
              <select className="ui-select" onChange={e => this.setState({ filter: e.currentTarget.value, account: "", id: "" })}>
                <option value="account">Account Number</option>
                <option value="extneralid">External ID</option>
              </select>
            </div>
            <div>
              {
                this.state.filter === "account" ? <input
                  className="ui-input"
                  name="account"
                  value={this.state.account}
                  onChange={e =>
                    this.setState({ account: e.currentTarget.value })
                  }
                  type="text"
                  placeholder="e.g 200008"
                  minLength={6}
                  maxLength={6}
                  required
                /> : <input
                    className="ui-input"
                    name="externalid"
                    value={this.state.id}
                    onChange={e =>
                      this.setState({ id: e.currentTarget.value })
                    }
                    type="text"
                    placeholder="e.g ch_1ISgMsLowMKXtUsXUehMtqyN"
                    required
                  />
              }
            </div>
            <div>
              <button
                className="ui-btn-compact"
              >
                <i className="far fa-search margin-right-10" />
              Search
              </button>
            </div>
          </form>
        </Card>
      </div>
      <div className="col-1 gap-10">
        {
          !this.state.loading && !this.state.data.length ? <Card message="Nothing found, search another account number" style={{ padding: 50 }} messageIcon="empty-set" /> : <Card style={{ minHeight: 120, padding: 16 }}>{this.state.loading ?
            <div className="grid-center-center">
              <Loader />
            </div> : <Table
              compact={true}
              data={this.state.data}
            />}</Card>
        }
      </div>
    </div>
  }
}

export default TransactionExplorer;
