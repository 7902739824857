import React from "react";

import Routes from "../../routes/Partners";
import SidebarLayout from "../../layouts/Sidebar";

class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " "),
      navigationOptions: []
    }
  }
  setNavigationOptions = navigationOptions => {
    this.setState({navigationOptions});
  }
  setTitle = title => {
    this.setState({
      title
    });
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.state.title}
        sidebarTitle="partners"
        navigationOptions={this.state.navigationOptions}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
          setTitle={this.setTitle}
          setNavigationOptions={this.setNavigationOptions}
        />
      </SidebarLayout>
    );
  }
}

export default Partners;
