import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Table from "../../../components/analytical/Table";
import Loader from "../../../components/generics/Loader";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";
import {
  PieChart,
  Pie,
  Legend,
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class SalesOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("day"),
      loading: true
    };
  }
  componentDidMount() {
    this.getData();
    this.props.setTitle("Sales Overview");
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        const res = await this.props.api.get(
          `/partners/${this.props.match.params.id}/sales?start=${this.state.startDate.format("YYYY-MM-DDTHH:mm")}&end=${this.state.endDate.format("YYYY-MM-DDT23:59")}`
        );
        console.log(res.data.data);
        console.log({
          data: res.data.data,
          totals: {
            sales: res.data.data.length,
            live: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").length,
            lost: res.data.data.filter(x => x.elecRegiStatus === "Lost" || x.gasRegiStatus === "Lost").length,
          },
          salesTrend: res.data.data.reduce((a, x) => {
            const date = x.elecSaleDate ? moment(x.elecSaleDate).format("DD/MM/YYYY") : moment(x.gasSaleDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          liveTrend: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const date = x.elecRegiDate ? moment(x.elecRegiDate).format("DD/MM/YYYY") : moment(x.gasRegiDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          lostTrend: res.data.data.filter(x => x.elecRegiStatus === "Lost" || x.gasRegiStatus === "Lost").reduce((a, x) => {
            const date = moment(x.endDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          liveFuelSplit: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const name = x.elecRegiStatus === "Registered" && x.gasRegiStatus === "Registered" ? "Dual Fuel" : x.elecRegiStatus === "Registered" ? "Electric" : "Gas";
            const indx = a.findIndex(x => x.name === name);
            if (indx === -1) {
              a.push({ name, value: 1 });
            } else {
              a[indx].value++;
            }
            return a;
          }, []),
          tariffSplit: res.data.data.reduce((a, x) => {
            const name = x.elecCurrentTariff || x.gasCurrentTariff;
            const indx = a.findIndex(x => x.name === (name ? name : "N/A"));
            if (indx === -1) {
              a.push({ name: (name ? name : "N/A"), total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          elecRegiSplit: res.data.data.filter(x => x.elecRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.elecRegiStatus);
            if (indx === -1) {
              a.push({ name: x.elecRegiStatus, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          elecMeterSplit: res.data.data.filter(x => x.elecRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.elecMeterType);
            if (indx === -1) {
              a.push({ name: x.elecMeterType, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          gasRegiSplit: res.data.data.filter(x => x.gasRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.gasRegiStatus);
            if (indx === -1) {
              a.push({ name: x.gasRegiStatus, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          gasMeterSplit: res.data.data.filter(x => x.gasRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.gasMeterType);
            if (indx === -1) {
              a.push({ name: x.gasMeterType, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          liveMandateSplit: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const name = x.activeMandate ? "Active Mandate" : "Inactive Mandate";
            const indx = a.findIndex(x => x.name === name);
            if (indx === -1) {
              a.push({ name, value: 1 });
            } else {
              a[indx].value++;
            }
            return a;
          }, []),
          loading: false
        })
        this.setState({
          data: res.data.data,
          totals: {
            sales: res.data.data.length,
            live: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").length,
            lost: res.data.data.filter(x => x.elecRegiStatus === "Lost" || x.gasRegiStatus === "Lost").length,
          },
          salesTrend: res.data.data.reduce((a, x) => {
            const date = x.elecSaleDate ? moment(x.elecSaleDate).format("DD/MM/YYYY") : moment(x.gasSaleDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          liveTrend: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const date = x.elecRegiDate ? moment(x.elecRegiDate).format("DD/MM/YYYY") : moment(x.gasRegiDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          lostTrend: res.data.data.filter(x => x.elecRegiStatus === "Lost" || x.gasRegiStatus === "Lost").reduce((a, x) => {
            const date = moment(x.endDate).format("DD/MM/YYYY");
            const indx = a.findIndex(x => x.date === date);
            if (indx === -1) {
              a.push({ date, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []),
          liveFuelSplit: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const name = x.elecRegiStatus === "Registered" && x.gasRegiStatus === "Registered" ? "Dual Fuel" : x.elecRegiStatus === "Registered" ? "Electric" : "Gas";
            const indx = a.findIndex(x => x.name === name);
            if (indx === -1) {
              a.push({ name, value: 1 });
            } else {
              a[indx].value++;
            }
            return a;
          }, []),
          tariffSplit: res.data.data.reduce((a, x) => {
            const name = x.elecCurrentTariff || x.gasCurrentTariff;
            const indx = a.findIndex(x => x.name === (name ? name : "N/A"));
            if (indx === -1) {
              a.push({ name: (name ? name : "N/A"), total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          elecRegiSplit: res.data.data.filter(x => x.elecRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.elecRegiStatus);
            if (indx === -1) {
              a.push({ name: x.elecRegiStatus, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          elecMeterSplit: res.data.data.filter(x => x.elecRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.elecMeterType);
            if (indx === -1) {
              a.push({ name: x.elecMeterType, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          gasRegiSplit: res.data.data.filter(x => x.gasRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.gasRegiStatus);
            if (indx === -1) {
              a.push({ name: x.gasRegiStatus, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          gasMeterSplit: res.data.data.filter(x => x.gasRegiStatus).reduce((a, x) => {
            const indx = a.findIndex(y => y.name === x.gasMeterType);
            if (indx === -1) {
              a.push({ name: x.gasMeterType, total: 1 });
            } else {
              a[indx].total++;
            }
            return a;
          }, []).sort((a,b) => b.total - a.total),
          liveMandateSplit: res.data.data.filter(x => x.elecRegiStatus === "Registered" || x.gasRegiStatus === "Registered").reduce((a, x) => {
            const name = x.activeMandate ? "Active Mandate" : "Inactive Mandate";
            const indx = a.findIndex(x => x.name === name);
            if (indx === -1) {
              a.push({ name, value: 1 });
            } else {
              a[indx].value++;
            }
            return a;
          }, []),
          loading: false
        });
      }
    );
  }
  render() {
    return !this.state.loading ? <div className="col-1 gap-40 margin-bottom-40">
      <div className="col-1 gap-10">
        <p className="ui-label">Overall statistics</p>
        <Card style={{ minHeight: 130 }}>
          <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
            <Stat
              label="Total Sales"
              figure={this.state.totals.sales}
            />
            <Stat
              label="Total Live"
              figure={this.state.totals.live}
            />
            <Stat
              label="Total Lost"
              figure={this.state.totals.lost}
            />
          </div>
        </Card>
      </div>
      <div className="col-2 gap-20">
        <div className="col-1 gap-10">
          <p className="ui-label">Sales Trend</p>
          <Card style={{ height: 300 }}>
            <div className="padding-10">
              <ResponsiveContainer>
                <LineChart
                  data={this.state.salesTrend.sort((a, b) => a.date - b.date)}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="date" />
                  <YAxis
                    width={45}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={value =>
                      new Intl.NumberFormat("en").format(value)
                    }
                  />
                  <Line
                    isAnimationActive={false}
                    dataKey="total"
                    stroke={colors[0]}
                    type="linear"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Live Trend</p>
          <Card style={{ height: 300 }}>
            <div className="padding-10">
              <ResponsiveContainer>
                <LineChart
                  data={this.state.liveTrend.sort((a, b) => a.date - b.date)}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="date" />
                  <YAxis
                    width={45}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={value =>
                      new Intl.NumberFormat("en").format(value)
                    }
                  />
                  <Line
                    isAnimationActive={false}
                    dataKey="total"
                    stroke={colors[1]}
                    type="linear"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Loss Trend</p>
          <Card style={{ height: 300 }}>
            <div className="padding-10">
              <ResponsiveContainer>
                <LineChart
                  data={this.state.lostTrend.sort((a, b) => a.date - b.date)}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="date" />
                  <YAxis
                    width={45}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={value =>
                      new Intl.NumberFormat("en").format(value)
                    }
                  />
                  <Line
                    isAnimationActive={false}
                    dataKey="total"
                    stroke={colors[2]}
                    type="linear"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Live Fuel Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={this.state.liveFuelSplit}
                    strokeWidth={0}
                    // paddingAngle={5}
                    innerRadius={60}
                  >
                    {this.state.liveFuelSplit.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                  </Pie>

                  <Tooltip />
                  <Legend
                    payload={this.state.liveFuelSplit.map((item, index) => ({
                        id: item.name,
                        type: "circle",
                        color: colors[index],
                        value: `${item.name} (${item.value})`
                      }))}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Tariff Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-15">
            <ResponsiveContainer>
                <BarChart data={this.state.tariffSplit}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"total"}
                    fill={colors[3]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Live Mandate Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={this.state.liveMandateSplit}
                    strokeWidth={0}
                    // paddingAngle={5}
                    innerRadius={60}
                  >
                    {this.state.liveMandateSplit.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      ))}
                  </Pie>

                  <Tooltip />
                  <Legend
                    payload={this.state.liveMandateSplit.map((item, index) => ({
                        id: item.name,
                        type: "circle",
                        color: colors[index],
                        value: `${item.name} (${item.value})`
                      }))}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Electric Regi Status Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
            <ResponsiveContainer>
                <BarChart data={this.state.elecRegiSplit}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"total"}
                    fill={colors[4]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Gas Regi Status Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
            <ResponsiveContainer>
                <BarChart data={this.state.gasRegiSplit}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"total"}
                    fill={colors[5]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Electric Meter Type Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
            <ResponsiveContainer>
                <BarChart data={this.state.elecMeterSplit}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"total"}
                    fill={colors[6]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Gas Meter Type Split</p>
          <Card style={{ height: 300 }}>
            <div className="padding-top-15 padding-bottom-10">
            <ResponsiveContainer>
                <BarChart data={this.state.gasMeterSplit}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"total"}
                    fill={colors[7]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
      </div>
    </div> : <div className="grid-center-center">
        <Loader />
      </div>;
  }
}

export default SalesOverview;
