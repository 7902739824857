import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Base from "../views/energy/Base";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/energy/base"
      render={internalProps => (
        <Base {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/energy/base" />} />
  </Switch>
);

export default Routes;
