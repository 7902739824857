import React from "react";
import CardTile from "../../components/interactive/CardTile";
import Card from "../../components/generics/Card";
import AccountSettings from "../../modals/AccountSettings";

class PortalHome extends React.Component {
  constructor(){
    super();
    this.state = {
      preferenceModal: false
    }
  }
  render(){
    return(
      <div className="container-medium">
        {
          this.state.preferenceModal ? <AccountSettings onExit={reload => {
            if(reload) this.props.userReload();
            this.setState({preferenceModal: false});
          }} api={this.props.api} user={this.props.user}/> : null
        }
        <div className="col-start-2 portal-home-container">
          <div className="navigation animated fadeIn">
            <img
              alt="logo"
              src="https://green-cdn.fra1.digitaloceanspaces.com/branding/logo-r-white.svg"
              height={32}
            />
            <div className="ui-body user-button" onClick={() => this.setState({preferenceModal: true})}>
              <i className="fad fa-user"></i>
              <p className="ui-body">{this.props.user.name}</p>
            </div>
            <div className="ui-body sign-out-button" onClick={() => {
              localStorage.removeItem("apollo_key");
              window.location.reload();
            }}>
              <i className="fad fa-sign-out"></i>
            </div>
          </div>
          <div className="heading">
            <h1 className="ui-title-mega margin-bottom-10 animated fadeInUp">
              Good{" "}
              {new Date().getHours() < 12
                ? "morning"
                : new Date().getHours() >= 12 && new Date().getHours() < 17
                ? "afternoon"
                : "evening"}
              , {this.props.user.informalGreeting}
            </h1>
            <h2 className="ui-title-two ui-title-sub animated fadeInUp">
              Welcome back to Green Studio.
            </h2>
          </div>
          <div>
            <div className="col-start-2 dashboard gap-40 animated fadeIn">
              <div className="apps">
                <p className="ui-label margin-bottom-10">Your apps</p>
                <div className="tiles">
                  {this.props.user.dataAccess ? <CardTile
                    title="Data"
                    colour="blue"
                    icon="fingerprint"
                    onClick={() => this.props.history.push('/portal/data')}
                  /> : null }
                  {this.props.user.adminAccess ? <CardTile
                    title="Admin"
                    colour="orange"
                    icon="lock"
                    onClick={() => this.props.history.push('/portal/admin')}
                  /> : null }
                  {this.props.user.supportAccess ? <CardTile
                    title="Support"
                    colour="purple"
                    icon="headset"
                    onClick={() => this.props.history.push('/portal/support')}
                  /> : null }
                  { this.props.user.billingAccess ? <CardTile
                    title="Billing"
                    colour="olive"
                    icon="file-invoice"
                    onClick={() => this.props.history.push('/portal/billing')}
                  /> : null }
                  { this.props.user.paymentsAccess ? <CardTile
                    title="Payments"
                    colour="blue"
                    icon="credit-card"
                    onClick={() => this.props.history.push('/portal/payments')}
                  /> : null }
                  { this.props.user.salesAccess ? <CardTile
                    title="Sales"
                    colour="green"
                    icon="phone"
                    onClick={() => window.location.href = "https://studio.quote.green?gclid="+this.props.user.id}
                  /> : null }
                  { this.props.user.fieldAccess ? <CardTile
                    title="Field"
                    colour="green"
                    icon="globe-europe"
                    onClick={() => this.props.history.push('/portal/field')}
                  /> : null }
                  { this.props.user.partnersAccess ? <CardTile
                    title="Partners"
                    colour="purple"
                    icon="handshake"
                    onClick={() => this.props.history.push('/portal/partners')}
                  /> : null }
                  { this.props.user.smartAccess ? <CardTile
                    title="Smart"
                    colour="green"
                    icon="house-signal"
                    onClick={() => this.props.history.push('/portal/smart')}
                  /> : null }
                  { this.props.user.energyAccess ? <CardTile
                    title="Energy"
                    colour="blue"
                    icon="wind-turbine"
                    onClick={() => this.props.history.push('/portal/energy')}
                  /> : null }
                  { this.props.user.reportingAccess ? <CardTile
                    title="Reporting"
                    colour="olive"
                    icon="file-chart-line"
                    onClick={() => this.props.history.push('/portal/reporting')}
                  /> : null }
                  <CardTile
                    title="Knowledge"
                    colour="green"
                    icon="archive"
                    onClick={() => this.props.history.push('/portal/knowledge')}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="notifications">
                <p className="ui-label margin-bottom-10">Your notifications</p>
                <Card className="padding-20" loading={false} message={
                  "All clear, no notifications found."
                }></Card>
              </div>
            </div>
          </div>
          <div>
            <p className="ui-body grid-center-center margin-bottom-40">
              &copy; {new Date().getFullYear()} Green Supplier Limited
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PortalHome;
