import React from "react";
import Card from "../../../components/generics/Card";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import moment from 'moment';

class WanLookup extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      data: null,
      postcode: ""
    };
  }
  async getData() {
    this.setState({
      loading: true
    }, async () => {
      try {
        const wans = await this.props.api.get("/customers/wan?postcode=" + this.state.postcode);
        console.log(wans.data);
        this.setState({
          data: wans.data.data.length ? wans.data.data.map(x => ({
            Postcode: x.postcode,
            Tier: x.tier,
            Availability: x.availability,
            Description: x.description
          })) : null,
          loading: false
        });
      } catch (e) {
        this.setState({
          loading: false,
          data: null
        })
      }
    });
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Postcode</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.postcode}
                  onChange={e =>
                    this.setState({ postcode: e.currentTarget.value })
                  }
                  type="text"
                  placeholder="Postcode"
                />
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Data Response</p>
          {
            !this.state.loading && !this.state.data ? <Card message="Nothing found, search another postcode" style={{ padding: 50 }} messageIcon="empty-set" /> : <Card style={{ minHeight: 120, padding: 16 }}>{this.state.loading ?
              <div className="grid-center-center">
                <Loader />
              </div> : <Table
                data={this.state.data}
              />}</Card>
          }
        </div>
      </div>
    );
  }
}

export default WanLookup;
