import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import SalesTrends from "../../views/data/customer-insights/SalesTrends";
import ChannelAnalysis from "../../views/data/customer-insights/ChannelAnalysis";
import MeterTypes from "../../views/data/customer-insights/MeterTypes";
import HistoricalGrowth from "../../views/data/customer-insights/HistoricalGrowth";
import RegionalInsights from "../../views/data/customer-insights/RegionalInsights";
import PlatformUsage from "../../views/data/customer-insights/PlatformUsage";
import SupplierIntelligence from "../../views/data/customer-insights/SupplierIntelligence";
import TariffSplit from "../../views/data/customer-insights/TariffSplit";
import PostcodeGeofencing from "../../views/data/customer-insights/PostcodeGeofencing";
import SmartInterest from "../../views/data/customer-insights/SmartInterest";
import UrnLookup from "../../views/data/customer-insights/UrnLookup";
import SegmentAnalysis from "../../views/data/customer-insights/SegmentAnalysis";
import WanLookup from "../../views/data/customer-insights/WanLookup";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/data/customer-insights/sales-trends"
      render={internalProps => (
        <SalesTrends {...internalProps} {...props} />
      )}
    /><Route
    path="/portal/data/customer-insights/platform-usage"
    render={internalProps => (
      <PlatformUsage {...internalProps} {...props} />
    )}
  />
    <Route
      path="/portal/data/customer-insights/channel-analysis"
      render={internalProps => (
        <ChannelAnalysis {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/meter-types"
      render={internalProps => (
        <MeterTypes {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/historical-growth"
      render={internalProps => (
        <HistoricalGrowth {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/regional-insights"
      render={internalProps => (
        <RegionalInsights {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/postcode-geofencing"
      render={internalProps => (
        <PostcodeGeofencing {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/smart-interest"
      render={internalProps => (
        <SmartInterest {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/supplier-intelligence"
      render={internalProps => (
        <SupplierIntelligence {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/urn-lookup"
      render={internalProps => (
        <UrnLookup {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/wan-lookup"
      render={internalProps => (
        <WanLookup {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/segment-analysis"
      render={internalProps => (
        <SegmentAnalysis {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/customer-insights/tariff-split"
      render={internalProps => (
        <TariffSplit {...internalProps} {...props} />
      )}
    />
    <Route render={props => <Redirect to={`/portal/data/customer-insights/sales-trends`} />} />
  </Switch>
);

export default Routes;
