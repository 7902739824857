import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Base from "../views/partners/PartnerManagement";
import Partner from "../views/partners/profile";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/partners/partner-management"
      exact
      render={internalProps => (
        <Base {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/partners/partner-management/:id"
      render={internalProps => (
        <Partner {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/partners/partner-management" />} />
  </Switch>
);

export default Routes;
