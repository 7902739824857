import React from "react";
import { NavLink } from "react-router-dom";
import GlobalSearch from "../modals/GlobalSearch";

class SidebarLayout extends React.Component {
  constructor() {
    super();
    this.state = {
      searching: false
    };
  }
  render() {
    return (
      <div className="sidebar-layout-container">
        {this.state.searching ? (
          <GlobalSearch placeholder={this.props.searchPlaceholder ? this.props.searchPlaceholder : "Search..."} onSearch={this.props.onSearch} onExit={() => this.setState({ searching: false })} />
        ) : null}
        <div className="sidebar">
          <div className="logo">
            <img
              alt="logo"
              src="https://green-cdn.fra1.digitaloceanspaces.com/branding/logo-r-white.svg"
              height={22}
              className="sidebar-margin"
            />
            <span className="ui-title-one ui-title-sub">
              {this.props.sidebarTitle}
            </span>
          </div>
          <ul className="navigation-options">
            {this.props.navigationOptions.map(no => (
              <li>
                <NavLink to={no.link}>
                  <i className={`fad fa-${no.icon}`} />
                  <p className="ui-body">{no.text}</p>
                </NavLink>
                {no.subNavigation ? (
                  <div className="sub-navigation-options">
                    {no.subNavigation.map(sno => (
                      <NavLink exact to={sno.link}>
                        <i className={`fad fa-${sno.icon}`} />
                        <p className="ui-body">{sno.text}</p>
                      </NavLink>
                    ))}
                  </div>
                ) : null}
              </li>
            ))}
          </ul>
          <div className="navigation-options">
            <NavLink exact to={"/portal"}>
              <i className={`fad fa-globe-europe`} />
              <p className="ui-body">Back to Studio</p>
            </NavLink>
            <NavLink
              exact
              to={""}
              onClick={() => {
                localStorage.removeItem("apollo_key");
                window.location.reload();
              }}
            >
              <i className={`fad fa-sign-out`} />
              <p className="ui-body">Sign Out</p>
            </NavLink>
          </div>
        </div>
        <div className="navigation">
          <h1
            className="ui-title-three"
            style={{ textTransform: "capitalize" }}
          >
            {this.props.routeTitle}
          </h1>
          {this.props.searchDisabled ? null :
            <div className="search">
              <i className="far fa-search" />
              <input
                className="ui-input"
                placeholder={"Search..."}
                onClick={() => this.setState({ searching: true })}
              />
            </div>
          }
        </div>
        <div className="routes col-1">
          <div className="container-medium">
            <div className="col-start-2">{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default SidebarLayout;
