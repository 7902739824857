import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Table from "../../../components/analytical/Table";
import Loader from "../../../components/generics/Loader";
import GenericConfirm from "../../../modals/GenericConfirm";
import moment from "moment";
import {
  PieChart,
  Pie,
  Legend,
  LineChart,
  Line,
  Tooltip,
  ResponsiveContainer,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class SalesTrends extends React.Component {
  constructor() {
    super();
    this.state = {
      sales: null,
      customers: null,
      syncModal: false,
      startDate: moment().startOf("month"),
      endDate: moment().endOf("day"),
      channel: "overall",
      loading: true
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData(disableCache) {
    this.setState(
      {
        loading: true
      },
      async () => {
        const customers = await this.props.api.get(
          `/customers/channels?interval=date&start=${this.state.startDate.format("YYYY-MM-DDTHH:mm")}&end=${this.state.endDate.format(
            "YYYY-MM-DDT23:59"
          )}${this.state.liveOnly ? "&liveOnly=true" : ""}${disableCache ? "&resetCache=true" : ""}`
        );
        console.log(customers.data.data);
        this.setState({
          customers: customers.data.data,
          intervalStatic: this.state.interval,
          loading: false
        });
      }
    );
  }
  get channel(){
    if(this.state.channel === "overall"){ 
      return this.state.customers.overall;
    } else {
      return this.state.customers.channels[this.state.channel];
    }
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        {this.state.syncModal ? (
          <GenericConfirm
            title="Sync live customers?"
            subTitle="This will reload the cache."
            body={
              <>
                Once confirmed this will fetch all live stats from the database.
                <br />
                <br />
                The initial load may take considerably longer.
              </>
            }
            onExit={confirm => {
              if (confirm) {
                this.setState(
                  {
                    customers: null,
                    loading: true,
                    syncModal: false
                  },
                  async () => {
                    this.getData(true);
                  }
                );
              } else {
                this.setState({
                  syncModal: false
                });
              }
            }}
          />
        ) : null}
        <button
          className="ui-btn sync-button"
          onClick={() => this.setState({ syncModal: true })}
        >
          <i className="fad fa-sync" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto auto auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Start date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ startDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="Start date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">End date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ endDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="End date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">Date basis</p>
                <select
                  className="ui-select"
                  value={this.state.liveOnly ? "live" : "sale"}
                  onChange={e =>
                    this.setState({ liveOnly: e.currentTarget.value === "live" })
                  }
                >
                  <option value="sale">Sale date</option>
                  <option value="live">Live date</option>
                </select>
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-1 gap-10">
        <div className="margin-bottom-20">
                <select
                  style={{maxWidth: "300px"}}
                  className="ui-select"
                  value={this.state.channel}
                  onChange={e =>
                    this.setState({ channel: e.currentTarget.value })
                  }
                >
                  <option value="overall">All channels</option>
                  {
                    this.state.customers ? Object.keys(this.state.customers.channels).map(x => <option value={x}>{x}</option>) : null
                  }
                </select>
              </div>
        <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-2 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Total Customers"
                  figure={this.channel.total.toLocaleString()}
                />
                <Stat
                  label="Favourable Tariff"
                  figure={Object.keys(this.channel.tariffs).sort((a,b) => {
                    const tariffs = this.channel.tariffs;
                    return (tariffs[b].electric + tariffs[b].gas + tariffs[b].dual) - (tariffs[a].electric + tariffs[a].gas + tariffs[a].dual)
                  })[0]}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">{this.state.liveOnly ? "Customers" : "Sales"} per day</p>
          <Card style={{ minHeight: 400 }}>
          {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <LineChart
                    data={Object.keys(this.channel.interval)
                      .sort((a, b) => a - b)
                      .map(x => ({
                        name: moment(x).format("DD/MM/YYYY"),
                        [this.state.liveOnly ? "Customers" : "Sales"]: this.channel.interval[x]
                      }))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={30}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey={this.state.liveOnly ? "Customers" : "Sales"}
                      stroke={colors[0]}
                      // type="basis"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Tariff split</p>
          <Card style={{ minHeight: 400 }}>
          {!this.state.loading ? (
                <div className="padding-15">
                  <ResponsiveContainer>
                  <BarChart
                    data={Object.keys(this.channel.tariffs).reduce((a,x) => ([...a,{
                      name: x,
                      value: Object.values(this.channel.tariffs[x]).reduce((a,x) => a += x, 0)
                    }]),[]).sort((a,b) => b.value - a.value)}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={30}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="value"
                      fill={colors[1]}
                    />
                  </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Fuel split</p>
          <Card style={{ minHeight: 400 }}>
          {!this.state.loading ? (
                <div className="padding-15">
                  <ResponsiveContainer>
                  <BarChart
                    data={["dual","electric","gas"].reduce((a,x) => ([...a, {
                      name: x,
                      value: Object.keys(this.channel.tariffs).reduce((_a,_x) => {
                        console.log(this.channel.tariffs[_x][x]);
                        return _a += this.channel.tariffs[_x][x];
                      }, 0) 
                    }]), []).sort((a,b) => b.value - a.value)}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={30}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="value"
                      fill={colors[1]}
                    />
                  </BarChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
          </Card>
        </div>
      </div>
    );
  }
}

export default SalesTrends;
