import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";

class UpsertUser extends React.Component {
  constructor(props) {
    super(props);
    if (props.user) {
      this.state = {
        ...props.user,
        password: "",
        new: false
      };
    } else {
      this.state = {
        name: "",
        email: "",
        adminAccess: false,
        salesAccess: false,
        supportAccess: false,
        billingAccess: false,
        dataAccess: false,
        smartAccess: false,
        energyAccess: false,
        reportingAccess: false,
        partnersAccess: false,
        active: true,
        new: true
      };
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  saveUser = async e => {
    e.preventDefault();
    if (this.state.new) {
      await this.props.api.post("users", this.state);
    } else {
      const updates = this.state;
      if(!updates.password) delete updates.password;
      await this.props.api.patch(`users/${this.state.id}`, updates);
    }
    this.props.onExit(true);
  };
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card style={{ minWidth: 500, minHeight: 300 }}>
            <form className="padding-30 gap-20 col-1" onSubmit={this.saveUser}>
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">
                  {!this.state.new ? "Modify a user" : "Create a user"}
                </h1>
                <h2 className="ui-title-five ui-title-sub">
                  {!this.state.new
                    ? `You're updating the account for "${this.props.user.name}".`
                    : "Add a user to Green Studio."}
                </h2>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Name</p>
                <input
                  type="text"
                  required
                  className="ui-input"
                  defaultValue={this.state.name}
                  onChange={e => this.setState({ name: e.currentTarget.value, email: `${e.currentTarget.value.toLowerCase().replace(/ /g, ".")}@green.energy` })}
                />
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Email</p>
                <input
                  type="email"
                  required
                  className="ui-input"
                  defaultValue={this.state.email}
                  onChange={e =>
                    this.setState({ email: e.currentTarget.value.toLowerCase() })
                  }
                />
              </div>
              {!this.state.new ? (
                <div className="col-1 gap-10">
                  <p className="ui-label">Password</p>
                  <input
                    type="password"
                    placeholder="New password..."
                    
                    className="ui-input"
                    defaultValue={this.state.password}
                    onChange={e =>
                      this.setState({ password: e.currentTarget.value })
                    }
                  />
                </div>
              ) : null}
              <div className="col-1 gap-10">
                <p className="ui-label">Permissions</p>
                <div className="col-4 gap-5">
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.adminAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ adminAccess: !this.state.adminAccess })
                    }
                  >
                    Admin
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.salesAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ salesAccess: !this.state.salesAccess })
                    }
                  >
                    Sales
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.fieldAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ fieldAccess: !this.state.fieldAccess })
                    }
                  >
                    Field
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.supportAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({
                        supportAccess: !this.state.supportAccess
                      })
                    }
                  >
                    Support
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.dataAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ dataAccess: !this.state.dataAccess })
                    }
                  >
                    Data
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.smartAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ smartAccess: !this.state.smartAccess })
                    }
                  >
                    Smart
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.billingAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ billingAccess: !this.state.billingAccess })
                    }
                  >
                    Billing
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.paymentsAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ paymentsAccess: !this.state.paymentsAccess })
                    }
                  >
                    Payments
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.reportingAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ reportingAccess: !this.state.reportingAccess })
                    }
                  >
                    Reporting
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.energyAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ energyAccess: !this.state.energyAccess })
                    }
                  >
                    Energy
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.partnersAccess ? "-secondary" : ""
                    } ui-btn-compact margin-right-10`}
                    onClick={() =>
                      this.setState({ partnersAccess: !this.state.partnersAccess })
                    }
                  >
                    Partners
                  </button>
                </div>
              </div>
              <div className="col-1 gap-10">
                <p className="ui-label">Blocked</p>
                <div>
                  <button
                    type="button"
                    className={`ui-btn${
                      this.state.active ? "" : "-secondary"
                    } ui-btn-compact margin-right-10`}
                    onClick={() => this.setState({ active: false })}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    className={`ui-btn${
                      !this.state.active ? "" : "-secondary"
                    } ui-btn-compact`}
                    onClick={() => this.setState({ active: true })}
                  >
                    No
                  </button>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  className="ui-btn margin-top-20 margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary margin-top-20">
                  {!this.state.new ? "Modify User" : "Create user"}
                </button>
              </div>
            </form>
          </Card>
        </div>
      </Modal>
    );
  }
}

export default UpsertUser;
