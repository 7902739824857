import React from "react";
import { NavLink } from "react-router-dom";
import Card from "../components/generics/Card";
import Modal from "../components/generics/Modal";
import Loader from "../components/generics/Loader";

class GlobalSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      query: "",
      results: null,
      loading: false
    };
    this.input = React.createRef();
  }
  componentDidMount() {
    this.input.focus();
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit();
    }
  };
  submitSearch = async e => {
    e.preventDefault();
    this.setState({
      results: null,
      loading: true
    }, async () => {
      const results = await this.props.onSearch(
        this.state.query
      );
      this.setState({ results, loading: false });
    });
  }
  render() {
    return (
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={this.handleExit}
        >
          <Card
            className={`global-search ${
              this.state.query.length ? "searching" : ""
              }`}
          >
            <form className="global-search-input" onSubmit={this.submitSearch}>
              <input
                className="ui-input"
                placeholder={this.props.placeholder}
                ref={ref => (this.input = ref)}
                onChange={e => {
                  const query = e.currentTarget.value.toLowerCase();
                  this.setState(
                    {
                      query
                    });
                }}
              />
              <button className="ui-btn-primary"><i className="far fa-search" /></button>
            </form>
            {this.state.query.length ? (
              !this.state.loading ? (
                <div className="global-search-results">
                  {this.state.results && this.state.results.length ? (
                    this.state.results.map(x => (
                      <NavLink
                        to={x.path}
                        onClick={() => this.props.onExit()}
                        className="ui-body global-search-result"
                      >
                        <p className="ui-title-five">{x.title}</p>
                        <p className="ui-body">{x.content}</p>
                      </NavLink>
                    ))
                  ) : (
                      <p className="ui-body" style={{ textAlign: "center" }}>
                        {
                          this.state.results ? "Nothing found" : "Click search to load results"
                        }
                      </p>
                    )}
                </div>
              ) : (
                  <div
                    className="grid-center-center"
                    style={{ gridTemplateRows: "unset" }}
                  >
                    <Loader />
                  </div>
                )
            ) : null}
          </Card>
        </div>
      </Modal>
    );
  }
}

export default GlobalSearch;
