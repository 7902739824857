/* global google */
import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  InfoWindow,
  Circle
} from "react-google-maps";
import HeatmapLayer from "react-google-maps/lib/components/visualization/HeatmapLayer";

const mapThemes = {
  dark: [
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36
        },
        {
          color: "#000000"
        },
        {
          lightness: 40
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on"
        },
        {
          color: "#000000"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 17
        },
        {
          weight: 1.2
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 21
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 29
        },
        {
          weight: 0.2
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 18
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 19
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#000000"
        },
        {
          lightness: 17
        }
      ]
    }
  ],
  light: [
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#e9e9e9"
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ffffff"
        },
        {
          lightness: 17
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#ffffff"
        },
        {
          lightness: 29
        },
        {
          weight: 0.2
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        },
        {
          lightness: 18
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        },
        {
          lightness: 21
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#dedede"
        },
        {
          lightness: 21
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "on"
        },
        {
          color: "#ffffff"
        },
        {
          lightness: 16
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: 36
        },
        {
          color: "#333333"
        },
        {
          lightness: 40
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "transit",
      elementType: "geometry",
      stylers: [
        {
          color: "#f2f2f2"
        },
        {
          lightness: 19
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#fefefe"
        },
        {
          lightness: 20
        }
      ]
    },
    {
      featureType: "administrative",
      elementType: "geometry.stroke",
      stylers: [
        {
          color: "#fefefe"
        },
        {
          lightness: 17
        },
        {
          weight: 1.2
        }
      ]
    }
  ],
  darkblue: [
    {
      stylers: [
        {
          hue: "#ff1a00"
        },
        {
          invert_lightness: true
        },
        {
          saturation: -100
        },
        {
          lightness: 33
        },
        {
          gamma: 0.5
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#191b22"
        }
      ]
    }
  ]
};

const Map = withScriptjs(
  withGoogleMap(
    class extends React.Component {
      constructor(props) {
        super(props);
        this.props = props;
        this.mapReference = React.createRef();
      }
      componentDidMount() {
        const bounds = new window.google.maps.LatLngBounds();
        for (var i = 0; i < this.props.markers.length; i++) {
          bounds.extend(this.props.markers[i]);
        }

        // this.mapReference.current.props.children[0].setIcon({
        //   url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        // });

        this.mapReference.current.fitBounds(bounds);
      }
      render() {
        return (
          <GoogleMap
            ref={this.mapReference}
            options={{
              fullscreenControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              styles:
                this.props.user.theme === "dark"
                  ? mapThemes.darkblue
                  : mapThemes.light
            }}
          >
            {this.props.heatmap ? (
              <HeatmapLayer
                data={this.props.markers.reduce((a, x) => {
                  for (let i = 0; i < x.weight; i++) {
                    a.push(new google.maps.LatLng(x.lat, x.lng));
                  }
                  return a;
                }, [])}
                options={{ radius: 12 }}
              />
            ) : (
                this.props.markers.map((x, i) => (
                  <CustomMarker infoHeading={x.infoHeading} infoBody={x.infoBody} lat={x.lat} lng={x.lng} colour={x.colour} scale={x.scale} i={x.i} click={x.onClick} />
                ))
              )}
            {
              this.props.geofence ? <Marker icon={{
                path: `M8.3,1.5c3.7-1.2,8.1-0.2,10.9,2.6c1.4,1.4,2.4,3.1,2.9,5c0.4,1.8,0.4,3.8-0.2,5.6c-0.3,0.8-0.7,1.5-1,2.2
                c-0.9,1.8-2,3.5-3.1,5.1c-1.5,2.3-3,4.8-4.1,7.3c-0.8,1.8-1.5,3.7-1.9,5.7c-0.7-2.9-1.8-5.7-3.3-8.4c-1.1-2.1-2.5-4.1-3.8-6.1
                C4,19.7,3.5,18.8,3,17.9c-0.6-1-1.2-2-1.5-3.1c-0.7-1.9-0.7-4-0.2-5.9C2.1,5.4,4.9,2.5,8.3,1.5 M11,6.8C9.5,7,8.3,7.9,7.6,9.2
                c-0.6,1.1-0.7,2.4-0.2,3.6c0.6,1.6,2,2.8,3.7,2.9c1.1,0.1,2.2-0.1,3-0.7c0.8-0.5,1.4-1.4,1.7-2.3c0.3-1,0.3-2.2-0.1-3.2
                c-0.4-0.8-1-1.6-1.8-2.1C13.1,6.9,12,6.7,11,6.8z`,
                fillColor: "#37A9E0",
                fillOpacity: 1,
                strokeWeight: 1.5,
                strokeColor: "white",
                scaledSize: new window.google.maps.Size(1, 1),
                anchor: new window.google.maps.Point(10, 30)
              }} position={{ lat: this.props.geofence.lat, lng: this.props.geofence.lng }} onMouseOver=
                {this.handleMouseOver} onMouseOut={this.handleMouseExit}><Circle defaultCenter={{
                  lat: this.props.geofence.lat,
                  lng: this.props.geofence.lng
                }}
                  radius={this.props.geofence.range * 1609.34} options={{
                    strokeColor: '#4E59A3',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#4E59A3',
                    fillOpacity: 0.35
                  }} /></Marker> : null
            }
          </GoogleMap>
        );
      }
    }
  )
);

class CustomMarker extends React.Component {
  state = {
    showInfoWindow: false
  };
  handleMouseOver = e => {
    this.setState({
      showInfoWindow: true
    });
  };
  handleMouseExit = e => {
    this.setState({
      showInfoWindow: false
    });
  };
  render() {
    const { showInfoWindow } = this.state;
    const { infoHeading, infoBody, lat, lng, colour, scale, i, click } = this.props;
    return (
      <Marker icon={{
        path: `M8.3,1.5c3.7-1.2,8.1-0.2,10.9,2.6c1.4,1.4,2.4,3.1,2.9,5c0.4,1.8,0.4,3.8-0.2,5.6c-0.3,0.8-0.7,1.5-1,2.2
        c-0.9,1.8-2,3.5-3.1,5.1c-1.5,2.3-3,4.8-4.1,7.3c-0.8,1.8-1.5,3.7-1.9,5.7c-0.7-2.9-1.8-5.7-3.3-8.4c-1.1-2.1-2.5-4.1-3.8-6.1
        C4,19.7,3.5,18.8,3,17.9c-0.6-1-1.2-2-1.5-3.1c-0.7-1.9-0.7-4-0.2-5.9C2.1,5.4,4.9,2.5,8.3,1.5 M11,6.8C9.5,7,8.3,7.9,7.6,9.2
        c-0.6,1.1-0.7,2.4-0.2,3.6c0.6,1.6,2,2.8,3.7,2.9c1.1,0.1,2.2-0.1,3-0.7c0.8-0.5,1.4-1.4,1.7-2.3c0.3-1,0.3-2.2-0.1-3.2
        c-0.4-0.8-1-1.6-1.8-2.1C13.1,6.9,12,6.7,11,6.8z`,
        fillColor: colour ? colour : "#FF0000",
        fillOpacity: 1,
        strokeWeight: 1.5,
        strokeColor: "white",
        scale: scale ? scale : 1,
        scaledSize: new window.google.maps.Size(1, 1),
        anchor: new window.google.maps.Point(10, 30)
      }} onClick={() => click ? click(i) : null} position={{ lat, lng }} onMouseOver=
        {this.handleMouseOver} onMouseOut={this.handleMouseExit}>
        {showInfoWindow && (
          <InfoWindow>
            <>
              <h4>{infoHeading}</h4>
              <p>{infoBody}</p>
            </>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

const MapContainer = props => (
  <Map
    markers={props.markers}
    heatmap={!props.showMarkers}
    user={props.user}
    geofence={props.geofence ? props.geofenceDetails : null}
    onMarkerClick={props.onMarkerClick}
    loadingElement={<div style={{ height: `100%` }} />}
    containerElement={<div style={{ height: `100%` }} />}
    mapElement={<div style={{ height: `100%` }} />}
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBkt8tUbv0AYexKtPYpxuPaeqKYYdS0umY&v=3.exp&libraries=geometry,drawing,places,visualization"
  />
);

export default MapContainer;
