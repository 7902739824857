import React from "react";
import Card from "../../components/generics/Card";
import Table from "../../components/analytical/Table";
import CardButton from "../../components/interactive/CardButton";
import moment from "moment";
import UpsertUser from "../../modals/UpsertUser";

class UserManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      users: null,
      upsertUserModal: false,
      userInScope: false
    };
  }
  componentDidMount() {
    this.getUsers();
  }
  async getUsers() {
    const res = await this.props.api.get("users");
    this.setState(
      {
        users: res.data.data.filter(x => x.active).sort((a,b) => a.name.localeCompare(b.name))
      });
  }
  render() {
    return (
      <div className="col-1 gap-50">
        {this.state.upsertUserModal ? <UpsertUser user={this.state.userInScope} api={this.props.api} onExit={reload => {
          if(reload) {
            this.setState({
              users: null
            });
            this.getUsers();
          }
          this.setState({upsertUserModal: false, userInScope: false});
        }}/> : null}
        <div className="col-1 gap-10">
          <p className="ui-label">Quick actions</p>
          <div className="col-2 gap-50">
            <CardButton
              title="Create a new user"
              colour="green"
              subTitle="Add a new user to Green Studio"
              icon="user"
              onClick={() => this.setState({upsertUserModal: true, userInScope: false})}
            />
          </div>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">User accounts</p>
          <Card
            loading={this.state.users === null}
            style={{ height: 500 }}
            className="padding-20"
          >
            {this.state.users ? <Table compact onClick={i => this.setState({upsertUserModal: true, userInScope: this.state.users[i]})} data={this.state.users.map(x => ({
              "Name": x.name,
              "Permissions": `${[{name:"Admin", access: x.adminAccess},{name:"Field", access: x.fieldAccess},{name:"Sales", access: x.salesAccess},{name:"Support", access: x.supportAccess},{name:"Data", access: x.dataAccess},{name:"Smart", access: x.smartAccess},{name:"Payments", access: x.paymentsAccess},{name:"Billing", access: x.billingAccess}].filter(x => x.access).map(x => x.name).join(", ")}` || "None set",
              "Last Updated": moment(x.updatedAt).format("DD/MM/YYYY HH:mm"),
              "Creation Date": moment(x.createdAt).format("DD/MM/YYYY"),
              "Blocked": !x.active
            }))}/> : null}
          </Card>
        </div>
      </div>
    );
  }
}

export default UserManagement;
