import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Table from "../../components/analytical/Table";
import GenericConfirm from "../../modals/GenericConfirm";
import moment from "moment";
import abbreviate from "number-abbreviate";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Legend,
  LineChart,
} from "recharts";
import downloadFile from "../../components/helpers/downloadFile";
import { json2csv } from "json-2-csv";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b",
];

class DisputeAgeing extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      downloadPending: false,
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          let res = await this.props.api.get(`/billing/dispute-ageing`);
          console.log(res.data.data);
          this.setState({
            loading: false,
            data: res.data.data.map(x => ({ ...x, billStopDate: moment(x.billStopDate) })),
          });
        } catch (e) {
          console.log(e);
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  downloadData = () => {
    json2csv(this.state.data.map(x => ({...x, billStopDate: x.billStopDate.format("DD/MM/YYYY")})), (err, csv) => {
      downloadFile(
        `dispute_ageing_${moment().format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  weeklyBreakdown = data => {
    const res = [];
    for (let node of data) {
      const weeksFromNow = moment().diff(node.billStopDate, "week");
      const weekKey = !weeksFromNow ? "Within the week" : `${weeksFromNow} week(s) ago`;
      const inx = res.findIndex(y => y.name === weekKey);
      if (inx > -1) {
        res[inx].Disputes++;
      } else {
        res.push({
          name: weekKey,
          key: weeksFromNow,
          Disputes: 1
        })
      }
    }
    return res.sort((a,b) => b.key - a.key);
  }
  renderDashboard = data => {
    return <>
      <div className="col-1 gap-10">
        <p
          className="ui-title-two margin-bottom-20"
          style={{ textTransform: "capitalize" }}
        >
          {data[0].commodity === "E" ? "Electric" : "Gas"}
        </p>
        <Card style={{ minHeight: 120 }}>
          <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
            <Stat
              compact
              figureColour={"blue"}
              label={`Total Disputes`}
              figure={data.length}
            />
            <Stat
              compact
              figureColour={"blue"}
              label={`Most Disputes`}
              figure={data.reduce((a, x) => {
                const inx = a.findIndex(y => y.prevSupplier === x.prevSupplier);
                if (inx > -1) {
                  a[inx].total++;
                } else {
                  a.push({
                    prevSupplier: x.prevSupplier,
                    total: 1
                  })
                }
                return a;
              }, []).sort((a, b) => b.total - a.total)[0].prevSupplier}
            />
            <Stat
              compact
              figureColour={"blue"}
              label={`Total Over 30 Days`}
              figure={`${(
                data.filter(x => moment().subtract(30, "days").isBefore(x.billStopDate)).length / data.length * 100
              ).toFixed(2)}%`}
            />
          </div>
        </Card>
        <div className="col-1 gap-30 margin-top-30">
          <div className="col-1 gap-10">
            <p className="ui-label">Supplier Breakdown</p>
            <Card style={{ height: 400 }} className="padding-20">
              <Table data={data.reduce((a, x) => {
                let inx = a.findIndex(y => y.prevSupplier === x.prevSupplier);
                if (inx > -1) {
                  a[inx].total++;
                } else {
                  a.push({
                    prevSupplier: x.prevSupplier,
                    total: 1
                  })
                }
                return a;
              }, []).sort((a, b) => b.total - a.total).map(x => ({
                Supplier: x.prevSupplier,
                "Total Disputed Reads": x.total
              }))} />
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Weekly Breakdown</p>
            <Card style={{ height: 400 }} className="padding-20">
              <ResponsiveContainer>
                <BarChart data={this.weeklyBreakdown(data)}>
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={60}
                    type="number"
                  // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border"),
                    }}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"Disputes"}
                    fill={colors[2]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </div>
        </div>
      </div>
    </>
  }
  render() {
    return this.state.error ? (
      <Card
      message="Contact support to reload"
      style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
      messageIcon="sync"
    />
    ) : this.state.loading ? null : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={this.downloadData}>
          <i
            className={`fad fa-download`}
          />
        </button>
        {this.renderDashboard(this.state.data.filter(x => x.commodity === "E"))}
        {this.renderDashboard(this.state.data.filter(x => x.commodity === "G"))}
      </div>
    );
  }
}

export default DisputeAgeing;
