import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";
import Loader from "../components/generics/Loader";
import GenericMessage from "./GenericMessage";
import moment from "moment";
import GenericConfirm from "./GenericConfirm";

class CustomerFunds extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmModal: false,
      successModal: false,
      error: null,
      loading: false,
      data: {
        cardNumber: "",
        expMonth: "1",
        expYear: moment().format("YYYY"),
        cvc: "",
        amount: 0,
        receiptEmail: props.email
      }
    };
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  };
  formatCardNumber(value) {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  }
  submitPayment = e => {
    e.preventDefault();
    this.setState({
      confirmModal: true
    });
  };
  async processPayment() {
    this.setState({ loading: true }, async () => {
      try {
        const res = await this.props.api.post(
          `/customers/${this.props.accountNumber}/funds`,
          { ...this.state.data, amount: Math.round(this.state.data.amount * 100) }
        );
        this.setState({ successModal: true });
      } catch (e) {
        this.setState({ error: e.response.data.errors[0], loading: false });
      }
    });
  }
  render() {
    return (
      <>
      {this.state.successModal ? (
          <GenericMessage
            title={`Successfully added £${this.state.data.amount.toFixed(2)}`}
            subTitle={`The customer will get an email shortly.`}
            body={
              <>
                This will take a few days to appear on the customers account.
              </>
            }
            cancelText="Close"
            onExit={() => {
              this.setState({ successModal: false }, () => {
                this.props.onExit(false);
              });
            }}
          />
        ) : null}
        {this.state.confirmModal ? (
          <GenericConfirm
            title={`Add £${this.state.data.amount.toFixed(2)}?`}
            subTitle={`Just to confirm before proceeding.`}
            body={
              <>
                Once confirmed this payment will be taken from the card provided
                and will
                <br />
                show on the account ({this.props.accountNumber}) within the next
                few days.
              </>
            }
            onExit={confirm => {
              this.setState({ confirmModal: false }, () => {
                if (confirm) this.processPayment();
              });
            }}
          />
        ) : null}
        <Modal>
          <div
            className="exitable animated duration-2 fadeIn"
            onClick={() => this.handleExit}
          >
            <Card style={{ minWidth: 500, backgroundColor: "var(--ui-bg)" }}>
              {this.state.loading ? (
                <div className="grid-center-center padding-30">
                  <Loader />
                </div>
              ) : (
                <div className="padding-30 gap-20 col-1">
                  <div className="col-1 gap-5 margin-bottom-20">
                    <h1 className="ui-title-three">Add funds</h1>
                    <h2 className="ui-title-five ui-title-sub">
                      Quickly add funds for the customer by the phone.
                    </h2>
                  </div>
                  <form className="gap-20 col-1" onSubmit={this.submitPayment}>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Amount (£)</p>
                      <input
                        required
                        type="number"
                        className="ui-input"
                        value={this.state.data.amount}
                        onChange={e =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              amount:
                                parseFloat(e.currentTarget.value) > 10000
                                  ? 10000
                                  : parseFloat(e.currentTarget.value)
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Card Number</p>
                      <input
                        required
                        type="text"
                        className="ui-input"
                        value={this.formatCardNumber(
                          this.state.data.cardNumber
                        )}
                        onChange={e =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              cardNumber: e.currentTarget.value.substr(0, 19)
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-2 gap-30">
                      <div className="col-1 gap-10">
                        <p className="ui-label">Expiry Month</p>
                        <select
                          required
                          type="number"
                          className="ui-select"
                          value={this.state.data.expMonth}
                          onChange={e =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                expMonth: e.currentTarget.value
                              }
                            })
                          }
                        >
                          {[...Array(12).keys()].map((x, i) => (
                            <option value={i + 1}>{i + 1}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-1 gap-10">
                        <p className="ui-label">Expiry Year</p>
                        <select
                          required
                          type="number"
                          className="ui-select"
                          value={this.state.data.expYear}
                          onChange={e =>
                            this.setState({
                              data: {
                                ...this.state.data,
                                expYear: e.currentTarget.value
                              }
                            })
                          }
                        >
                          {[...Array(10).keys()].map((x, i) => (
                            <option
                              value={moment()
                                .add(i, "year")
                                .format("YYYY")}
                            >
                              {moment()
                                .add(i, "year")
                                .format("YYYY")}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">CVC</p>
                      <input
                        required
                        type="password"
                        className="ui-input"
                        value={this.state.data.cvc}
                        onChange={e =>
                          this.setState({
                            data: {
                              ...this.state.data,
                              cvc: e.currentTarget.value.substr(0, 3)
                            }
                          })
                        }
                      />
                    </div>
                    <div className="col-1 gap-10">
                      <p className="ui-label">Email receipt</p>
                      <input
                        type="email"
                        className="ui-input"
                        defaultValue={this.state.data.receiptEmail}
                        disabled={true}
                      />
                    </div>
                    <div>
                      {this.state.error ? (
                        <p
                          className="bg-orange colour-white padding-left-10 padding-right-10 padding-top-5 padding-bottom-5 ui-body"
                          style={{ borderRadius: 8 }}
                        >
                          {this.state.error}
                        </p>
                      ) : null}
                      <button
                        type="button"
                        className="ui-btn margin-top-20 margin-right-20"
                        onClick={() => this.props.onExit()}
                      >
                        Cancel
                      </button>
                      <button className="ui-btn-primary margin-top-20">
                        {"Confirm Payment"}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </Card>
          </div>
        </Modal>
      </>
    );
  }
}

export default CustomerFunds;
