import React from "react";
import Card from "../../../components/generics/Card";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import moment from 'moment';

const colors = ["#30AF5F", "#4E59A3", "#37A9E0", "#E67F25", "#16a085", "#27ae60", "#2980b9", "#8e44ad", "#f39c12", "#d35400", "#c0392b"];

class PlatformUsage extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      split: null,
      logins: null
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    const platformSplit = await this.props.api.get("/customers/platforms");
    const loginSplit = await this.props.api.get("/customers/platforms/logins");
    this.setState({
      split: platformSplit.data.data,
      logins: loginSplit.data.data,
      loading: false
    });
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-2 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Platform Utilisation"
                  figure={`${Math.abs(this.state.split.none - 100).toFixed(
                    2
                  )}%`}
                />
                <Stat
                  label="Mobile and Web Utilisation"
                  figure={`${this.state.split.platform.toFixed(2)}%`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Platform split</p>
          <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-top-15 padding-bottom-30">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={Object.keys(this.state.split).map(x => ({
                        name: x,
                        value: this.state.split[x]
                      }))}
                      strokeWidth={0}
                      // paddingAngle={5}
                      innerRadius={80}
                    >
                      {Object.keys(this.state.split)
                        .map(x => ({
                          name: x,
                          value: this.state.split[x]
                        }))
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                    </Pie>

                    <Tooltip />
                    <Legend
                      payload={Object.keys(this.state.split)
                        .map(x => ({
                          name: x,
                          value: this.state.split[x].toFixed(2) + "%"
                        }))
                        .map((item, index) => ({
                          id: item.name,
                          type: "circle",
                          color: colors[index],
                          value: `${item.name} (${item.value})`
                        }))}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Initial login trends</p>
          <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <LineChart
                    data={this.state.logins.mobile
                      .sort((a, b) => a.date - b.date)
                      .map(x => ({
                        name: moment(x.date).format("DD/MM/YYYY"),
                        Web: this.state.logins.web.find(y => y.date === x.date) ? this.state.logins.web.find(y => y.date === x.date).total : 0,
                        Mobile: x.total
                      }))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={45}
                      type="number"
                      domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey="Mobile"
                      stroke={colors[0]}
                      type="basis"
                      dot={false}
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey="Web"
                      stroke={colors[1]}
                      type="basis"
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default PlatformUsage;
