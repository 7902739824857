import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Overview from "../../views/data/site-analytics/Overview";
import ReferrerTrends from "../../views/data/site-analytics/ReferrerTrends";
import FunnelAnalysis from "../../views/data/site-analytics/FunnelAnalysis";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/data/site-analytics/overview"
      render={internalProps => (
        <Overview {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/site-analytics/referrer-trends"
      render={internalProps => (
        <ReferrerTrends {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/site-analytics/funnel-analysis"
      render={internalProps => (
        <FunnelAnalysis {...internalProps} {...props} />
      )}
    />
    <Route render={props => <Redirect to={`/portal/data/site-analytics/overview`} />} />
  </Switch>
);

export default Routes;
