import React from "react";
import CardTile from "../../../components/interactive/CardTile";
import Loader from "../../../components/generics/Loader";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import moment from "moment";
import CustomerCommsActionsModal from "../../../modals/CustomerCommsActions";
import CustomerFundsModal from "../../../modals/CustomerFunds";
import GenericMessage from "../../../modals/GenericMessage";
import Table from "../../../components/analytical/Table";
import { Helmet } from "react-helmet";

class CustomerProfile extends React.Component {
    constructor() {
        super();
        this.state = {
            account: null,
            loading: true,
            commsModal: false,
            fundsModal: false,
            inActiveModal: false
        };
    }
    componentDidMount() {
        this.getAccount();
    }
    componentDidUpdate(oldProps) {
        if (
            this.props.accountNumber !==
            oldProps.accountNumber
        ) {
            this.getAccount();
        }
    }
    async getAccount() {
        this.setState(
            {
                loading: true
            },
            async () => {
                const account = await this.props.api.get(
                    `customers/${this.props.accountNumber}`
                );
                this.setState(
                    {
                        account: account.data.data,
                        isActive: account.data.data.EndDate ? moment(account.data.data.EndDate).isAfter(moment()) ? true : false : true,
                        inActiveModal: account.data.data.EndDate ? moment(account.data.data.EndDate).isAfter(moment()) ? false : true : false,
                        loading: false
                    });
            }
        );
    }
    render() {
        return !this.state.loading ? (
            <div className="col-1 gap-40 margin-bottom-40">
                <Helmet>
                    <title>({this.props.accountNumber}) Overview - Green Studio</title>
                </Helmet>
                {this.state.commsModal ? (
                    <CustomerCommsActionsModal
                        email={this.state.account.ContactEmail}
                        phone={this.state.account.ContactPhone}
                        accountNumber={this.props.accountNumber}
                        api={this.props.api}
                        onExit={() => {
                            this.setState({ commsModal: false });
                        }}
                    />
                ) : null}
                {
                    this.state.inActiveModal ? <GenericMessage
                        title={`Inactive Customer`}
                        subTitle={`Some sections will be disabled.`}
                        body={
                            <>
                                The customer left us on the {moment(this.state.account.EndDate).format("Do MMMM YYYY")} ({moment(this.state.account.EndDate).fromNow()}).
                            </>
                        }
                        cancelText="OK"
                        onExit={() => {
                            this.setState({ inActiveModal: false });
                        }}
                    /> : null
                }
                {this.state.fundsModal ? (
                    <CustomerFundsModal
                        email={this.state.account.ContactEmail}
                        accountNumber={this.props.accountNumber}
                        api={this.props.api}
                        onExit={() => {
                            this.setState({ fundsModal: false });
                        }}
                    />
                ) : null}
                <Card className="col-2 gap-40 padding-30" style={{ minHeight: 120 }}>
                    <Stat
                        label="Account Holder"
                        figureColour="blue"
                        compact
                        figure={this.state.account.CustomerName}
                    />
                    <Stat
                        label="Current Balance"
                        figureColour={this.state.account.SalesLedgerBalance < 0 ? "orange" : "green"}
                        compact
                        figure={`£${this.state.account.SalesLedgerBalance.toFixed(2)}`}
                    />
                    <Stat
                        label="Current Tariff"
                        figureColour="blue"
                        compact
                        figure={this.state.account.ReportingElecCurrentTariff}
                    />
                    {!this.state.isActive ? (
                        <Stat
                            label="End Date"
                            figureColour="orange"
                            compact
                            figure={moment(this.state.account.EndDate).format("Do MMMM YYYY")}
                        />
                    ) : (
                            <Stat
                                label="Member Since"
                                figureColour="blue"
                                compact
                                figure={moment(this.state.account.StartDate).format(
                                    "Do MMMM YYYY"
                                )}
                            />
                        )}
                    <Stat
                        label="Account Type"
                        figureColour="blue"
                        compact
                        figure={this.state.account.AccountType}
                    />
                    <Stat
                        label="Sales Channel"
                        figureColour="blue"
                        compact
                        figure={this.state.account.ReportingSalesChannel}
                    />
                    
                </Card>
                <div className="col-1 gap-10">
                    {this.props.user.paymentsAccess ? <p className="ui-label">Quick actions</p> : null}

                    <div
                        className={`col-2 gap-30`}
                    >
                        {this.props.user.paymentsAccess ? (<>
                            <CardTile
                                title="Take Payment"
                                colour="blue"
                                icon="credit-card"
                                onClick={() => this.setState({ fundsModal: true })}
                            />
                            {this.state.account.ExternalSystemRef ? <CardTile
                                title="Open GoCardless"
                                colour="blue"
                                icon="arrow-right"
                                onClick={() => window.open(`https://manage.gocardless.com/customers/${this.state.account.ExternalSystemRef}`)}
                            /> : null}
                        </>) : null}
                    </div>
                </div>
            </div>
        ) : (
                <div className="grid-center-center">
                    <Loader />
                </div>
            );
    }
}

export default CustomerProfile;
