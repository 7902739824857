import React from "react";

import Routes from "../../routes/Reporting";
import SidebarLayout from "../../layouts/Sidebar";

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SidebarLayout
        onSearch={() => { }}
        sidebarTitle="reporting"
        navigationOptions={[
          {
            icon: "chart-line",
            text: "Finance",
            link: "/portal/reporting/finance/payout-forecasting",
            subNavigation: [
              {
                icon: "receipt",
                text: "Payout Forecasting",
                link: "/portal/reporting/finance/payout-forecasting"
              }
            ]
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Layout;
