import React from "react";

import Routes from "../../routes/Energy";
import SidebarLayout from "../../layouts/Sidebar";

class Layout extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SidebarLayout
        onSearch={() => {}}
        sidebarTitle="energy"
        navigationOptions={[
          
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Layout;
