import React from "react";
import moment from "moment";
import Card from "../../../components/generics/Card";
import {
  PieChart,
  Pie,
  Legend,
  Bar,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import Loader from "../../../components/generics/Loader";
import EnergyRegionMap from "../../../components/interactive/EnergyRegionMap";
import downloadFile from "../../../components/helpers/downloadFile";
import { json2csv } from "json-2-csv";
import Table from "../../../components/analytical/Table";
import Stat from "../../../components/analytical/Stat";
import { mean } from "stats-lite";
import BarChart from "recharts/lib/chart/BarChart";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class GasForecast extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true
    };
  }
  componentDidMount() {
    this.getData();
  }
  downloadScope = async () => {
    json2csv(this.state.data, (err, csv) => {
      downloadFile(
        `customer_exploration_${moment().format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  };
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        const result = await this.props.api.get(
          `/customers/exploration`
        );
        this.setState({
          data: result.data.data.sort((a,b) => b.live_customers - a.live_customers),
          loading: false
        });
      }
    );
  }
  render() {
    return (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={this.downloadScope}>
          <i className="fad fa-download" />
        </button>
          <Card style={{ height: 500 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart
                    data={this.state.data}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="channel" />
                    <YAxis
                      width={50}
                      type="number"
                    
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey="live_customers"
                      fill={colors[1]}
                      dot={false}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
    );
  }
}

export default GasForecast;
