import React from "react";

import Routes from "../../routes/Knowledge";
import SidebarLayout from "../../layouts/Sidebar";

class Knowledge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    }
  }
  render() {
    return (
      <SidebarLayout
        onSearch={q => console.log(q)}
        sidebarTitle="knowledge"
        navigationOptions={[
          {
            icon: "archive",
            text: "Knowledge Base",
            link: "/portal/knowledge/base"
          },
          {
            icon: "file-import",
            text: "Article Management",
            link: "/portal/knowledge/article-management"
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Knowledge;
