import React from "react";

import Routes from "../../routes/Billing";
import SidebarLayout from "../../layouts/Sidebar";

class Billing extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="billing"
        navigationOptions={[
          {
            icon: "badge-percent",
            text: "Billing Dashboard",
            link: "/portal/billing/billing-dashboard"
          },
          {
            icon: "coin",
            text: "Balance Bands",
            link: "/portal/billing/balance-bands"
          },
          {
            icon: "exclamation-triangle",
            text: "Dispute Ageing",
            link: "/portal/billing/dispute-ageing"
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Billing;
