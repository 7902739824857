import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Table from "../../components/analytical/Table";
import GenericConfirm from "../../modals/GenericConfirm";
import moment from "moment";
import abbreviate from "number-abbreviate";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Legend,
  LineChart
} from "recharts";
import downloadFile from "../../components/helpers/downloadFile";
import { json2csv } from 'json-2-csv';

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class TopUpOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          let balances = await this.props.api.get(
            `/billing/balances`
          );
          console.log(balances.data.data)
          this.setState({
            loading: false,
            data: balances.data.data
          });
        } catch (e) {
          console.log(e);
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  downloadInactive = () => {
    json2csv(this.state.data.inactiveAccountNumbers, (err,csv) => {
      downloadFile(
        `inactive_plan_accounts_${moment().format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  render() {
    return this.state.error ? (
      <Card
        message="Contact support to reload"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="sync"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={this.downloadInactive}>
          <i className="fad fa-download" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Overall statistics</p>
          <Card style={{ minHeight: 120 }}>
            {!this.state.loading ? (
              <div className="col-4 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  compact
                  figureColour="blue"
                  label={`Live Accounts`}
                  figure={this.state.data.accounts.toLocaleString()}
                />
                <Stat
                  compact
                  figureColour={this.state.data.balance >= 0 ? "green" : "orange"}
                  label={`Cumulative Balance`}
                  figure={`£${abbreviate(this.state.data.balance, 2)}`}
                />
                <Stat
                  compact
                  figureColour="blue"
                  label={`Debt Percentage`}
                  figure={`${(Object.keys(this.state.data.bands).filter(x => x.indexOf("Debt") > -1).reduce((a,k) => a += this.state.data.bands[k].accounts,0) * 100 / this.state.data.accounts).toFixed(2)}%`}
                />
                <Stat
                  compact
                  figureColour="blue"
                  label={`Inactive Plans`}
                  figure={this.state.data.inactiveAccountNumbers.length.toLocaleString()}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Balance by band</p>
        <Card style={{ height: 450 }}>
          {!this.state.loading ? (
            <div className="padding-15">
              <ResponsiveContainer>
                <BarChart
                  data={Object.keys(this.state.data.bands).map(x => ({
                    key: x,
                    Balance: this.state.data.bands[x].balance,
                    Accounts: this.state.data.bands[x].accounts
                  }))}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="key" />
                  <YAxis
                    width={60}
                    type="number"
                    // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={(value,i,x) => `£${abbreviate(value, 2)}, Accounts: ${x.payload.Accounts}`}
                    // ${JSON.stringify(x.payload.Accounts)}
                  />
                  <Bar
                    isAnimationActive={false}
                    dataKey={"Balance"}
                    fill={colors[1]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Accounts per band</p>
        <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-top-15 padding-bottom-30">
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      isAnimationActive={false}
                      data={Object.keys(this.state.data.bands).map(x => ({
                        name: x,
                        value: this.state.data.bands[x].accounts
                      }))}
                      strokeWidth={0}
                      // paddingAngle={5}
                      innerRadius={80}
                    >
                      {Object.keys(this.state.data.bands).map(x => ({
                        name: x,
                        value: this.state.data.bands[x].accounts
                      }))
                        .map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                    </Pie>

                    <Tooltip />
                    <Legend
                      payload={Object.keys(this.state.data.bands).map(x => ({
                        name: x,
                        value: this.state.data.bands[x].accounts
                      }))
                        .map((item, index) => ({
                          id: item.name,
                          type: "circle",
                          color: colors[index],
                          value: `${item.name}`
                        }))}
                    />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Band overview</p>
            <Card style={{ height: 400 }} className="padding-20">
              {!this.state.loading ? <Table data={Object.keys(this.state.data.bands).map(x => ({
                "Band": x,
                "Balance": `£${this.state.data.bands[x].balance ? this.state.data.bands[x].balance.toLocaleString() : 0}`,
                "Accounts": this.state.data.bands[x].accounts.toLocaleString(),
                "Active Plans": this.state.data.bands[x].activePlans.toLocaleString(),
                "Inactive Plans": this.state.data.bands[x].inactivePlans.toLocaleString()
              }))}/>: (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
      </div>
    );
  }
}

export default TopUpOverview;
