import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Table from "../../components/analytical/Table";
import GenericConfirm from "../../modals/GenericConfirm";
import moment from "moment";
import abbreviate from "number-abbreviate";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell
} from "recharts";
import downloadFile from "../../components/helpers/downloadFile";
import { json2csv } from 'json-2-csv';

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class TopUpOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      channelOverview: null,
      startDate: moment().startOf("day"),
      endDate: moment(),
      interval: "date",
      intervalStatic: "date",
      loading: true,
      error: false
    };
  }
  downloadData = () => {
    json2csv(Object.keys(this.state.data).reduce((a,k) => {
      Object.keys(this.state.data[k]).forEach(x => {
        a.push({
          date: k,
          channel: x,
          amount: this.state.data[k][x]
        })
      });
      return a;
    }, []), (err,csv) => {
      downloadFile(
        `topup_overview_${this.state.startDate.format(
          "YYYYMMDD"
        )}_${this.state.endDate.format(
          "YYYYMMDD"
        )}.csv`,
        "text/csv",
        csv
      );
    });
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          let topups = await this.props.api.get(
            `/billing/topups?start=${this.state.startDate}&end=${this.state.endDate}&interval=${this.state.interval}`
          );
          let channelOverview = [];
          Object.keys(topups.data.data).forEach(interval => {
            Object.keys(topups.data.data[interval]).forEach(channel => {
              let indx = channelOverview.findIndex(x => x.channel === channel);
              if(indx === -1){
                channelOverview.push({
                  channel,
                  amount: topups.data.data[interval][channel]
                });
              } else {
                channelOverview[indx].amount += topups.data.data[interval][channel];
              }
            });
          });
          channelOverview = channelOverview.sort((a,b) => b.amount - a.amount);
          this.setState({
            loading: false,
            data: topups.data.data,
            channelOverview,
            intervalStatic: this.state.interval
          });
        } catch (e) {
          console.log(e);
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  formatInterval(x) {
    switch (this.state.intervalStatic) {
      case "hour":
        return moment()
          .hour(x)
          .format("ha");
      case "date":
        return moment(x).format("DD/MM/YYYY");
      case "month":
        return moment()
          .month(x - 1)
          .format("MMM YYYY");
      case "day":
        return moment()
          .date(x)
          .format("Do");
      case "dayofweek":
        return x;
      case "year":
        return x;
    }
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={this.downloadData}>
          <i className="fad fa-download" />
        </button>
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card>
            <div
              className="padding-20 col-4 gap-20"
              style={{
                alignItems: "end",
                gridTemplateColumns: "auto auto auto max-content"
              }}
            >
              <div>
                <p className="ui-label margin-bottom-10">Start date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.startDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ startDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="Start date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">End date</p>
                <input
                  className="ui-input"
                  defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                  onChange={e =>
                    this.setState({ endDate: moment(e.currentTarget.value) })
                  }
                  type="datetime-local"
                  placeholder="End date"
                />
              </div>
              <div>
                <p className="ui-label margin-bottom-10">Interval</p>
                <select
                  className="ui-select"
                  value={this.state.interval}
                  onChange={e =>
                    this.setState({ interval: e.currentTarget.value })
                  }
                >
                  <option value="date">Date</option>
                  <option value="hour">Hourly</option>
                  <option value="day">Daily</option>
                  <option value="dayofweek">Day of Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                </select>
              </div>
              <div>
                <button
                  className="ui-btn-compact"
                  onClick={() => this.getData()}
                >
                  <i className="far fa-search margin-right-10" />
                  Search
                </button>
              </div>
            </div>
          </Card>
        </div>
        <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-2 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Total Accumulated"
                  figureColour="blue"
                  figure={`£${Object.values(this.state.channelOverview).reduce((a,x) => a+=x.amount,0).toLocaleString()}`}
                />
                <Stat
                  label="Best Channel"
                  figureColour="blue"
                  figure={this.state.channelOverview[Object.keys(this.state.channelOverview)[0]] ? this.state.channelOverview[Object.keys(this.state.channelOverview)[0]].channel : "N/A"}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        <Card style={{ height: 450 }}>
          {!this.state.loading ? (
            <div className="padding-15">
              <ResponsiveContainer>
                <BarChart
                  data={Object.keys(this.state.data)
                    .sort((a, b) => a - b)
                    .map(x => {
                      return {
                        name: this.formatInterval(x),
                        ...this.state.data[x]
                      };
                    })}
                >
                  <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                  <XAxis height={20} dataKey="name" />
                  <YAxis
                    width={40}
                    type="number"
                    // domain={[0, dataMax => dataMax * 1.25]}
                  />
                  <Tooltip
                    cursor={{
                      fill: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--ui-border")
                    }}
                    formatter={value => `£${abbreviate(value, 2)}`}
                  />
                  {this.state.channelOverview.map((x, i) => (
                    <Bar
                      isAnimationActive={false}
                      dataKey={x.channel}
                      fill={colors[i]}
                      // stackId="a"
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </div>
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
        <Card style={{ height: 400 }} className="padding-20">
          {!this.state.loading ? (
            <Table
              data={this.state.channelOverview.map(x => ({
                  Channel: x.channel,
                  Amount: `£${x.amount.toLocaleString()}`
                }))}
            />
          ) : (
            <div className="grid-center-center">
              <Loader />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default TopUpOverview;
