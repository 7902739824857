import React from "react";
import moment from "moment";
import Card from "../../../components/generics/Card";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import axios from "axios";
import GenericConfirm from "../../../modals/GenericConfirm";
import CreatePartnerTariff from "../../../modals/CreatePartnerTariff";

class Partner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      liveTariffList: [],
      confirmDeleteTariffModal: false,
      tariffIdInScope: null,
      newTariffModal: false,
      newTariff: {}
    };
  }
  componentDidMount() {
    this.getPartner();
    this.getTariffs();
    this.props.setTitle("Configuration");
  }
  async getTariffs() {
    let res = await axios.get(`https://api.core.green.energy/tariffs?live=true&gsp=_a`);
    res = res.data.data;
    res = res.filter((x, i) => res.findIndex(y => y.name === x.name) === i);
    this.setState({
      liveTariffList: res
    });
  }
  async getPartner() {
    const res = await this.props.api.get(`partners/${this.props.match.params.id}`);
    if (!res.data.data) {
      this.props.history.push("/portal/partners/partner-management")
    } else {
      this.setState(
        {
          ...res.data.data,
          loaded: true
        });
    }
  }
  async handleLogoChange(e) {
    const file = e.target.files[0];
    console.log(file.type)
    if (file.type === "image/png") {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        this.setState({
          whitelabelLogo: fileReader.result
        })
      }
    }
  }
  async handleBgChange(e) {
    const file = e.target.files[0];
    if (file.type === "image/jpeg" || file.type === "image/png") {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        this.setState({
          whitelabelBackgroundImage: fileReader.result
        })
      }
    }
  }
  savePartner = async (e) => {
    e.preventDefault();
    this.setState({
      loaded: false
    }, async () => {
      const post = this.state;
      await this.props.api.patch(`partners/${this.props.match.params.id}`, post);
      this.setState({
        loaded: true
      });
    });
  }
  render() {
    return <>
      {this.state.newTariffModal ? <CreatePartnerTariff api={this.props.api} partnerId={this.state.id} tariffs={this.state.liveTariffList} onExit={c => {
        if (c) {
          this.setState({
            loaded: false,
            newTariffModal: false
          }, () => this.getPartner());
        } else {
          this.setState({ newTariffModal: false });
        }
      }} /> : null}
      {this.state.confirmDeleteTariffModal ? <GenericConfirm
        title="Confirm tariff deletion"
        subTitle="Are you sure you want to delete this tariff?"
        body={
          <>
            This will immediately remove this tariff from sale.
          </>
        }
        onExit={async confirm => {
          if (confirm) {
            await this.props.api.delete(`partners/tariffs/${this.state.tariffIdInScope}`);
            this.setState({
              tariffs: this.state.tariffs.filter(x => x.id !== this.state.tariffIdInScope),
              tariffIdInScope: null,
              confirmDeleteTariffModal: false
            })
          } else {
            this.setState({
              confirmDeleteTariffModal: false
            });
          }
        }}
      /> : null}
      {
        this.state.loaded ? <form className="col-1 gap-40" onSubmit={this.savePartner}>
          <button className="ui-btn bg-green sync-button" type="submit">
            <i className="fad fa-save" />
          </button>
          <div className="col-1 gap-10">
            <p className="ui-label">Partner Information</p>
            <Card
              className="padding-20"
            >
              <div className="col-3 gap-20">
                <div className="col-1 gap-10">
                  <p className="ui-label">Reference</p>
                  <input
                    type="text"
                    required
                    readOnly
                    disabled
                    className="ui-input"
                    style={{ fontSize: 12 }}
                    value={this.state.reference}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Name</p>
                  <input
                    type="text"
                    required
                    className="ui-input"
                    value={this.state.name}
                    onChange={e => this.setState({ name: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Sales Reference</p>
                  <input
                    type="text"
                    required
                    readOnly
                    disabled
                    className="ui-input"
                    value={this.state.salesChannelCode}
                    maxLength={12}
                    onChange={e => this.setState({ salesChannelCode: e.currentTarget.value.toUpperCase().replace(/ /g, "") })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Website URL</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.url}
                    onChange={e => this.setState({ url: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Account Manager</p>
                  <input
                    type="text"
                    required
                    onChange={e => this.setState({ accountManager: e.currentTarget.value })}
                    className="ui-input"
                    value={this.state.accountManager}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Status</p>
                  <select value={String(this.state.active)} className="ui-select" onChange={e => this.setState({ active: e.currentTarget.value === "true" })}>
                    <option value="true">Active</option>
                    <option value="false">Not Active</option>
                  </select>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Contact Information</p>
            <Card
              className="padding-20"
            >
              <div className="col-3 gap-20">
                <div className="col-1 gap-10">
                  <p className="ui-label">Primary Contact Name</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.primaryContactName}
                    onChange={e => this.setState({ primaryContactName: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Primary Contact Email</p>
                  <input
                    type="email"
                    className="ui-input"
                    value={this.state.primaryContactEmail}
                    onChange={e => this.setState({ primaryContactEmail: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Primary Contact Number</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.primaryContactTelephone}
                    onChange={e => this.setState({ primaryContactTelephone: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Technical Contact Name</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.technicalContactName}
                    onChange={e => this.setState({ technicalContactName: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Technical Contact Email</p>
                  <input
                    type="email"
                    className="ui-input"
                    value={this.state.technicalContactEmail}
                    onChange={e => this.setState({ technicalContactEmail: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Technical Contact Number</p>
                  <input
                    type="text"
                    className="ui-input"
                    value={this.state.technicalContactTelephone}
                    onChange={e => this.setState({ technicalContactTelephone: e.currentTarget.value })}
                  />
                </div>

              </div>
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Reconciliation Details</p>
            <Card
              className="padding-20"
            >
              <div className="col-2 gap-20">
                <div className="col-1 gap-10">
                  <p className="ui-label">Reporting Email</p>
                  <input
                    type="email"
                    className="ui-input"
                    value={this.state.reportingEmail}
                    onChange={e => this.setState({ reportingEmail: e.currentTarget.value })}
                  />
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Reporting Period</p>
                  <select className="ui-select" value={this.state.reportingPeriod} onChange={e => this.setState({ reportingPeriod: e.currentTarget.value })}>
                    <option value="1">1st of each month</option>
                    <option value="15">15th of each month</option>
                    <option value="115">1st and 15th of each month</option>
                  </select>
                </div>

              </div>
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Setup Information <i className="fa fa-key colour-green" style={{ float: "right" }}></i></p>
            <Card
              className="padding-20"
            >
              <div className="col-2 gap-40">
                <div className="col-1 gap-10">
                  <p className="ui-label">Offering</p>
                  <div className="col-3 gap-10">
                    <button
                      type="button"
                      className={`ui-btn${this.state.credit ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ credit: !this.state.credit })
                      }
                    >
                      Credit
                  </button>
                    <button
                      type="button"
                      className={`ui-btn${this.state.prepay ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ prepay: !this.state.prepay })
                      }
                    >
                      Prepay
                  </button>
                    <button
                      type="button"
                      className={`ui-btn${this.state.business ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ business: !this.state.business })
                      }
                    >
                      Business
                  </button>
                  </div>
                </div>
                <div className="col-1 gap-10">
                  <p className="ui-label">Services</p>
                  <div className="col-3 gap-10">
                    <button
                      type="button"
                      className={`ui-btn${this.state.whitelabel ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ whitelabel: !this.state.whitelabel })
                      }
                    >
                      White Label
                  </button>
                    <button
                      type="button"
                      className={`ui-btn${this.state.sftp ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ sftp: !this.state.sftp })
                      }
                    >
                      SFTP
                  </button>
                    <button
                      type="button"
                      className={`ui-btn${this.state.api ? "-secondary" : ""
                        } ui-btn-compact`}
                      onClick={() =>
                        this.setState({ api: !this.state.api })
                      }
                    >
                      API
                  </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          {
            this.state.whitelabel ? <div className="col-1 gap-10">
              <p className="ui-label">White Label Settings <a target="_blank" className="colour-green" href={`https://wl.preview.green?ref=${this.state.reference}`}><i className="fa fa-external-link" style={{ float: "right" }}></i></a></p>
              <Card
                className="padding-20"
              >
                <div className="col-3 gap-20">
                  <div className="col-1 gap-10">
                    <p className="ui-label">URL</p>
                    <input
                      type="text"
                      className="ui-input"
                      value={this.state.whitelabelDomain}
                      onChange={e => this.setState({ whitelabelDomain: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Heading Text</p>
                    <input
                      type="text"
                      className="ui-input"
                      value={this.state.whitelabelHeadingText}
                      onChange={e => this.setState({ whitelabelHeadingText: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Sub Heading Text</p>
                    <input
                      type="text"
                      className="ui-input"
                      value={this.state.whitelabelSubHeadingText}
                      onChange={e => this.setState({ whitelabelSubHeadingText: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Primary Colour</p>
                    <input
                      type="color"
                      className="ui-input"
                      value={this.state.whitelabelPrimaryColour}
                      onChange={e => this.setState({ whitelabelPrimaryColour: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Secondary Colour</p>
                    <input
                      type="color"
                      className="ui-input"
                      value={this.state.whitelabelSecondaryColour}
                      onChange={e => this.setState({ whitelabelSecondaryColour: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Background Colour</p>
                    <input
                      type="color"
                      className="ui-input"
                      value={this.state.whitelabelBackgroundColour}
                      onChange={e => this.setState({ whitelabelBackgroundColour: e.currentTarget.value })}
                    />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Logo (PNG)</p>
                    <label for="logo">
                      <div className="ui-img-preview" style={{ backgroundColor: this.state.whitelabelBackgroundColour ? this.state.whitelabelBackgroundColour : "black" }}>
                        <img src={this.state.whitelabelLogo ? this.state.whitelabelLogo : "https://green.cdn.energy/branding/logo-white.svg"} />
                      </div>
                    </label>
                    <input type="file" id="logo" style={{ display: "none" }} onChange={e => this.handleLogoChange(e)} />
                  </div>
                  <div className="col-1 gap-10">
                    <p className="ui-label">Background Image (PNG/JPG) {this.state.whitelabelBackgroundImage ? <i onClick={() => this.setState({ whitelabelBackgroundImage: null })} className="fa fa-times margin-left-10 colour-orange"></i> : null}</p>
                    <label for="bg">
                      <div className="ui-img-preview" style={{ backgroundColor: this.state.whitelabelBackgroundColour ? this.state.whitelabelBackgroundColour : "black", padding: 0 }}>
                        <img src={this.state.whitelabelBackgroundImage ? this.state.whitelabelBackgroundImage : ""} />
                      </div>
                    </label>
                    <input type="file" id="bg" style={{ display: "none" }} onChange={e => this.handleBgChange(e)} />
                  </div>
                </div>
              </Card>
            </div> : null
          }
          <div className="col-1 gap-10">
            <p className="ui-label">Tariff Allocation<i style={{cursor: "pointer"}} onClick={() => this.setState({ newTariffModal: true })} className="fa fa-plus" style={{ float: "right" }}></i></p>
            <Card
              className="padding-20"
              style={{ height: 300 }}
            >
              <Table compact data={this.state.tariffs.map(x => ({
                "Exact Tariff Name": x.dyballName,
                "Heading Text": x.heading,
                "Sub Heading Text": x.subHeading,
                "Dual Fuel": x.dual,
                "Electric": x.elecOnly,
                "Prepay": x.prepay,
                actions: [{
                  icon: "times",
                  colour: "orange",
                  onClick: () => this.setState({
                    tariffIdInScope: x.id,
                    confirmDeleteTariffModal: true
                  })
                }]
              }))} />
            </Card>
          </div>
          <div></div>
        </form> : <div className="grid-center-center">
            <Loader />
          </div>
      }</>
  }
}

export default Partner;
