import React from "react";

import Routes from "../../routes/Data";
import SidebarLayout from "../../layouts/Sidebar";

class Data extends React.Component {
  constructor() {
    super();
  }
  render() {
    return (
      <SidebarLayout
      searchDisabled={true}
        onSearch={q => console.log(q)}
        routeTitle={this.props.location.pathname.match(/\/([^\/]+)\/?$/)[1].replace(/-/g, " ")}
        sidebarTitle="data"
        navigationOptions={[
          {
            icon: "chart-line",
            text: "Customer Insights",
            link: "/portal/data/customer-insights",
            subNavigation: [
              {
                icon: "calendar",
                text: "Sales Trends",
                link: "/portal/data/customer-insights/sales-trends"
              },
              {
                icon: "poll",
                text: "Channel Analysis",
                link: "/portal/data/customer-insights/channel-analysis"
              },
              {
                icon: "percentage",
                text: "Tariff Split",
                link: "/portal/data/customer-insights/tariff-split"
              },
              {
                icon: "chart-pie",
                text: "Segment Analysis",
                link: "/portal/data/customer-insights/segment-analysis"
              },
              {
                icon: "browser",
                text: "Platform Usage",
                link: "/portal/data/customer-insights/platform-usage"
              },
              {
                icon: "tachometer",
                text: "Meter Types",
                link: "/portal/data/customer-insights/meter-types"
              },
              {
                icon: "history",
                text: "Historical Growth",
                link: "/portal/data/customer-insights/historical-growth"
              },
              {
                icon: "map-marker-alt",
                text: "Regional Insights",
                link: "/portal/data/customer-insights/regional-insights"
              },
              {
                icon: "street-view",
                text: "Postcode Geofencing",
                link: "/portal/data/customer-insights/postcode-geofencing"
              },
              {
                icon: "house-signal",
                text: "Smart Interest",
                link: "/portal/data/customer-insights/smart-interest"
              },
              {
                icon: "brain",
                text: "Supplier Intelligence",
                link: "/portal/data/customer-insights/supplier-intelligence"
              },
              {
                icon: "list-ol",
                text: "URN Lookup",
                link: "/portal/data/customer-insights/urn-lookup"
              },
              {
                icon: "tachometer",
                text: "WAN Lookup",
                link: "/portal/data/customer-insights/wan-lookup"
              }
            ]
          },
          {
            icon: "browser",
            text: "Site Analytics",
            link: "/portal/data/site-analytics",
            subNavigation: [
              {
                icon: "handshake",
                text: "Referral Overview",
                link: "/portal/data/site-analytics/overview"
              },
              {
                icon: "chart-bar",
                text: "Referrer Trends",
                link: "/portal/data/site-analytics/referrer-trends"
              },
              {
                icon: "filter",
                text: "Funnel Analysis",
                link: "/portal/data/site-analytics/funnel-analysis"
              }
            ]
          },
          {
            icon: "cloud",
            text: "Data Forecasting",
            link: "/portal/data/data-forecasting",
            subNavigation: [
              {
                icon: "flame",
                text: "Gas Forecast",
                link: "/portal/data/data-forecasting/gas-forecast"
              }
            ]
          }
        ]}
      >
        <Routes
          api={this.props.api}
          user={this.props.user}
        />
      </SidebarLayout>
    );
  }
}

export default Data;
