import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Base from "../views/reporting/Base";
import PayoutForecasting from "../views/reporting/PayoutForecasting";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/reporting/base"
      render={internalProps => (
        <Base {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/reporting/finance/payout-forecasting"
      render={internalProps => (
        <PayoutForecasting {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/reporting/finance/payout-forecasting" />} />
  </Switch>
);

export default Routes;
