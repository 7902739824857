import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import TopUpOverview from "../views/payments/TopUpOverview";
import ImportTransactions from "../views/payments/sales-ledger/ImportTransactions";
import TransactionExplorer from "../views/payments/sales-ledger/TransactionExplorer";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/payments/topup-overview"
      render={internalProps => (
        <TopUpOverview {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/payments/sales-ledger/import-transactions"
      render={internalProps => (
        <ImportTransactions {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/payments/sales-ledger/transaction-explorer"
      render={internalProps => (
        <TransactionExplorer {...internalProps} {...props} />
      )}
    />
    <Route path="/portal/payments/sales-ledger" render={() => <Redirect to="/portal/payments/sales-ledger/transaction-explorer" />} />
    <Route render={() => <Redirect to="/portal/payments/sales-ledger/transaction-explorer" />} />
  </Switch>
);

export default Routes;
