import React from "react";
import Modal from "../components/generics/Modal";
import Card from "../components/generics/Card";
import CardButton from "../components/interactive/CardButton";
import axios from "axios";
import GenericMessage from "./GenericMessage";

class CustomerEmailActions extends React.Component {
  constructor() {
    super();
    this.state = {
      commsSentModal: false
    }
  }
  handleExit = e => {
    if (e.target.className.indexOf("exitable") > -1) {
      this.props.onExit(false);
    }
  };
  render() {
    return <>
      {this.state.commsSentModal ? <GenericMessage title="Communication sent" subTitle={`It's on the way.`} body={<>The customer will recieve the email/SMS within the next few minutes.</>} cancelText="Close" onExit={() => this.setState({ commsSentModal: false })} /> : null}
      <Modal>
        <div
          className="exitable animated duration-2 fadeIn"
          onClick={() => this.handleExit}
        >
          <Card style={{ minWidth: 600, backgroundColor: "var(--ui-bg)" }}>
            <div className="padding-30 gap-20 col-1">
              <div className="col-1 gap-5 margin-bottom-20">
                <h1 className="ui-title-three">Support Communications</h1>
                <h2 className="ui-title-five ui-title-sub">{this.props.email} / {this.props.phone}</h2>
              </div>
              <div className="col-1 gap-20">
                <CardButton
                  compact
                  title="Verified Trustpilot review request"
                  colour="green"
                  icon="star"
                  onClick={async () => {
                    await this.props.api.post(`/customers/${this.props.accountNumber}/${this.props.email}/trustpilot`);
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <div className="col-2 gap-20">
                <CardButton
                  compact
                  
                  title="Mobile email link"
                  colour="blue"
                  icon="mobile"
                  onClick={async () => {
                    await axios.post(`https://api.core.green.energy/auth/token`, {
                      "user": this.props.email,
                      "callback": "green://auth/",
                      "type": "email"
                    });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  disabled
                  title="Mobile SMS code"
                  colour="blue"
                  icon="sms"
                  onClick={async () => {
                    await axios.post(`https://api.core.green.energy/auth/token`, {
                      "user": this.props.phone,
                      "callback": "green://",
                      "type": "phone"
                    });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  title="Web email link"
                  colour="grey"
                  icon="desktop"
                  onClick={async () => {
                    await axios.post(`https://api.core.green.energy/auth/token`, {
                      "user": this.props.email,
                      "callback": "https://members.green.energy/auth/redeem/",
                      "type": "email"
                    });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                <CardButton
                  compact
                  disabled
                  title="Web SMS code"
                  colour="grey"
                  icon="sms"
                  onClick={async () => {
                    await axios.post(`https://api.core.green.energy/auth/token`, {
                      "user": this.props.phone,
                      "callback": "green://",
                      "type": "phone"
                    });
                    this.setState({
                      commsSentModal: true
                    });
                  }}
                />
                </div>
              </div>
              <div className="margin-top-25">
                <button
                  type="button"
                  className="ui-btn margin-right-20"
                  onClick={() => this.props.onExit()}
                >
                  Cancel
                  </button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
    </>;
  }
}

export default CustomerEmailActions;
