import React from "react";
import Card from "../../../components/generics/Card";
import Stat from "../../../components/analytical/Stat";
import Loader from "../../../components/generics/Loader";
import Table from "../../../components/analytical/Table";
import moment from "moment";
import {
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b"
];

class ReferralTrends extends React.Component {
  constructor() {
    super();
    this.state = {
      domains: [],
      selectedDomain: "",
      referrals: null,
      startDate: moment().subtract(28, "days"),
      endDate: moment().endOf("day"),
      loading: true,
      error: false
    };
  }
  componentDidMount() {
    this.getDomainList();
  }
  async getDomainList() {
    const domains = await this.props.api.get(`/analytics/sales/referrers`);
    this.setState(
      { domains: domains.data.data, selectedDomain: domains.data.data[0] },
      () => this.getData()
    );
  }
  getConversionRate(q, s) {
    return (((q - (q - s)) / q) * 100).toFixed(2) + "%";
  }
  async getData() {
    this.setState(
      {
        loading: true
      },
      async () => {
        try {
          const referrals = await this.props.api.get(
            `/analytics/sales/referrals/${
              this.state.selectedDomain
            }?start=${this.state.startDate.format(
              "YYYY-MM-DD"
            )}&end=${this.state.endDate.format("YYYY-MM-DD")}`
          );
          console.log(referrals.data.data);
          this.setState({
            referrals: referrals.data.data,
            loading: false
          });
        } catch (e) {
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  render() {
    return this.state.error ? (
      <Card
        message="An error occurred"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="bug"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <div className="col-1 gap-10">
          <p className="ui-label">Report filters</p>
          <Card style={{ minHeight: 110 }}>
            {!this.state.loading ? (
              <div
                className="padding-20 col-4 gap-20"
                style={{
                  alignItems: "end",
                  gridTemplateColumns: "auto auto auto max-content"
                }}
              >
                <div>
                  <p className="ui-label margin-bottom-10">Domain Referrer</p>
                  <select
                    className="ui-select"
                    value={this.state.selectedDomain}
                    onChange={e =>
                      this.setState({ selectedDomain: e.currentTarget.value })
                    }
                  >
                    {this.state.domains.map(x => (
                      <option value={x}>{x}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <p className="ui-label margin-bottom-10">Start date</p>
                  <input
                    className="ui-input"
                    defaultValue={this.state.startDate.format(
                      "YYYY-MM-DDTHH:mm"
                    )}
                    onChange={e =>
                      this.setState({
                        startDate: moment(e.currentTarget.value)
                      })
                    }
                    type="datetime-local"
                    placeholder="Start date"
                  />
                </div>
                <div>
                  <p className="ui-label margin-bottom-10">End date</p>
                  <input
                    className="ui-input"
                    defaultValue={this.state.endDate.format("YYYY-MM-DDTHH:mm")}
                    onChange={e =>
                      this.setState({ endDate: moment(e.currentTarget.value) })
                    }
                    type="datetime-local"
                    placeholder="End date"
                  />
                </div>
                <div>
                  <button
                    className="ui-btn-compact"
                    onClick={() => this.getData()}
                  >
                    <i className="far fa-search margin-right-10" />
                    Search
                  </button>
                </div>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <Card style={{ minHeight: 130 }}>
            {!this.state.loading ? (
              <div className="col-3 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  label="Quotes"
                  figure={this.state.referrals
                    .reduce((a, x) => (a += x.quotes), 0)
                    .toLocaleString()}
                />
                <Stat
                  label="Sales"
                  figure={this.state.referrals
                    .reduce((a, x) => (a += x.sales), 0)
                    .toLocaleString()}
                />
                <Stat
                  label="Conversion Rate"
                  figure={this.getConversionRate(
                    this.state.referrals.reduce((a, x) => (a += x.quotes), 0),
                    this.state.referrals.reduce((a, x) => (a += x.sales), 0)
                  )}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Sales trend</p>

          <Card style={{ height: 400 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <LineChart
                    data={this.state.referrals
                      .sort((a, b) => a.date - b.date)
                      .map(x => ({
                        date: moment(x.date).format("DD/MM"),
                        Sales: x.sales,
                        Quotes: x.quotes
                      }))}
                  >
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="date" />
                    <YAxis
                      width={30}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border")
                      }}
                      formatter={value =>
                        new Intl.NumberFormat("en").format(value)
                      }
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey="Quotes"
                      stroke={colors[1]}
                      dot={false}
                    />
                    <Line
                      isAnimationActive={false}
                      dataKey="Sales"
                      stroke={colors[0]}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  }
}

export default ReferralTrends;
