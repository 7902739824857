import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import CustomerInsights from "../views/data/customer-insights";
import SiteAnalytics from "../views/data/site-analytics";
import DataForecasting from "../views/data/data-forecasting";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/data/customer-insights"
      render={internalProps => (
        <CustomerInsights {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/site-analytics"
      render={internalProps => (
        <SiteAnalytics {...internalProps} {...props} />
      )}
    />
    <Route
      path="/portal/data/data-forecasting"
      render={internalProps => (
        <DataForecasting {...internalProps} {...props} />
      )}
    />
    <Route render={() => <Redirect to="/portal/data/customer-insights" />} />
  </Switch>
);

export default Routes;
