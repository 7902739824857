import React from "react";
import Card from "../../components/generics/Card";

class Base extends React.Component {
  render(){
    return <Card message="Section coming soon" style={{height:"100%",borderRadius: "12px 12px 0 0"}} messageIcon="tools"/>;
  }
}

export default Base;
