import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import PortalHome from "../views/portal/Home";
import Support from "../views/support";
import Data from "../views/data";
import Admin from "../views/admin";
import Knowledge from "../views/knowledge";
import Billing from "../views/billing";
import Field from "../views/field";
import Payments from "../views/payments";
import Partners from "../views/partners";
import Energy from "../views/energy";
import Reporting from "../views/reporting";
import Smart from "../views/smart";

const Routes = props => (
  <Switch>
    <Route
      path="/portal/home"
      render={internalProps => <PortalHome {...internalProps} {...props} />}
    />
    <Route
      path="/portal/support"
      render={internalProps => <Support {...internalProps} {...props} />}
    />
    <Route
      path="/portal/data"
      render={internalProps => <Data {...internalProps} {...props} />}
    />
    <Route
      path="/portal/admin"
      render={internalProps => <Admin {...internalProps} {...props} />}
    />
    <Route
      path="/portal/knowledge"
      render={internalProps => <Knowledge {...internalProps} {...props} />}
    />
    <Route
      path="/portal/billing"
      render={internalProps => <Billing {...internalProps} {...props} />}
    />
    <Route
      path="/portal/payments"
      render={internalProps => <Payments {...internalProps} {...props} />}
    />
    <Route
      path="/portal/field"
      render={internalProps => <Field {...internalProps} {...props} />}
    />
    <Route
      path="/portal/partners"
      render={internalProps => <Partners {...internalProps} {...props} />}
    />
    <Route
      path="/portal/smart"
      render={internalProps => <Smart {...internalProps} {...props} />}
    />
    <Route
      path="/portal/energy"
      render={internalProps => <Energy {...internalProps} {...props} />}
    />
    <Route
      path="/portal/reporting"
      render={internalProps => <Reporting {...internalProps} {...props} />}
    />
    <Route render={() => <Redirect to="/portal/home" />} />
  </Switch>
);

export default Routes;
