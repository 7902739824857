import React from "react";
import Card from "../../components/generics/Card";
import Stat from "../../components/analytical/Stat";
import Loader from "../../components/generics/Loader";
import CardButton from "../../components/interactive/CardButton";
import Table from "../../components/analytical/Table";
import GenericConfirm from "../../modals/GenericConfirm";
import moment from "moment";
import abbreviate from "number-abbreviate";
import {
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Cell,
  Pie,
  PieChart,
  Legend,
  LineChart,
} from "recharts";
import downloadFile from "../../components/helpers/downloadFile";
import { json2csv } from "json-2-csv";

const colors = [
  "#30AF5F",
  "#4E59A3",
  "#37A9E0",
  "#E67F25",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#f39c12",
  "#d35400",
  "#c0392b",
];

class TopUpOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      loading: true,
      error: false,
      downloadPending: false,
    };
  }
  componentDidMount() {
    this.getData();
  }
  async getData() {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          let stats = await this.props.api.get(`/billing/unbilled-stats`);
          console.log(stats.data.data);
          this.setState({
            loading: false,
            data: stats.data.data,
          });
        } catch (e) {
          console.log(e);
          this.setState({ error: true, loading: false });
        }
      }
    );
  }
  downloadRawData = async () => {
    this.setState({ downloadPending: true }, async () => {
      const file = await this.props.api.get(`/billing/unbilled-raw`);
      downloadFile(
        `unbilled_raw_${moment().format("YYYYMMDD")}.csv`,
        "text/csv",
        file.data.data
      );
      this.setState({ downloadPending: false });
    });
  };
  eventBreakdown = (com) => {
    const keys = Object.keys(this.state.data.events);
    if(com === "electric"){
      keys.splice(keys.findIndex(x => x === "missingMBR"), 1);
    } else {
      keys.splice(keys.findIndex(x => x === "missingD86"), 1);
    }
    return keys.filter(x => x !== "billStops")
      .map((x) => ({
        name:
          x === "disputedRead"
            ? "Disputed Read"
            : x === "et"
            ? "ET"
            : x === "metering"
            ? "Metering"
            : x === "admin"
            ? "Admin"
            : x === "missingD86"
            ? "Missing D86"
            : x === "complaints"
            ? "Complaints"
            : "Missing MBR",
        Accounts: this.state.data.events[x][com],
      }))
      .sort((a, b) => b.Accounts - a.Accounts);
  };
  unbilledBreakdown = (com) => {
    return Object.keys(this.state.data.unbilledIntervals)
      .map((x) => ({
        name:
          x === "over90Days"
            ? "90 Days +"
            : x === "within30Days"
            ? "< 30 Days"
            : x === "within3160Days"
            ? "31 - 60 Days"
            : "61 - 90 Days",
        value: this.state.data.unbilledIntervals[x][com],
      }))
      .sort((a, b) => b.value - a.value);
  };
  firstBillBreakdown = (com) => {
    return Object.keys(this.state.data.firstBills).map((x) => ({
      name: x === "next7Days" ? "Next 7 Days" : "7 - 14 Days",
      value: this.state.data.firstBills[x][com],
    }));
  };
  recurringBillBreakdown = (com) => {
    return Object.keys(this.state.data.recurringBills).map((x) => ({
      name: x === "next7Days" ? "Next 7 Days" : "7 - 14 Days",
      value: this.state.data.recurringBills[x][com],
    }));
  };
  renderDashboard = (com) => {
    return (
      <>
        <div className="col-1 gap-10">
          <p
            className="ui-title-two margin-bottom-20"
            style={{ textTransform: "capitalize" }}
          >
            {com}
          </p>
          <Card style={{ minHeight: 120 }}>
            {!this.state.loading ? (
              <div className="col-4 gap-20 padding-top-25 padding-bottom-25 padding-left-35 padding-right-35">
                <Stat
                  compact
                  figureColour={com === "electric" ? "blue" : "orange"}
                  label={`Live Accounts`}
                  figure={this.state.data.live[com].toLocaleString()}
                />
                <Stat
                  compact
                  figureColour={com === "electric" ? "blue" : "orange"}
                  label={`Bill Due`}
                  figure={this.state.data.dueBill[com].toLocaleString()}
                />
                <Stat
                  compact
                  figureColour={com === "electric" ? "blue" : "orange"}
                  label={`Final Bill Due`}
                  figure={this.state.data.finalBills[com].toLocaleString()}
                />
                <Stat
                  compact
                  figureColour={com === "electric" ? "blue" : "orange"}
                  label={`Billing Percentage`}
                  figure={`${(
                    (1 - (this.state.data.unbilledIntervals.within3160Days[com] + this.state.data.unbilledIntervals.within6090Days[com] + this.state.data.unbilledIntervals.over90Days[com]) / this.state.data.dueBill[com]) * 100
                  ).toFixed(2)}%`}
                />
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-2 gap-40">
          <div className="col-1 gap-10">
            <p className="ui-label">First bill breakdown</p>
            <Card style={{ height: 300 }}>
              {!this.state.loading ? (
                <div className="padding-top-15 padding-bottom-30">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={this.firstBillBreakdown(com)}
                        strokeWidth={0}
                        // paddingAngle={5}
                        innerRadius={60}
                      >
                        {this.firstBillBreakdown(com).map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={colors[index]} />
                        ))}
                      </Pie>

                      <Tooltip />
                      <Legend
                        payload={this.firstBillBreakdown(com).map(
                          (item, index) => ({
                            id: item.name,
                            type: "circle",
                            color: colors[index],
                            value: `${item.name}`,
                          })
                        )}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
          <div className="col-1 gap-10">
            <p className="ui-label">Recurring bill breakdown</p>
            <Card style={{ height: 300 }}>
              {!this.state.loading ? (
                <div className="padding-top-15 padding-bottom-30">
                  <ResponsiveContainer>
                    <PieChart>
                      <Pie
                        dataKey="value"
                        isAnimationActive={false}
                        data={this.recurringBillBreakdown(com)}
                        strokeWidth={0}
                        // paddingAngle={5}
                        innerRadius={60}
                      >
                        {this.recurringBillBreakdown(com).map(
                          (entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index]} />
                          )
                        )}
                      </Pie>

                      <Tooltip formatter={(value) => `${value} Accounts`} />
                      <Legend
                        payload={this.recurringBillBreakdown(com).map(
                          (item, index) => ({
                            id: item.name,
                            type: "circle",
                            color: colors[index],
                            value: `${item.name}`,
                          })
                        )}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              ) : (
                <div className="grid-center-center">
                  <Loader />
                </div>
              )}
            </Card>
          </div>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Event overview</p>
          <Card style={{ height: 350 }}>
            {!this.state.loading ? (
              <div className="padding-15">
                <ResponsiveContainer>
                  <BarChart data={this.eventBreakdown(com)}>
                    <CartesianGrid strokeDasharray="5 5" strokeOpacity={0} />
                    <XAxis height={20} dataKey="name" />
                    <YAxis
                      width={60}
                      type="number"
                      // domain={[0, dataMax => dataMax * 1.25]}
                    />
                    <Tooltip
                      cursor={{
                        fill: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--ui-border"),
                      }}
                    />
                    <Bar
                      isAnimationActive={false}
                      dataKey={"Accounts"}
                      fill={colors[2]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
        <div className="col-1 gap-10">
          <p className="ui-label">Unbilled intervals</p>
          <Card style={{ height: 350 }}>
            {!this.state.loading ? (
              <div className="padding-top-15 padding-bottom-30">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={this.unbilledBreakdown(com)}
                    strokeWidth={0}
                    // paddingAngle={5}
                    innerRadius={60}
                  >
                    {this.unbilledBreakdown(com).map(
                      (entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index]} />
                      )
                    )}
                  </Pie>

                  <Tooltip formatter={(value, x) => {
                    if(x !== "< 30 Days"){
                      return `${value} Accounts (${(this.state.data.unbilledIntervals[x === "90 Days +" ? "over90Days" : x === "61 - 90 Days" ? "within6090Days" : "within3160Days"][com] / this.state.data.dueBill[com] * 100).toFixed(2)}%)`
                    }
                    return `${value} Accounts`
                  }} />
                  <Legend
                    payload={this.unbilledBreakdown(com).map(
                      (item, index) => ({
                        id: item.name,
                        type: "circle",
                        color: colors[index],
                        value: `${item.name}`,
                      })
                    )}
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
            ) : (
              <div className="grid-center-center">
                <Loader />
              </div>
            )}
          </Card>
        </div>
      </>
    );
  };
  render() {
    return this.state.error ? (
      <Card
        message="Contact support to reload"
        style={{ height: "100%", borderRadius: "12px 12px 0 0" }}
        messageIcon="sync"
      />
    ) : (
      <div className="col-1 gap-40 margin-bottom-40">
        <button className="ui-btn sync-button" onClick={() => this.state.downloadPending ? null : this.downloadRawData()}>
          <i
            className={`fad fa-${
              this.state.downloadPending ? "spinner-third fa-spin colour-blue" : "download"
            }`}
          />
        </button>
        {this.renderDashboard("electric")}
        {this.renderDashboard("gas")}
      </div>
    );
  }
}

export default TopUpOverview;
